import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';

import controlStyles from '../../ProductControl.module.scss';

export interface Props {
  allItemsSelected: boolean;
  allowSelectAll: boolean;
  onSelectAll(deselect?: boolean): void;
  children: React.ReactNode;
}

function MultiSelectProductControlHeader({ allItemsSelected, onSelectAll, allowSelectAll, children }: Props) {
  const { t } = useTranslation();
  const selectAllBtn = useMemo(() => {
    if (!allowSelectAll) {
      return null;
    }

    return allItemsSelected ? (
      <Button tertiary small className="cy-deselect-all" stopPropagation onClick={() => onSelectAll(true)}>
        {t('Unselect all')}
      </Button>
    ) : (
      <Button tertiary small className="cy-select-all" stopPropagation onClick={() => onSelectAll()}>
        {t('Select all')}
      </Button>
    );
  }, [allItemsSelected]);

  return (
    <div className="row-flex row-flex--between row-flex--baseline w-100">
      <div className={cn(controlStyles.controlTitle, 'row-flex row-flex--middle')}>{children}</div>
      {selectAllBtn}
    </div>
  );
}

export default React.memo(MultiSelectProductControlHeader);
