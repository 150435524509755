import { FabricImage, FabricObject } from 'fabric';
import { ImageProps } from 'fabric/src/shapes/Image';
import React, { useContext, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';

import CustomFabricImage, { IFabricImageOptions } from 'editor/src/fabric/CustomFabricImage';
import loadImage from 'editor/src/util/loadImage';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import { GroupContext } from './FabricGroupComponent';
import { disposeElement } from './fabricUtils';
import useControlVisibility, { ControlVisibility } from './useControlVisibility';
import useEvent, { EventHandler } from './useEvent';
import useEvents from './useEvents';
import useObjectProps from './useObjectProps';

export type CrossOrigin = 'anonymous' | undefined;

interface Props extends Partial<ImageProps> {
  uuid?: number;
  zIndex?: number;
  frameRect?: ObjectRect;
  getFabricOptionsOnUpdate?: (element: CustomFabricImage) => Partial<FabricImage>;
  onImageLoaded?: () => void;
  controlVisibility?: ControlVisibility;
  events?: { [eventType: string]: EventHandler };
  onMouseDown?: EventHandler;
  onMouseMove?: EventHandler;
  onMouseUp?: EventHandler;
  onMouseOver?: EventHandler;
  onMouseOut?: EventHandler;
  onModified?: EventHandler;
  onMouseDblClick?: EventHandler;
  crossOrigin: CrossOrigin;
  source: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement | string;
}

function FabricImageComponent(props: Props, ref: React.Ref<CustomFabricImage>) {
  const fabricCanvas = useFabricCanvas();
  const groupContext = useContext(GroupContext);
  const {
    source,
    crossOrigin,
    getFabricOptionsOnUpdate,
    filters,
    onImageLoaded,
    controlVisibility,
    events,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseOver,
    onMouseOut,
    onMouseDblClick,
    onModified,
    ...imageProps
  } = props;
  const [element] = useState(() => new CustomFabricImage(new Image(), imageProps as IFabricImageOptions));
  useObjectProps(element, imageProps as Partial<FabricObject>);

  const getFabricOptionsOnUpdateRef = useRef(getFabricOptionsOnUpdate);
  getFabricOptionsOnUpdateRef.current = getFabricOptionsOnUpdate;
  useImperativeHandle(ref, () => element);

  useEvents(element, events);
  useEvent(element, 'mouseover', onMouseOver);
  useEvent(element, 'mouseout', onMouseOut);
  useEvent(element, 'mousedown', onMouseDown);
  useEvent(element, 'mousemove', onMouseMove);
  useEvent(element, 'mouseup', onMouseUp);
  useEvent(element, 'mousedblclick', onMouseDblClick);
  useEvent(element, 'modified', onModified);

  const mounted = useRef(true);
  const loadingSrc = useRef(source);
  useLayoutEffect(() => {
    if (typeof source !== 'string') {
      element.isLoaded = true;
      element.setElement(source as any);
      element.applyFilters();
      return;
    }

    element.isLoaded = false;
    loadingSrc.current = source;
    loadImage(source, crossOrigin, { executor: 'FabricImageComponent' })
      .then((img) => {
        if (mounted.current && loadingSrc.current === source && img) {
          element.isLoaded = true;
          element.setElement(img);
          if (getFabricOptionsOnUpdateRef.current) {
            element.set(getFabricOptionsOnUpdateRef.current(element));
          }
          element.setCoords();
          onImageLoaded?.();
          fabricCanvas.requestRenderAll();
        }
      })
      .catch(() => {});
  }, [source, crossOrigin]);

  useControlVisibility(element, controlVisibility);

  useLayoutEffect(() => {
    element.filters = crossOrigin === 'anonymous' ? filters ?? [] : [];
    if (element.isLoaded) {
      if (typeof source !== 'string') {
        element.setElement(source as any);
      }

      element.applyFilters();
    }
  }, [filters, crossOrigin]);

  useLayoutEffect(() => {
    const containerContext = groupContext ?? fabricCanvas;
    containerContext.add(element);
    return () => {
      mounted.current = false;
      disposeElement(element);
      containerContext.remove(element);
      fabricCanvas.requestRenderAll();
    };
  }, []);

  useLayoutEffect(() => {
    if (getFabricOptionsOnUpdateRef.current && element.isLoaded) {
      element.set(getFabricOptionsOnUpdateRef.current(element));
    }
    element.setCoords();
    fabricCanvas.requestRenderAll();
  });

  return null;
}

export default React.memo(React.forwardRef(FabricImageComponent));
