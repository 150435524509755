import type { ThunkDispatch } from 'editor/src/store/hooks';
import { setHelpCenterArticlesSearchStringAction } from 'editor/src/store/hostSettings/slice';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const requestHelpCenterArticlesOperation = (searchString: string) => (dispatch: ThunkDispatch) => {
  dispatch(setHelpCenterArticlesSearchStringAction(searchString));

  sendPostMessage('help.requestArticles', searchString);
};

export default requestHelpCenterArticlesOperation;
