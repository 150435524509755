import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setDesignDataAction, setOriginalDesignDataAction } from 'editor/src/store/design/slice';
import { DesignData } from 'editor/src/store/design/types';
import applyDesign from 'editor/src/store/design/util/applyDesign';
import requestVariantsColorOverrides from 'editor/src/store/design/util/requestVariantsColorOverrides';
import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import { setFocusedContentAction as setFocusedContentOperation } from 'editor/src/store/editor/slice';
import setSidebarTabsOperation from 'editor/src/store/editorModules/sidebar/operation/setBaseTabsOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import clearStatesOperation from 'editor/src/store/editorModules/undoRedo/operation/clearStatesOperation';
import loadDesignDataFonts from 'editor/src/store/fonts/utils/loadDesignDataFonts';
import type { AsyncThunk } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import applyDefaultMockupSceneIfNoneAppliedOperation from 'editor/src/store/mockup/operation/applyDefaultMockupSceneIfNoneAppliedOperation';
import setupProductSizeOperation from 'editor/src/store/variants/operation/setupProductSizeOperation';
import { UNSELECTED_PAGE_COUNT } from 'editor/src/store/variants/reducer/setPageCountReducer';

import { logEvent, setProductUid } from 'editor/src/amplitude';
import getDesignDataStats from 'editor/src/amplitude/getDesignDataStats';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';

import { extractDisplayPages } from 'editor/src/component/EditorArea/utils/displayPageUtils';

import resetActiveElementsOperation from '../../editor/operation/resetActiveElementsOperation';
import selectElementOperation from '../../editor/operation/selectElementOperation';
import { setSelectedProductUidAction } from '../../variants/slice';
import { isEmbedded } from '../../watchers/useDisplayMode';

import autoApplyLayoutSchemasToDesignDataOperation from './autoApplyLayoutSchemasToDesignDataOperation';
import updateAllGridElementsOperation from './calendarGrid/updateAllGridElementsOperation';
import controlDesignDataAndGetColorOverrides from './controlDesignDataAndGetColorOverrides';
import getFirstPersonalizableElement from './getFirstPersonalizableElement';
import setInnerPagesCountOperation from './setInnerPagesCountOperation';
import setupUuidGenerators from './setupUuidGenerators';

export const DEFAULT_TABS: TAB_NAMES[] = [TAB_NAMES.LAYERS, TAB_NAMES.GALLERY, TAB_NAMES.LAYOUTS, TAB_NAMES.SETTINGS];

const setDesignDataOperation =
  (designData: DesignData, log = true, onSetDesignCallback?: (design: DesignData) => void): AsyncThunk =>
  async (dispatch, getState, { i18n }) => {
    let state = getState();
    let nextDesignData = designData;

    // backend sends wrong data ? let's not set it..
    if (!nextDesignData.spreads) {
      throw new Error('no spreads in the design data?');
    }

    if (nextDesignData.spreads.some((spread) => spread.pages.length === 0)) {
      throw new Error('no pages in the spreads?');
    }

    const { selectedPageCount } = state.variants;
    const pageCount = nextDesignData.page_count;

    if (pageCount && selectedPageCount !== UNSELECTED_PAGE_COUNT && nextDesignData.page_count !== selectedPageCount) {
      // eslint-disable-next-line no-console
      console.error(`incorrect design for page count: ${nextDesignData.page_count}`);
      return;
    }

    const spreadGroups = state.variants.groupedSpreadsPerProductUids[nextDesignData.product_uid];
    const variantKey = state.variants.selectedGroupKey;
    const designOptionKey = state.variants.selectedDesignOptionValue;

    const colorOverrides = controlDesignDataAndGetColorOverrides({
      designData: nextDesignData,
      i18n,
      spreadGroups,
    });
    requestVariantsColorOverrides(colorOverrides, variantKey, designOptionKey);
    setupUuidGenerators([nextDesignData]);

    if (isEmbedded) {
      setTimeout(() => {
        const { designData } = getState().design;
        if (!designData || !!designData.personalizationSettings?.allowAddElements) {
          return;
        }

        state = getState();
        const selectedElementsUuids = state.editor.selectedElementUuids;
        const firstPersonalizableElement = getFirstPersonalizableElement(designData);

        // if no element is selected -  select the first visible personalizable element(if any) after 1 second
        if (!selectedElementsUuids.length && firstPersonalizableElement?.uuid) {
          dispatch(selectElementOperation(firstPersonalizableElement.uuid));
        }
      }, 1000);

      // reflect design data if it changed in the embedded editor
      if (state.design.designData) {
        nextDesignData = applyDesign(
          state.design.designData,
          state.design.originalDesignData,
          nextDesignData,
          true,
          getReflectContext(state),
        );
      }
    }

    try {
      await loadDesignDataFonts([nextDesignData], state.fonts.availableFonts, state.gridDesigns.grids);
    } catch (e) {
      throw e || new Error('failed loading design fonts');
    }
    state = getState();

    let currentDesignData = state.design.designData;

    batch(() => {
      // allow undo/redo when switching variants for the shopify flow
      if (!isEmbedded) {
        dispatch(clearStatesOperation());
      }

      dispatch(setInnerPagesCountOperation(nextDesignData.page_count));

      if (state.editor.currentSpreadIndex > nextDesignData.spreads.length) {
        dispatch(setCurrentSpreadIndexOperation(0));
      } else {
        dispatch(resetActiveElementsOperation());
      }

      if (!state.editorModules.sidebar.tabs.length) {
        dispatch(setSidebarTabsOperation(DEFAULT_TABS));
      }

      state = getState();
      currentDesignData = state.design.designData;
      dispatch(setDesignDataAction(nextDesignData));
      dispatch(setOriginalDesignDataAction(designData));
      if (state.design.designData?.calendar && state.gridDesigns.locale) {
        void dispatch(updateAllGridElementsOperation({ locale: state.gridDesigns.locale }));
      }
      state = getState();

      const splitDisplayPerContent = getHostSetting(state, 'splitDisplayPerContent');
      const displayPage = extractDisplayPages(nextDesignData.spreads, splitDisplayPerContent);
      if (displayPage.length) {
        dispatch(setFocusedContentOperation(displayPage[0].focusedContent));
      }

      dispatch(setupProductSizeOperation());
      dispatch(autoApplyLayoutSchemasToDesignDataOperation());
      dispatch(applyDefaultMockupSceneIfNoneAppliedOperation());
    });

    if (!currentDesignData || currentDesignData.product_uid !== nextDesignData.product_uid) {
      sendPostMessage('design.productSwitched', {
        productUid: nextDesignData.product_uid,
        width: nextDesignData.dimensions?.width,
        height: nextDesignData.dimensions?.height,
        pageCount: nextDesignData.page_count_limit ? nextDesignData.page_count : undefined,
      });
    }

    setProductUid(nextDesignData.product_uid);
    dispatch(setSelectedProductUidAction(nextDesignData.product_uid));
    // eslint-disable-next-line no-console
    console.log(`Product uid: ${nextDesignData.product_uid}`);
    if (log) {
      logEvent('design loaded', getDesignDataStats(getState()));
    }
    state.design.designData && onSetDesignCallback?.(state.design.designData);
  };

export default setDesignDataOperation;
