import { captureException } from '@sentry/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import refreshCollectionCacheOperation from 'editor/src/store/collections/operations/refreshCollectionCacheOperation';
import setCollectionFolderOperation from 'editor/src/store/collections/operations/setCollectionFolderOperation';
import addDigitizedAssetOperation from 'editor/src/store/design/operation/addDigitizedAssetOperation';
import addonUploadedOperation from 'editor/src/store/design/operation/addonUploadedOperation';
import addonUploadFailedOperation from 'editor/src/store/design/operation/addonUploadFailedOperation';
import addOrApplyCollectionFolderToPageOperation from 'editor/src/store/design/operation/addOrApplyCollectionFolderToPageOperation';
import applyDesignDataToOperation from 'editor/src/store/design/operation/applyDesignDataToOperation';
import applyHostImageOperation from 'editor/src/store/design/operation/applyHostImageOperation';
import applyLayoutOperation from 'editor/src/store/design/operation/applyLayoutOperation';
import changeFormatOperation from 'editor/src/store/design/operation/changeFormatOperation';
import loadSchemaSetOperation from 'editor/src/store/design/operation/loadSchemaSetOperation';
import pluginAssetUploadedOperation from 'editor/src/store/design/operation/pluginAssetUploadedOperation';
import requestDesignDataOperation from 'editor/src/store/design/operation/requestDesignDataOperation';
import setAssetWithReplacedColorsOperation from 'editor/src/store/design/operation/setAssetWithReplacedColorsOperation';
import setAutoApplyLayoutSchemaNamesOperation from 'editor/src/store/design/operation/setAutoApplyLayoutSchemaNamesOperation';
import setDesignDataOperation from 'editor/src/store/design/operation/setDesignDataOperation';
import setDigitizedAssetErrorOperation from 'editor/src/store/design/operation/setDigitizedAssetErrorOperation';
import setQuantizedAssetErrorOperation from 'editor/src/store/design/operation/setQuantizedAssetErrorOperation';
import setQuantizedAssetOperation from 'editor/src/store/design/operation/setQuantizedAssetOperation';
import setSpreadBackgroundsOperation from 'editor/src/store/design/operation/setSpreadBackgroundsOperation';
import setSpreadForegroundsOperation from 'editor/src/store/design/operation/setSpreadForegroundsOperation';
import { addCalendarPublicEventsAction, setDesignSavingStatusAction } from 'editor/src/store/design/slice';
import applyLayoutSchemaByIndexToCurrentSpreadOperation from 'editor/src/store/editor/operation/applyLayoutSchemaByIndexToCurrentSpreadOperation';
import checkVariantsForErrorsOperation from 'editor/src/store/editor/operation/checkVariantsForErrorsOperation';
import setDisabledSpreadsOperation from 'editor/src/store/editor/operation/setDisabledSpreadsOperation';
import setHostDefaultSettingsOperation from 'editor/src/store/editor/operation/setHostDefaultSettingsOperation';
import setImagePatternScaleBasesOperation from 'editor/src/store/editor/operation/setImagePatternScaleBasesOperation';
import setPersonalizationStudioBannerDataOperation from 'editor/src/store/editor/operation/setPersonalizationStudioBannerDataOperation';
import setTrackDesignChangesOperation from 'editor/src/store/editor/operation/setTrackDesignChangesOperation';
import setUploaderConfigOperation from 'editor/src/store/editor/operation/setUploaderConfigOperation';
import { disableSpreadAction, enableSpreadAction, setCurrentStyleAction } from 'editor/src/store/editor/slice';
import setAddonsAttributionImageOperation from 'editor/src/store/editorModules/addons/operation/setAddonsAttributionImageOperation';
import setAddonsDataOperation from 'editor/src/store/editorModules/addons/operation/setAddonsDataOperation';
import setAddonsInUseOperation from 'editor/src/store/editorModules/addons/operation/setAddonsInUseOperation';
import setFormatsDataOperation from 'editor/src/store/editorModules/formats/operation/setFormatsDataOperation';
import setLayoutsOperation from 'editor/src/store/editorModules/layouts/operation/setLayoutsOperation';
import setDefaultZoomOperation from 'editor/src/store/editorModules/panAndZoom/operation/setDefaultZoomOperation';
import setSpreadsDefaultZoomOperation from 'editor/src/store/editorModules/panAndZoom/operation/setSpreadsDefaultZoomOperation';
import setFlatPreviewAvailableOperation from 'editor/src/store/editorModules/preview/operation/setFlatPreviewAvailableOperation';
import setFlatPreviewFailedOperation from 'editor/src/store/editorModules/preview/operation/setFlatPreviewFailedOperation';
import setFlatPreviewReadyOperation from 'editor/src/store/editorModules/preview/operation/setFlatPreviewReadyOperation';
import setPreviewStatusOperation from 'editor/src/store/editorModules/preview/operation/setPreviewStatusOperation';
import setRendersOperation from 'editor/src/store/editorModules/preview/operation/setRendersOperation';
import setSpreadPreviewOperation from 'editor/src/store/editorModules/preview/operation/setSpreadPreviewOperation';
import setThe3dPreviewAvailableOperation from 'editor/src/store/editorModules/preview/operation/setThe3dPreviewAvailableOperation';
import { PreviewStatus } from 'editor/src/store/editorModules/preview/types';
import setBaseTabsOperation from 'editor/src/store/editorModules/sidebar/operation/setBaseTabsOperation';
import setGroupedTabsOperation from 'editor/src/store/editorModules/sidebar/operation/setGroupedTabsOperation';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import editDesignTemplateNameOperation from 'editor/src/store/editorModules/templates/operation/editDesignTemplateNameOperation';
import removeDesignTemplateOperation from 'editor/src/store/editorModules/templates/operation/removeDesignTemplateOperation';
import {
  addDesignTemplateAction as addDesignTemplateOperation,
  setTemplatesAction as setTemplatesOperation,
} from 'editor/src/store/editorModules/templates/slice';
import setElementsToHighlightOperation from 'editor/src/store/editorModules/tour/operation/setElementsToHighlightOperation';
import setIntroTourStepsOperation from 'editor/src/store/editorModules/tour/operation/setIntroTourStepsOperation';
import clearStatesOperation from 'editor/src/store/editorModules/undoRedo/operation/clearStatesOperation';
import loadFontOperation from 'editor/src/store/fonts/operation/loadFontOperation';
import setDefaultFontOperation from 'editor/src/store/fonts/operation/setDefaultFontOperation';
import setFontListOperation from 'editor/src/store/fonts/operation/setFontListOperation';
import { setDefaultFontStylesAction } from 'editor/src/store/fonts/slice';
import addImageLocalPreviewFromUploader from 'editor/src/store/gallery/operation/addImageLocalPreviewFromUploader';
import addUploadedImages from 'editor/src/store/gallery/operation/addUploadedImagesOperation';
import removeImagesOperation from 'editor/src/store/gallery/operation/removeImagesOperation';
import updateImageOnFailedUpload from 'editor/src/store/gallery/operation/updateImageOnFailedUpload';
import updateImageOnUpdatedMetadataOperation from 'editor/src/store/gallery/operation/updateImageOnUpdatedMetadataOperation';
import updateImageOnUploadOperation from 'editor/src/store/gallery/operation/updateImageOnUpload';
import { setGridEventsAction } from 'editor/src/store/gridConfig/slice';
import { useDispatch } from 'editor/src/store/hooks';
import setHostSettingsOperation from 'editor/src/store/hostSettings/operation/setHostSettingsOperation';
import { setHelpCenterArticlesAction } from 'editor/src/store/hostSettings/slice';
import loadMockupOperation from 'editor/src/store/mockup/operation/loadMockupOperation';
import loadSceneOperation from 'editor/src/store/mockup/operation/loadSceneOperation';
import onMockupDownloadFailedOperation from 'editor/src/store/mockup/operation/onMockupDownloadFailedOperation';
import onMockupDownloadReadyOperation from 'editor/src/store/mockup/operation/onMockupDownloadReadyOperation';
import onMockupDownloadStarted from 'editor/src/store/mockup/operation/onMockupDownloadStarted';
import onMockupGenerationFailedOperation from 'editor/src/store/mockup/operation/onMockupGenerationFailedOperation';
import saveVariantOperation from 'editor/src/store/mockup/operation/saveVariantOperation';
import setMockupDataOperation from 'editor/src/store/mockup/operation/setMockupDataOperation';
import setMockupPreviewOperation from 'editor/src/store/mockup/operation/setMockupPreviewOperation';
import setMockupProductPreviewOperation from 'editor/src/store/mockup/operation/setMockupProductPreviewOperation';
import setProductScenesOperation from 'editor/src/store/mockup/operation/setProductScenesOperation';
import setScenePlaceholderMockupDataOperation from 'editor/src/store/mockup/operation/setScenePlaceholderMockupDataOperation';
import setStoreProductsOperation from 'editor/src/store/mockup/operation/setStoreProductsOperation';
import setStoreProductVariantsOperation from 'editor/src/store/mockup/operation/setStoreProductVariantsOperation';
import setStoresOperation from 'editor/src/store/mockup/operation/setStoresOperation';
import applyModifiedImageOperation from 'editor/src/store/plugins/operation/imageManipulation/applyModifiedImageOperation';
import resetModifiedImageOperation from 'editor/src/store/plugins/operation/imageManipulation/resetModifiedImageOperation';
import setPluginsOperation from 'editor/src/store/plugins/operation/setPluginsOperation';
import loadContentSquareScript from 'editor/src/store/utils/loadContentsquareScript';
import enableGroupLinkingOperation from 'editor/src/store/variants/operation/enableGroupLinkingOperation';
import handleNewDesignTemplatesOperation from 'editor/src/store/variants/operation/handleNewDesignTemplatesOperation';
import setAsVariantFlowOperation from 'editor/src/store/variants/operation/setAsVariantFlowOperation';
import setECommerceProductOperation from 'editor/src/store/variants/operation/setECommerceProductOperation';
import setMultipleProductsDataOperation from 'editor/src/store/variants/operation/setMultipleProductsDataOperation';
import setProductDataOperation from 'editor/src/store/variants/operation/setProductDataOperation';
import setSingleVariantProductDataOperation from 'editor/src/store/variants/operation/setSingleVariantProductDataOperation';
import setSpreadGroupsPerProductUidOperation from 'editor/src/store/variants/operation/setSpreadGroupsPerProductUidOperation';
import setVariantDesignToValidateOperation from 'editor/src/store/variants/operation/setVariantDesignToValidateOperation';

import * as amplitude from 'editor/src/amplitude';
import environment, { EnvEnum } from 'editor/src/environment';
import { processLocale } from 'editor/src/util/locales';
import { InboundPostMessage } from 'editor/src/util/postMessages/messages';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import toastController from 'editor/src/util/toastController';

import { checkForPromotionBannersDismissalConditions } from 'editor/src/component/Banners/utils';
import useCustomToastr from 'editor/src/component/CustomToastrComponent/useCustomToastr';
import { PopupName } from 'editor/src/component/Popup/popups';
import ButtonOpenTemplatesTab from 'editor/src/component/TopMenuDesktop/buttons/ButtonOpenTemplatesTab';

import RequestHandler from './RequestHandler';

function PostMessageReceiver() {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const showToastr = useCustomToastr();

  const receiveMessage = async (
    e: MessageEvent<{
      type: keyof InboundPostMessage;
      data: any;
      callback?: any;
    }>,
  ) => {
    let success = true;
    if (environment.env === EnvEnum.dev && (e.source !== e.target || (e.data as any).fakeHost)) {
      // eslint-disable-next-line no-console
      console.debug('To the editor', e.data);
    }
    switch (e.data.type) {
      case 'design.setAutoApplyLayoutSchemaNames':
        dispatch(setAutoApplyLayoutSchemaNamesOperation(e.data.data));
        break;
      case 'layouts.setSchemas':
        dispatch(setLayoutsOperation(e.data.data));
        break;
      case 'layouts.loadSchemaSet':
        dispatch(loadSchemaSetOperation(e.data.data));
        break;
      case 'settings.setHostDefaultSettings':
        dispatch(setHostDefaultSettingsOperation(e.data.data));
        break;
      case 'editor.setEditorHostSettings':
        dispatch(setHostSettingsOperation(e.data.data));
        break;
      case 'editor.setCalendarEvents':
        dispatch(setGridEventsAction(e.data.data));
        dispatch(addCalendarPublicEventsAction(e.data.data));
        break;
      case 'editor.setTrackDesignChanges':
        dispatch(setTrackDesignChangesOperation(e.data.data));
        break;
      case 'variants.setProductData': {
        void dispatch(setProductDataOperation(e.data.data));
        break;
      }
      case 'variants.setMultipleProductsData': {
        void dispatch(setMultipleProductsDataOperation(e.data.data));
        break;
      }
      case 'variants.setSingleVariantProductData': {
        void dispatch(setSingleVariantProductDataOperation(e.data.data));
        break;
      }
      case 'variants.setLimit': {
        void dispatch(setHostSettingsOperation({ variantsLimit: e.data.data }));
        break;
      }
      case 'variants.setSpreadGroupsPerProductUid': {
        void dispatch(setSpreadGroupsPerProductUidOperation(e.data.data));
        break;
      }
      case 'variants.enableGroupLinking': {
        dispatch(enableGroupLinkingOperation(e.data.data));
        break;
      }
      case 'variants.setAsVariantFlow': {
        dispatch(setAsVariantFlowOperation());
        break;
      }
      case 'variants.designTemplates': {
        void dispatch(handleNewDesignTemplatesOperation(e.data.data));
        break;
      }
      case 'fonts.setDefaultFont': {
        batch(() => {
          const fontFamily = e.data.data;
          dispatch(setDefaultFontStylesAction({ fontFamily }));
          dispatch(setCurrentStyleAction({ type: 'text', update: { fontFamily } }));
          void dispatch(loadFontOperation(fontFamily));
        });
        break;
      }
      case 'editor.setLocale': {
        const lang = processLocale(e.data.data);
        if (i18n.i18n.language !== lang) {
          void i18n.i18n.changeLanguage(lang);
          dispatch(setDefaultFontOperation(lang));
        }
        break;
      }
      case 'editor.setTranslations':
        if (e.data.data) {
          const lan = i18n.i18n.language;
          i18n.i18n.addResources(lan, 'translations', e.data.data);
          void i18n.i18n.changeLanguage(lan);
        }
        break;
      case 'design.requestDesignData':
        success = dispatch(requestDesignDataOperation(e.data));
        break;
      case 'editor.requestUserConfirmation':
        dispatch(openPopupOperation(PopupName.CONFIRMATION_POPUP, e.data.data));
        break;
      case 'editor.mode': {
        void dispatch(setHostSettingsOperation({ editorMode: e.data.data }));
        break;
      }
      case 'design.changeFormat':
        dispatch(clearStatesOperation());
        success = dispatch(
          changeFormatOperation(
            e.data.data.productUid || e.data.data,
            e.data.data.designTemplate,
            e.data.data.forceFormatChange,
          ),
        );
        break;
      case 'design.setDesignData':
        try {
          if (!e.data.data) {
            throw new Error('empty designdata');
          }
          await dispatch(setDesignDataOperation(e.data.data));
          sendPostMessage('success.LoadingDesignSuccess', undefined);
        } catch (error) {
          captureException(error, { extra: { design: e.data.data, error } });
          sendPostMessage('error.loadingDesignFailed', undefined);
        }
        break;
      case 'design.applyDesignDataTo': {
        // TODO as applyDesignDataTo structure changed we need care about old format until it will be change for the host
        let { data } = e.data;
        if (!data.design) {
          data = { design: data };
        }

        try {
          dispatch(applyDesignDataToOperation(data));
          sendPostMessage('success.LoadingDesignSuccess', undefined);
        } catch (error) {
          captureException(error, { extra: { design: e.data.data, error } });
          sendPostMessage('error.loadingDesignFailed', undefined);
        }
        break;
      }
      case 'design.applyLayout':
        dispatch(applyLayoutOperation(e.data.data));
        break;
      case 'design.applyAsset':
        dispatch(applyHostImageOperation(e.data.data));
        break;
      case 'design.enableSpreadEditing':
        dispatch(enableSpreadAction(e.data.data));
        break;
      case 'design.disableSpreadEditing':
        dispatch(disableSpreadAction(e.data.data));
        break;
      case 'design.setQuantizedAsset':
        dispatch(setQuantizedAssetOperation(e.data.data));
        break;
      case 'design.setQuantizedAssetError':
        dispatch(setQuantizedAssetErrorOperation(e.data.data));
        break;
      case 'design.setDigitizedAsset':
        dispatch(addDigitizedAssetOperation(e.data.data));
        break;
      case 'design.setDigitizedAssetError':
        dispatch(setDigitizedAssetErrorOperation(e.data.data));
        break;
      case 'design.setDesignTemplates':
        dispatch(setTemplatesOperation(e.data.data));
        break;
      case 'design.setDesignTemplate':
        showToastr('success', i18n.t('Design saved in Templates'), {
          timeOut: 5000,
          ActionButton: <ButtonOpenTemplatesTab />,
        });
        dispatch(addDesignTemplateOperation(e.data.data));
        break;
      case 'design.updateDesignTemplateResult':
        if (e.data.data.success && e.data.data.template) {
          showToastr('success', i18n.t('Design Templates name has been updated'), { timeOut: 3000 });
          dispatch(editDesignTemplateNameOperation(e.data.data.template));
        } else {
          showToastr('info', i18n.t('Could not Design Templates name'), { timeOut: 3000 });
        }
        break;
      case 'design.deleteDesignTemplateResult':
        dispatch(removeDesignTemplateOperation(e.data.data));
        break;
      case 'editor.setDisabledSpreads':
        dispatch(setDisabledSpreadsOperation(e.data.data));
        break;
      case 'uploader.previewGeneratedSuccess':
        void dispatch(addImageLocalPreviewFromUploader(e.data.data));
        break;
      case 'uploader.uploadError':
        dispatch(updateImageOnFailedUpload(e.data.data.file?.id));
        toastController.warningPersist(i18n.t('editor-image-issue'), i18n.t('editor-image-upload-failed'));
        break;
      case 'gallery.assetImported':
      case 'uploader.uploadSuccess':
        void dispatch(updateImageOnUploadOperation(e.data.data));
        break;
      case 'uploader.setConfig':
        dispatch(setUploaderConfigOperation(e.data.data));
        break;
      case 'gallery.setUploadedImages':
        if (e.data.data) {
          void dispatch(addUploadedImages(e.data.data, true));
        }
        break;
      case 'gallery.loadUploadedImages':
        if (e.data.data) {
          void dispatch(addUploadedImages(e.data.data));
        }
        break;
      case 'gallery.removeImages':
        dispatch(removeImagesOperation(e.data.data as string[]));
        break;
      case 'gallery.assetUpdated':
        if (!e.data.data.success) {
          showToastr('info', i18n.t('Could not update image name'), {
            timeOut: 3000,
          });
          break;
        }

        showToastr('success', i18n.t('Image name has been updated'), {
          timeOut: 3000,
        });
        dispatch(updateImageOnUpdatedMetadataOperation(e.data.data.asset));
        break;
      case 'editor.setSidebarActiveTabName':
        dispatch(setSidebarActiveTabByNameOperation(e.data.data, true));
        break;
      case 'editor.setSidebarActiveTab':
        dispatch(setSidebarActiveTabOperation(e.data.data));
        break;
      case 'editor.setSidebarTabs':
        dispatch(setBaseTabsOperation(e.data.data));
        break;
      case 'editor.setGroupedSidebarTabs':
        dispatch(setGroupedTabsOperation(e.data.data));
        break;
      case 'design.spreadBackgrounds':
        void dispatch(setSpreadBackgroundsOperation(e.data.data));
        break;
      case 'design.spreadForegrounds':
        void dispatch(setSpreadForegroundsOperation(e.data.data));
        break;
      case 'addons.setAddons':
        dispatch(setAddonsDataOperation(e.data.data));
        break;
      case 'addons.setAddonsInUse':
        dispatch(setAddonsInUseOperation(e.data.data || []));
        break;
      case 'addons.setAddonsAttributionImage':
        dispatch(setAddonsAttributionImageOperation(e.data.data));
        break;
      case 'addons.addonUploaded':
        void dispatch(addonUploadedOperation(e.data.data));
        break;
      case 'plugins.assetUploaded':
        dispatch(pluginAssetUploadedOperation(e.data.data));
        break;
      case 'addons.addonUploadFailed':
        dispatch(addonUploadFailedOperation(e.data.data));
        break;
      case 'editor.setFormatsData':
        dispatch(setFormatsDataOperation(e.data.data));
        break;
      case 'editor.setDefaultZoom':
        dispatch(setDefaultZoomOperation(e.data.data.zoom));
        break;
      case 'editor.setSpreadsDefaultZoom':
        dispatch(setSpreadsDefaultZoomOperation(e.data.data.zoom));
        break;
      case 'editor.applyLayout':
        dispatch(applyLayoutSchemaByIndexToCurrentSpreadOperation(Number(e.data.data)));
        break;
      case 'preview.setThe3dPreviewAvailable':
        dispatch(setThe3dPreviewAvailableOperation(e.data.data));
        break;
      case 'preview.setFlatPreviewAvailable':
        dispatch(setFlatPreviewAvailableOperation(e.data.data));
        break;
      case 'preview.setSpreadPreview':
        dispatch(setSpreadPreviewOperation(e.data.data));
        break;
      case 'preview.setRenders':
        dispatch(setRendersOperation(e.data.data));
        break;
      case 'preview.rendersError':
        dispatch(setPreviewStatusOperation(PreviewStatus.ERROR));
        break;
      case 'preview.flatPreviewReady':
        dispatch(setFlatPreviewReadyOperation(e.data.data));
        break;
      case 'preview.flatPreviewFailed':
        dispatch(setFlatPreviewFailedOperation(e.data.data));
        break;
      case 'editor.requestAnswer': {
        const { requestId, data } = e.data.data;
        RequestHandler.getInstance().onRequestAnswer(requestId, data);
        break;
      }
      case 'plugins.applyModifiedImage':
        dispatch(applyModifiedImageOperation(e.data.data));
        break;
      case 'plugins.resetModifiedImage':
        dispatch(resetModifiedImageOperation(e.data.data));
        break;
      case 'plugins.setPlugins':
        dispatch(setPluginsOperation(e.data.data));
        break;
      case 'advancedPersonalisation.folderContent':
        void dispatch(setCollectionFolderOperation(e.data.data));
        break;
      case 'advancedPersonalisation.addFolderToDesign':
        void dispatch(addOrApplyCollectionFolderToPageOperation(e.data.data));
        break;
      case 'advancedPersonalisation.libraryClosed':
        dispatch(refreshCollectionCacheOperation());
        break;
      case 'asset.setAssetWithReplacedColors':
        dispatch(setAssetWithReplacedColorsOperation(e.data.data));
        break;
      case 'editor.setupAmplitude': {
        const { userId } = e.data.data as { userId: string };
        amplitude.setUserId(userId);
        break;
      }
      case 'fonts.setFontList':
        dispatch(setFontListOperation(e.data.data));
        break;
      case 'editor.setImagePatternScaleBases':
        dispatch(setImagePatternScaleBasesOperation(e.data.data));
        break;
      case 'mockup.setProductPlaceholderData':
      case 'mockup.setData':
        void dispatch(setMockupDataOperation(e.data.data));
        break;
      case 'mockup.setScenePlaceholderData':
        dispatch(setScenePlaceholderMockupDataOperation(e.data.data));
        break;
      case 'mockup.variant':
        void dispatch(saveVariantOperation(e.data.data));
        break;
      case 'mockup.setSceneStructure':
        dispatch(loadSceneOperation(e.data.data));
        break;
      case 'mockup.setMockupStructure':
        dispatch(loadMockupOperation(e.data.data));
        break;
      case 'mockup.setStores':
        dispatch(setStoresOperation(e.data.data));
        break;
      case 'mockup.setStoreProducts':
        dispatch(setStoreProductsOperation(e.data.data));
        break;
      case 'mockup.setStoreProductVariants':
        dispatch(setStoreProductVariantsOperation(e.data.data));
        break;
      case 'mockup.setPreview':
        dispatch(setMockupPreviewOperation(e.data.data.variant));
        break;
      case 'mockup.setProductPreview':
        dispatch(setMockupProductPreviewOperation(e.data.data));
        break;
      case 'mockup.previewGenerationFailed':
        dispatch(onMockupGenerationFailedOperation());
        break;
      case 'mockup.downloadStarted':
        dispatch(onMockupDownloadStarted());
        break;
      case 'mockup.downloadReady':
        void dispatch(onMockupDownloadReadyOperation(e.data.data.url, e.data.data.format));
        break;
      case 'mockup.downloadFailed':
        dispatch(onMockupDownloadFailedOperation());
        break;
      case 'mockup.setProductScenes':
        dispatch(setProductScenesOperation(e.data.data));
        break;
      case 'variants.setECommerceProduct':
        dispatch(setECommerceProductOperation(e.data.data));
        break;
      case 'variants.setECommerceVariantDesignToValidate':
        dispatch(setVariantDesignToValidateOperation(e.data.data));
        break;
      case 'draft.saveSuccess':
        dispatch(setDesignSavingStatusAction('saved'));
        break;
      case 'editor.continueButtonClick':
        dispatch(checkVariantsForErrorsOperation());
        break;
      case 'editor.setPersonalizationStudioBannerData':
        dispatch(setPersonalizationStudioBannerDataOperation(e.data.data));
        checkForPromotionBannersDismissalConditions();
        break;
      case 'editor.loadContentsquareScript':
        loadContentSquareScript(e.data.data.contentsquareLink);
        break;
      case 'editor.setElementsToHighlight':
        dispatch(setElementsToHighlightOperation(e.data.data));
        break;
      case 'editor.setTourSteps':
        dispatch(setIntroTourStepsOperation(e.data.data));
        break;
      case 'help.setArticles':
        dispatch(setHelpCenterArticlesAction(e.data.data));
        break;
      default:
        break;
    }

    if (e.data.callback && success) {
      const { type, data } = e.data.callback;
      sendPostMessage(type, data);
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage);
    sendPostMessage('editor.ready', undefined);
    sendPostMessage('editor.requestElementsToHighlight', undefined);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  return null;
}

export default React.memo(PostMessageReceiver);
