import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Checkbox from 'editor/src/component/Checkbox';
import IconCheck from 'editor/src/component/Icon/IconCheck';
import IconChevronUp from 'editor/src/component/Icon/IconChevronUp';

import styles from './index.module.scss';

export interface SelectOption {
  label: React.ReactNode | string; // For the display in the dropdown
  value: string | number | null; // For the identification of the option.
  content?: React.ReactNode; // Optional additional content
  disabled?: boolean;
  description?: string;
  previewImg?: string;
}

interface Props {
  label?: string;
  options: SelectOption[];
  onSelect: (selectedOption: SelectOption) => unknown;
  selected?: (string | number)[];
  className?: string;
  placeholder?: string;
  isMultiSelect?: boolean;
}

function Select({ label, options, onSelect, selected, className, placeholder, isMultiSelect = false }: Props) {
  const defaultOption = { label, value: null };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<(string | number)[]>(
    selected || (defaultOption.value ? [defaultOption.value] : []),
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (selected && !isEqual(selected, selectedOptions)) {
      setSelectedOptions(selected);
    }
  }, [selected, selectedOptions]);

  const handleSelect = (option: SelectOption) => {
    if (option.disabled) {
      return;
    }
    !isMultiSelect && setIsOpen(false);
    let newSelection: (string | number)[];
    if (!option.value) {
      newSelection = [];
    } else if (isMultiSelect) {
      if (selectedOptions.includes(option.value)) {
        newSelection = selectedOptions.filter((selectedOption) => selectedOption !== option.value);
      } else {
        newSelection = [...selectedOptions, option.value];
      }
    } else {
      newSelection = [option.value];
    }
    setSelectedOptions(newSelection);
    onSelect(option);
  };

  const selectedOptionsLabel = useMemo(() => {
    const selectedLabel = options.find(({ value }) => value === selectedOptions[0])?.label || '';
    if (selectedOptions.length <= 1) {
      return selectedLabel;
    }
    return `${selectedLabel} + ${selectedOptions.length - 1}`;
  }, [selectedOptions]);

  return (
    <div ref={dropdownRef} className={cn(styles.wrapper, className)}>
      {label && <div className={cn(styles.selectLabel)}>{label}</div>}

      <div
        onClick={() => setIsOpen(!isOpen)}
        className={cn(styles.selectedOption, 'cy-select-input', { [styles.isOpen]: isOpen })}
      >
        {placeholder && !selectedOptions.length && <div className={cn(styles.placeholder)}>{placeholder}</div>}
        <div className={cn(styles.selectedOptionLabel)}>{selectedOptionsLabel}</div>
        <div className={cn(styles.chevronIcon, { [styles.isOpen]: isOpen })}>
          <IconChevronUp />
        </div>
      </div>

      <div className={cn(styles.overlay, { [styles.isOpen]: isOpen })}>
        {options.map((option) => {
          const { value, label, previewImg, disabled, content, description } = option;
          const isSelected = value !== null && selectedOptions.includes(value);
          return (
            <div
              className={cn(styles.option, 'cy-select-option', {
                [styles.selected]: isSelected,
                [styles.disabled]: disabled,
                [styles.withPreview]: previewImg,
                [styles.multi]: isMultiSelect,
              })}
              key={value}
              onClick={() => handleSelect(option)}
            >
              {isMultiSelect && <Checkbox on={isSelected} disabled={disabled} />}
              <div className={cn(styles.label, isMultiSelect ? styles.multiSelect : undefined)}>
                <div className={styles.labelWrapper}>
                  {label}
                  {content && <div className={cn(styles.content)}>{content}</div>}
                  {description && <div className={cn(styles.content)}>{description}</div>}
                </div>
                {isSelected && !isMultiSelect && !previewImg && <IconCheck className={styles.check} />}
                {previewImg && <img className={styles.preview} src={previewImg} alt="option-preview" />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Select;
