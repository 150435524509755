import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getImageIdsUsage from 'editor/src/store/design/util/getImageIdsUsage';
import getImageIdsWithPersonalizationLocked from 'editor/src/store/design/util/getImageIdsWithPersonalizationLocked';
import applyImageOperation from 'editor/src/store/editor/operation/applyImageOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import isMultipleImageSelectAllowed from 'editor/src/store/editor/selector/isMultipleImageSelectAllowed';
import isPersonalizationLockIgnored from 'editor/src/store/editor/selector/isPersonalizationLockIgnored';
import isUseNotUploadedImagesAllowed from 'editor/src/store/editor/selector/isUseNotUploadedImagesAllowed';
import getGalleryImages from 'editor/src/store/gallery/selector/getGalleryImages';
import getHideUsedPhotos from 'editor/src/store/gallery/selector/getHideUsedPhotos';
import getImageAction from 'editor/src/store/gallery/selector/getImageAction';
import getSelectedGalleryImagesIds from 'editor/src/store/gallery/selector/getSelectedGalleryImagesIds';
import { selectImageAction, unselectImagesAction } from 'editor/src/store/gallery/slice';
import { AssetDigitizationStatus, GalleryImage } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ImageListItem from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/ImageListItem';
import IconInfo from 'editor/src/component/Icon/IconInfo';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';
import WithTooltip from 'editor/src/component/WithTooltip';

import GalleryImageTab, { GalleryTabItem } from './GalleryImageTab';
import VerticalMasonry from './VerticalMasonry';

import styles from './index.module.scss';

interface Props {
  onAddImageCb?: () => void;
  extraBottomPadding?: number;
}

function GalleryImageList({ onAddImageCb, extraBottomPadding }: Props) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<GalleryTabItem>(GalleryTabItem.All);

  const {
    images,
    imageAction,
    designData,
    allowedUseNotUploadedImages,
    allowSelectMultipleImages,
    ignorePersonalizationLock,
    hideUsedPhotos,
    isMultiSelectEnabled,
    canvasSelectedImageUuid,
    selectedGalleryImagesIds,
  } = useSelector((state) => {
    const currentSpreadIndex = getCurrentSpreadIndex(state);
    return {
      images: getGalleryImages(state),
      imageAction: getImageAction(state),
      designData: state.design.designData,
      hideUsedPhotos: getHideUsedPhotos(state),
      currentSpreadIndex,
      allowedUseNotUploadedImages: isUseNotUploadedImagesAllowed(state),
      allowSelectMultipleImages: isMultipleImageSelectAllowed(state),
      ignorePersonalizationLock: isPersonalizationLockIgnored(state),
      isMultiSelectEnabled: isMultipleImageSelectAllowed(state),
      canvasSelectedImageUuid: getSelectedImageUuid(state),
      selectedGalleryImagesIds: getSelectedGalleryImagesIds(state),
    };
  }, shallowEqual);

  const imageUsage = useMemo(() => getImageIdsUsage(designData), [designData]);

  const cannotDeleteImageIds = useMemo(() => {
    if (ignorePersonalizationLock) {
      return new Set<string>();
    }
    return getImageIdsWithPersonalizationLocked(designData);
  }, [designData, ignorePersonalizationLock]);

  const onImageClick = useCallback(
    (imageId: string) => {
      if (isMultiSelectEnabled && canvasSelectedImageUuid === undefined) {
        if (selectedGalleryImagesIds.includes(imageId)) {
          dispatch(unselectImagesAction([imageId]));
        } else {
          dispatch(selectImageAction(imageId));
        }
      } else {
        dispatch(applyImageOperation(imageId));
        onAddImageCb?.();
      }
    },
    [isMultiSelectEnabled, selectedGalleryImagesIds, canvasSelectedImageUuid],
  );

  const filteredImages = useMemo(
    () => images.filter((image) => !image.hidden && (!hideUsedPhotos || !imageUsage.get(image.id))),
    [images, hideUsedPhotos, imageUsage],
  );

  const onTabSwitch = (key: GalleryTabItem) => {
    setActiveTab(key);
  };

  const digitizedImages = useMemo(() => {
    return filteredImages.filter(
      (image) =>
        image.digitizationStatus === AssetDigitizationStatus.DIGITIZED ||
        image.digitizationStatus === AssetDigitizationStatus.DIGITIZING,
    );
  }, [filteredImages]);

  return (
    <div className={cn(styles.galleryImageList, 'cy-gallery-image-list')}>
      {!!digitizedImages.length && (
        <GalleryImageTab
          items={[
            {
              key: GalleryTabItem.All,
              label: <>{t('All uploaded files')}</>,
            },
            {
              key: GalleryTabItem.Digitized,
              label: (
                <>
                  {t('Digitized files')}&nbsp;
                  <WithTooltip
                    placement="top"
                    overlay={t('After digitization, you can reuse the file for future orders at no extra cost')}
                  >
                    <IconInfo size="small" />
                  </WithTooltip>
                </>
              ),
            },
          ]}
          activeKey={activeTab}
          onSwitch={onTabSwitch}
        />
      )}
      <VerticalMasonry<GalleryImage>
        elements={activeTab === GalleryTabItem.All ? filteredImages : digitizedImages}
        cols={3}
        spacing={4}
        minHeight={60}
        maxHeight={150}
        extraBottomPadding={extraBottomPadding}
        render={(image, x, y, width, height) => (
          <ImageListItem
            key={image.uuid}
            image={image}
            imageAction={imageAction}
            isSelected={selectedGalleryImagesIds.includes(image.id)}
            usage={imageUsage.get(image.id) ?? 0}
            noRemove={cannotDeleteImageIds.has(image.id)}
            allowedUseNotUploadedImages={allowedUseNotUploadedImages}
            allowMultipleImageSelect={allowSelectMultipleImages}
            isMobile={isMobile}
            onImageClick={onImageClick}
            x={x}
            y={y}
            width={width}
            height={height}
          />
        )}
      />
    </div>
  );
}

export default React.memo(GalleryImageList);
