import { Canvas as FabricCanvas, util as fabricNativeUtils } from 'fabric';
import { useState, useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadBackgroundImage from 'editor/src/store/design/selector/getSpreadBackgroundImage';
import getSpreadForegroundImage from 'editor/src/store/design/selector/getSpreadForegroundImage';
import { ContentAddress, Coords, Dimensions } from 'editor/src/store/design/types';
import getDefaultZoom from 'editor/src/store/editorModules/panAndZoom/selector/getDefaultZoom';
import { useSelector, useStore } from 'editor/src/store/hooks';

import { useBottomBarHeight } from './EditorAreaControls/BottomControls/BottomBarHeightProvider';
import { DEFAULT_TRANSFORM } from './Gestures';
import useGetCenter from './Gestures/useGetCenter';
import useMinZoom from './Gestures/useMinZoom';
import { CanvasRotation, VIEWPORT_CHANGED_EVENT } from './types';
import { POINT_0_0 } from './useCanvasRotation';
import getBestFitZoom from './utils/getBestFitZoom';
import { MAX_ZOOM } from './utils/zoomUtils';

function useSetupCanvasDisplay(
  fabricCanvas: FabricCanvas | undefined,
  canvasDim: Dimensions,
  spreadKey: string,
  spreadIndex: number,
  spreadCoords: Coords,
  focusedContentAddress: ContentAddress | undefined,
  mm2px: (x: number) => number,
  utilsSetup: boolean,
  spreadWidth: number,
  spreadHeight: number,
  autoZoom: boolean,
) {
  const store = useStore();
  const { defaultZoom, background, foreground } = useSelector((state) => {
    const spread = getSpread(state, spreadIndex);
    return {
      defaultZoom: autoZoom ? undefined : getDefaultZoom(state),
      background: getSpreadBackgroundImage(state, spread?.name || ''),
      foreground: getSpreadForegroundImage(state, spread?.name || ''),
    };
  }, shallowEqual);

  const [canvasRotation, setCanvasRotation] = useState<CanvasRotation>({
    angleDeg: 0,
    angleRad: 0,
    canvasCenter: POINT_0_0,
  });
  const { bottomBarHeight } = useBottomBarHeight();

  const { minZoom, fitZoom } = useMinZoom(canvasDim, spreadIndex, spreadWidth, spreadHeight, mm2px);
  const { getCenter } = useGetCenter(
    fabricCanvas,
    canvasDim,
    spreadIndex,
    spreadCoords,
    spreadWidth,
    spreadHeight,
    minZoom,
    mm2px,
  );

  const focusedContent = useMemo(() => {
    const spread = getSpread(store.getState(), spreadIndex);
    const focusedPage = focusedContentAddress && spread?.pages[focusedContentAddress.pageIndex];
    return focusedContentAddress && focusedPage?.groups.content?.[focusedContentAddress.contentIndex];
  }, [focusedContentAddress]);

  useEffect(() => {
    if (!fabricCanvas || !spreadWidth || !spreadHeight || !utilsSetup) {
      return;
    }
    const zoom = Math.min(defaultZoom ?? fitZoom, MAX_ZOOM);

    fabricCanvas.setZoom(zoom);
    const center = getCenter(zoom);
    fabricCanvas.absolutePan(center);

    const angleDeg = focusedContent?.rotate || 0;
    const angleRad = fabricNativeUtils.degreesToRadians(angleDeg);
    setCanvasRotation({
      angleDeg,
      angleRad,
      canvasCenter: fabricCanvas.getVpCenter(),
    });

    fabricCanvas.fire(VIEWPORT_CHANGED_EVENT as any);
    fabricCanvas.requestRenderAll();
  }, [getCenter, fitZoom, defaultZoom, !!fabricCanvas, focusedContent, utilsSetup, spreadKey]);

  useEffect(() => {
    (window as any).rotateCanvas = (angleDeg: number) => {
      if (fabricCanvas) {
        const angleRad = fabricNativeUtils.degreesToRadians(angleDeg);
        setCanvasRotation({
          angleDeg,
          angleRad,
          canvasCenter: fabricCanvas.getVpCenter(),
        });
      }
    };
  }, [fabricCanvas]);

  useEffect(() => {
    if (!fabricCanvas) {
      return undefined;
    }

    let previousMobileMenuOpen = false;
    let currentAnimateCancel: { abort: () => void } | undefined;

    // to avoid unnecessary React work. this should only update fabric canvas
    const cancel = store.subscribe(() => {
      const isMobileMenuOpen = store.getState().editor.mobileMenu.isOpen;
      if (previousMobileMenuOpen === isMobileMenuOpen) {
        return;
      }
      previousMobileMenuOpen = isMobileMenuOpen;
      currentAnimateCancel?.abort();

      const newCanvasDim = isMobileMenuOpen
        ? { width: canvasDim.width, height: Math.round(canvasDim.height * 0.6) }
        : canvasDim;

      const targetZoom = getBestFitZoom(
        newCanvasDim,
        spreadWidth,
        spreadHeight,
        background,
        foreground,
        focusedContent,
        mm2px,
        bottomBarHeight,
      );

      const currentZoom = fabricCanvas.getZoom();
      const vpt = fabricCanvas.viewportTransform || DEFAULT_TRANSFORM;
      const currentCenter = { x: vpt[4], y: vpt[5] };
      const targetCenter = getCenter(targetZoom, newCanvasDim);

      currentAnimateCancel = fabricNativeUtils.animate({
        startValue: 0,
        endValue: 1,
        duration: 150,
        easing: fabricNativeUtils.ease.easeOutSine,
        onChange: (percentage: number) => {
          const zoomvalue = currentZoom + (targetZoom - currentZoom) * percentage;
          fabricCanvas.setZoom(zoomvalue);
          const vpt = fabricCanvas.viewportTransform || DEFAULT_TRANSFORM;
          vpt[4] = currentCenter.x + (-targetCenter.x - currentCenter.x) * percentage;
          vpt[5] = currentCenter.y + (-targetCenter.y - currentCenter.y) * percentage;
          fabricCanvas.setViewportTransform(vpt);
          fabricCanvas.fire(VIEWPORT_CHANGED_EVENT as any);
          fabricCanvas.requestRenderAll();
        },
        onComplete: () => {
          fabricCanvas.requestRenderAll();
          fabricCanvas.fire(VIEWPORT_CHANGED_EVENT as any);
        },
      });
    });

    return () => cancel();
  }, [getCenter, fitZoom, defaultZoom, !!fabricCanvas, focusedContent, utilsSetup, spreadKey]);

  return canvasRotation;
}

export default useSetupCanvasDisplay;
