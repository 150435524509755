/* eslint-disable max-len */
import React from 'react';

interface Props {
  className?: string;
}

function IconWand({ className }: Props) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3667 5.63333L15 7L15.6334 5.63333L17 5L15.6334 4.36667L15 3L14.3667 4.36667L13 5L14.3667 5.63333ZM6.70004 5.63333L7.33337 7L7.96671 5.63333L9.33337 5L7.96671 4.36667L7.33337 3L6.70004 4.36667L5.33337 5L6.70004 5.63333ZM14.3667 13.3L15 14.6667L15.6334 13.3L17 12.6667L15.6334 12.0333L15 10.6667L14.3667 12.0333L13 12.6667L14.3667 13.3ZM4.50671 14.4602L5.53742 15.4909L10.4039 10.6244L9.37321 9.59371L4.50671 14.4602ZM3.09249 14.1067C2.89723 14.3019 2.89723 14.6185 3.09249 14.8138L5.18386 16.9051C5.37912 17.1004 5.69571 17.1004 5.89097 16.9051L13.5855 9.21058C13.7808 9.01532 13.7808 8.69873 13.5855 8.50347L11.4942 6.4121C11.2989 6.21684 10.9823 6.21684 10.787 6.4121L3.09249 14.1067Z"
        fill="#212121"
      />
    </svg>
  );
}

export default React.memo(IconWand);
