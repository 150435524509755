import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';
import updatePersonalizationOperation, {
  PersonalizationType,
  personalizationTypeToPluginMap,
} from 'editor/src/store/variants/operation/updateProductPersonalizationOperation';

import { RootState } from 'editor/src/store';

import EtsyIcon from 'editor/src/component/Icon/IconEtsy';
import ShopifyIcon from 'editor/src/component/Icon/IconShopify';
import Select, { SelectOption } from 'editor/src/component/Select';

import { EtsyPersonalizationPlugins, ShopifyPersonalizationPlugins } from '../plugins';

import { PersonalizationChannels } from './constants';

import styles from './index.module.scss';

interface Props {
  isShopifyConnected: boolean;
  isEtsyConnected: boolean;
}

interface ComputedOption {
  value: null | PersonalizationChannels;
  label: React.ReactElement;
}

function DefaultPersonalizationPlugin({ isShopifyConnected, isEtsyConnected }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shopifyOptions: SelectOption[] = [
    {
      value: 'in-page-personalization',
      label: t('Shopify in page personalization'),
      content: t('Shopify in page personalization content'),
    },
    {
      value: 'editor-personalization',
      label: t('Editor Personalization'),
      content: (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('Editor Personalization content'),
          }}
        />
      ),
    },
  ];

  const etsyOptions: SelectOption[] = [
    {
      value: 'ai-personalization',
      label: t('Automated etsy personalization'),
      content: t('Automated etsy personalization content'),
    },
    {
      value: 'email-workflow-personalization',
      label: t('Email workflow'),
      content: (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: t('Email workflow content') }}
        />
      ),
    },
  ];

  const globalPlugins = useSelector((state: RootState) => state.design.globalPlugins) || [];

  const selectedPersonalizationChannel = useMemo(() => {
    const activeEtsyOption = globalPlugins.find((p) => EtsyPersonalizationPlugins.includes(p as PluginName));
    if (activeEtsyOption) {
      const type = etsyOptions.find(
        (option) => personalizationTypeToPluginMap.get(option.value as PersonalizationType) === activeEtsyOption,
      )?.value;
      return { store: PersonalizationChannels.Etsy, type };
    }

    const activeShopifyOption = globalPlugins.find((p) => ShopifyPersonalizationPlugins.includes(p as PluginName));
    if (activeShopifyOption) {
      const type = shopifyOptions.find(
        (option) => personalizationTypeToPluginMap.get(option.value as PersonalizationType) === activeShopifyOption,
      )?.value;
      return { store: PersonalizationChannels.Shopify, type };
    }

    // Default case when none matches
    return null;
  }, [globalPlugins]);

  const options = useMemo(() => {
    const computedOptions: ComputedOption[] = [
      {
        value: null,
        label: <div className={cn(styles.labelWrapper)}>{t('Select the shop channel')}</div>,
      },
    ];

    if (isShopifyConnected) {
      computedOptions.push({
        value: PersonalizationChannels.Shopify,
        label: (
          <div className={cn(styles.labelWrapper)}>
            <ShopifyIcon className={cn(styles.shopifyIcon, styles.icon)} />
            <div>{t('Shopify')}</div>
          </div>
        ),
      });
    }

    if (isEtsyConnected) {
      computedOptions.push({
        value: PersonalizationChannels.Etsy,
        label: (
          <div className={cn(styles.labelWrapper)}>
            <EtsyIcon className={cn(styles.etsyIcon, styles.icon)} />
            <div>{t('Etsy')}</div>
          </div>
        ),
      });
    }

    return computedOptions;
  }, [isShopifyConnected, isEtsyConnected, t]);

  const defaultOption = options.find((o) => selectedPersonalizationChannel?.store === o.value);

  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(defaultOption);

  const selectedShopifyPersonalizationType =
    selectedPersonalizationChannel?.store === PersonalizationChannels.Shopify
      ? (shopifyOptions.find(({ value }) => value === selectedPersonalizationChannel.type) as SelectOption)
      : shopifyOptions[1];

  const selectedEtsyPersonalizationType =
    selectedPersonalizationChannel?.store === PersonalizationChannels.Etsy
      ? (etsyOptions.find(({ value }) => value === selectedPersonalizationChannel.type) as SelectOption)
      : etsyOptions[1];

  function onChannelSelect(option: SelectOption) {
    setSelectedOption(option);

    if (option.value === PersonalizationChannels.Shopify) {
      onPersonalizationTypeSelect(selectedShopifyPersonalizationType);
    } else {
      onPersonalizationTypeSelect(selectedEtsyPersonalizationType);
    }
  }

  function onPersonalizationTypeSelect(option: SelectOption) {
    dispatch(updatePersonalizationOperation(option.value as PersonalizationType));
  }

  return (
    <div>
      {!!options.length && (
        <div>
          <div className="mb-2 cy-personazilation-shop-channel-wrapper">
            <Select
              label={t('Select the shop channel')}
              options={options}
              onSelect={onChannelSelect}
              selected={defaultOption?.value ? [defaultOption.value] : []}
            />
          </div>

          <div className="mb-4 cy-personazilation-shop-channel-type-wrapper">
            {selectedOption?.value === PersonalizationChannels.Shopify && (
              <Select
                label={t('Select a default personalization type')}
                options={shopifyOptions}
                onSelect={onPersonalizationTypeSelect}
                selected={selectedShopifyPersonalizationType?.value ? [selectedShopifyPersonalizationType.value] : []}
              />
            )}
            {selectedOption?.value === PersonalizationChannels.Etsy && (
              <Select
                label={t('Select a default personalization type')}
                options={etsyOptions}
                onSelect={onPersonalizationTypeSelect}
                selected={selectedEtsyPersonalizationType.value ? [selectedEtsyPersonalizationType.value] : []}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default React.memo(DefaultPersonalizationPlugin);
