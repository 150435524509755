import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadContentElementsWithPossibleMirroring from 'editor/src/store/design/selector/getSpreadContentElementsWithPossibleMirroring';
import getStructureIndexByElementUuid from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { MediaElement, MediaImage } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { DPI_ADJUSTED } from 'editor/src/store/plugins/utils/imageManipulationUtils';

import getScaleAndCenterImageUpdates from 'editor/src/util/design/getScaleAndCenterImageUpdates';
import getBoxesMinMax from 'editor/src/util/getBoxesMinMax';

const applyImageScaleDownOperation =
  (element: MediaElement, scaleIndex: number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    dispatch(saveUndoRedoStateOperation('scale down element'));
    const state = getState();
    const elementAddress = getStructureIndexByElementUuid(state, element.uuid);
    if (!elementAddress) {
      return;
    }

    const spread = getSpread(state, elementAddress.spreadIndex);
    const contentElements = spread ? getSpreadContentElementsWithPossibleMirroring(spread) : undefined;
    const mediaElement = getMediaElement<MediaImage>(state, elementAddress);

    if (!contentElements?.length || !mediaElement) {
      return;
    }

    const bbox = getBoxesMinMax(contentElements);
    let elementUpdate = getScaleAndCenterImageUpdates(mediaElement, scaleIndex, bbox);
    if (!elementUpdate.adjustments?.find(({ name }) => name === DPI_ADJUSTED)) {
      const adjustments = [
        ...(elementUpdate.adjustments || []),
        { name: DPI_ADJUSTED, value: scaleIndex, custom: true },
      ];
      elementUpdate = {
        ...elementUpdate,
        adjustments,
      };
    }

    dispatch(updateMediaElementAction({ elementAddress, elementUpdate }));
  };

export default applyImageScaleDownOperation;
