import { IntroTourStep } from 'editor/src/store/editorModules/tour/types';

const TOUR_ELEMENTS_KEY = 'TOUR_ELEMENTS_HIDDEN_STATUS';

type TourElementType = 'printAreas' | 'introTour';

interface TourVisibilityElement {
  hiddenTimestamp: number;
  metadata?: any;
}

type TourVisibilityElements = {
  [key in TourElementType]: Record<string, TourVisibilityElement>;
};

const getTourVisibilityElements = (): TourVisibilityElements => {
  const tourElementsVisibilityJSON = window.localStorage.getItem(TOUR_ELEMENTS_KEY);
  return (tourElementsVisibilityJSON ? JSON.parse(tourElementsVisibilityJSON) : {}) as TourVisibilityElements;
};

const setTourVisibilityElements = (data: TourVisibilityElements) => {
  window.localStorage.setItem(TOUR_ELEMENTS_KEY, JSON.stringify(data));
};

export const isTourElementHidden = (type: TourElementType, key: string): boolean => {
  const tourElementsVisibilityElements = getTourVisibilityElements();

  // check expiration time if needed
  return !!tourElementsVisibilityElements?.[type]?.[key]?.hiddenTimestamp;
};

export const hideTourElement = (type: TourElementType, key: string, metadata?: any): void => {
  const tourElementsVisibilityElements = getTourVisibilityElements();
  if (!tourElementsVisibilityElements[type]) {
    tourElementsVisibilityElements[type] = {};
  }

  tourElementsVisibilityElements[type][key] = {
    hiddenTimestamp: Date.now(),
    metadata,
  };

  setTourVisibilityElements(tourElementsVisibilityElements);
};

export const resetTourElement = (type: TourElementType, key: string): void => {
  const tourElementsVisibilityElements = getTourVisibilityElements();
  if (!tourElementsVisibilityElements[type]) {
    return;
  }

  delete tourElementsVisibilityElements[type][key];

  setTourVisibilityElements(tourElementsVisibilityElements);
};

export const getToursStepsKey = (introTourSteps: IntroTourStep[]): string => {
  return introTourSteps.map((step) => step.id).join('__');
};
