import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShadowSide } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';
import setMockupShadowSideOperation from 'editor/src/store/mockup/operation/setMockupShadowSideOperation';
import { ControlUIType, VariationProductControl } from 'editor/src/store/variants/types';

import ButtonElement from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ButtonElement';
import SingleValueProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleValueProductControl';

import buttonStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleControl/index.module.scss';

import type { TFunction } from 'i18next';

function getOptions(t: TFunction): Array<{ title: string; value: ShadowSide }> {
  return [
    { title: t('Left'), value: 'left' },
    { title: t('Right'), value: 'right' },
  ];
}
interface Props {
  shadowSide: ShadowSide | undefined;
}

function MockupShadowSideControl({ shadowSide }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function onSelect(_: VariationProductControl, option: { title: string; value: ShadowSide }) {
    dispatch(setMockupShadowSideOperation(option.value));
  }

  const control: VariationProductControl = useMemo(
    () => ({
      type: 'single',
      uiType: ControlUIType.Button,
      key: 'shadow-side',
      title: t('Shadow side'),
      options: getOptions(t),
    }),
    [],
  );

  return (
    <SingleValueProductControl
      noTopBar
      className={cn(buttonStyles.ButtonControl, 'cy-shadow-side-control')}
      title={control.title}
      control={control}
      options={control.options}
      toggleOption={onSelect}
      selectedValue={shadowSide ?? 'left'}
      Element={ButtonElement}
    />
  );
}

export default React.memo(MockupShadowSideControl);
