import React, { useMemo } from 'react';

import { SingleProductControlProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';
import Select, { SelectOption } from 'editor/src/component/Select';
import WithTooltip from 'editor/src/component/WithTooltip';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

export type Option = { value: number; label: string };

function DropDownControl({
  control,
  toggleOption,
  value,
  noTopBar,
  IconElement,
  availableOptions,
  disabled,
  disabledControlDescription,
}: SingleProductControlProps) {
  const options = useMemo(
    () =>
      control.options.map((option) => {
        return {
          value: option.value,
          label: option.title,
          disabled: disabled || (!!availableOptions && !availableOptions.has(option.value)),
          previewImg: option.optionDetails?.previewImage,
          description: option.optionDetails?.subtitle,
        };
      }),
    [control.options, availableOptions],
  );

  const selectedOption = useMemo(
    () => control.options.find((option) => option.value === value),
    [value, control.options],
  );

  const onSelect = (option: SelectOption) => {
    const selectedOption = control.options.find(({ value }) => value === option.value);
    if (selectedOption) {
      toggleOption(control, selectedOption);
    }
  };

  const renderContent = (
    <>
      {!noTopBar && <PropertySeparator bigMargin />}
      <div className={controlStyles.controlTitleContainer}>
        <div className={controlStyles.controlTitle}>{control.title}</div>
        {IconElement && <IconElement />}
      </div>
      <Select options={options} onSelect={onSelect} selected={[selectedOption?.value || 0]} />
    </>
  );

  if (disabledControlDescription) {
    return (
      <WithTooltip overlay={disabledControlDescription} placement="top">
        {renderContent}
      </WithTooltip>
    );
  }

  return renderContent;
}

export default DropDownControl;
