import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import updateAllGridElementsOperation from 'editor/src/store/design/operation/calendarGrid/updateAllGridElementsOperation';
import { MediaGrid } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';
import Select, { SelectOption } from 'editor/src/component/Select';

import controlStyles from '../ProductControls/ProductControl.module.scss';

interface Props {
  noTopBar?: boolean;
  gridElement: MediaGrid;
}

type Option = { label: string; value: string; year: number; month: number };
const CURRENT_YEAR = new Date().getFullYear();

function StartDateControl({ noTopBar, gridElement }: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { gridDate } = gridElement.grid;

  const onSelect = useCallback((option: SelectOption) => {
    const selectedOption = options.find(({ value }) => value === option.value);
    if (selectedOption) {
      void dispatch(
        updateAllGridElementsOperation({
          gridDate: { year: selectedOption.year, month: selectedOption.month },
        }),
      );
    }
  }, []);

  const options = useMemo(() => {
    const options: Option[] = [];
    for (let i = 0; i < 36; i += 1) {
      const year = CURRENT_YEAR + Math.floor(i / 12);
      const month = i % 12;
      const dateOption = new Date(year, i % 12, 1);
      const monthName = dateOption.toLocaleString(i18n.languageWithDash, {
        month: 'long',
      });
      options.push({
        label: `${monthName} ${year}`,
        value: `${month}-${year}`,
        year,
        month,
      });
    }

    return options;
  }, [i18n.language]);

  const selectedOption = useMemo(
    () => options.find(({ year, month }) => year === gridDate.year && month === gridDate.month),
    [options, gridDate],
  );

  return (
    <>
      {!noTopBar && <PropertySeparator bigMargin />}
      <div className={controlStyles.controlTitleContainer}>
        <div className={controlStyles.controlTitle}>{t('editor-start-month-on')}</div>
      </div>
      <Select
        options={options}
        onSelect={onSelect}
        selected={[selectedOption?.value || '']}
        className="cy-start-date"
      />
    </>
  );
}

export default React.memo(StartDateControl);
