import { Thunk } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { setTemplatesAction } from '../slice';
import { SpreadDesignTemplate } from '../types';

const editDesignTemplateNameOperation =
  (payload: SpreadDesignTemplate): Thunk =>
  (dispatch, getState: () => RootState) => {
    const state = getState();

    const templates = state.editorModules.templates.designTemplates.map((template) =>
      template.id === payload.id ? payload : template,
    );

    dispatch(setTemplatesAction(templates));
  };

export default editDesignTemplateNameOperation;
