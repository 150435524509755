import { FabricObject } from 'fabric';
import { useEffect } from 'react';

import { MediaElement } from 'editor/src/store/design/types';
import selectPreviousTabOperation from 'editor/src/store/editorModules/sidebar/operation/selectPreviousTabOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { isNotDefaultTab, SystemTabNames, TabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useStore, useDispatch } from 'editor/src/store/hooks';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

const SystemTabs = new Set(SystemTabNames);

function canTypeDisplayTab(type: MediaElement['type'], tab: TabInfo, checkFontColorTab: boolean) {
  if (isNotDefaultTab(tab)) {
    return true;
  }

  if ((checkFontColorTab && tab === TAB_NAMES.FONT_COLORS) || tab === TAB_NAMES.FONT_FAMILIES) {
    return type === 'text';
  }
  if (tab === TAB_NAMES.GRID_DESIGNS) {
    return type === 'grid';
  }

  return true;
}

// support element selection through store
function useStoreSelection(
  fabricElementRef: React.RefObject<FabricObject>,
  uuid: number,
  type: MediaElement['type'],
  selected: boolean,
) {
  const fabricCanvas = useFabricCanvas();
  const dispatch = useDispatch();
  const store = useStore();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (selected) {
      if (fabricElementRef.current && fabricCanvas.getActiveObject() !== fabricElementRef.current) {
        fabricCanvas.setActiveObject(fabricElementRef.current);
        fabricCanvas.requestRenderAll();
      }
    } else if (fabricElementRef.current && fabricCanvas.getActiveObject() === fabricElementRef.current) {
      fabricCanvas.discardActiveObject();
      fabricCanvas.requestRenderAll();
    }

    const activeTab = getSidebarActiveTab(store.getState());
    if (
      !isMobile &&
      !selected &&
      !fabricCanvas.getActiveObject() &&
      activeTab &&
      SystemTabs.has(activeTab as TAB_NAMES)
    ) {
      dispatch(selectPreviousTabOperation());
    } else if (!isMobile && selected && activeTab && !canTypeDisplayTab(type, activeTab, true)) {
      dispatch(setSidebarActiveTabOperation(0));
    }

    return () => {
      const activeTab = getSidebarActiveTab(store.getState());
      if (selected && activeTab && !canTypeDisplayTab(type, activeTab, false)) {
        dispatch(setSidebarActiveTabOperation(0));
      }
    };
  }, [selected, uuid]);
}

export default useStoreSelection;
