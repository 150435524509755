import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import setActivePreviewOperation from 'editor/src/store/editorModules/preview/operation/setActivePreviewOperation';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import {
  CustomTabInfo,
  GenericPluginTabInfo,
  PluginTabInfo,
  TAB_NAMES,
} from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import { logEvent } from 'editor/src/amplitude';
import customIconsList from 'editor/src/util/customIconsList';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import useTabState from 'editor/src/component/DesktopSidebar/Tabs/useTabState';
import Icon from 'editor/src/component/Icon';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  name: TAB_NAMES;
  onMobileClick?: (active: boolean) => void;
  badge?: React.ReactElement;
  icon?: React.ReactElement;
  title: string;
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
}

export const getIcon = (
  tab: CustomTabInfo | PluginTabInfo | GenericPluginTabInfo,
  className?: string,
  iconProps?: { active?: boolean },
): JSX.Element | null => {
  if ('iconUrl' in tab) {
    return <div className={cn(styles.iconUrl, className)} style={{ backgroundImage: `url(${tab.iconUrl})` }} />;
  }

  if ('iconSVG' in tab) {
    // eslint-disable-next-line react/no-danger
    return <Icon className={cn(styles.iconSvg, className)} dangerouslySetInnerHTML={{ __html: tab.iconSVG }} />;
  }

  const CustomIcon = customIconsList[tab.iconName || ''];
  if (CustomIcon) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CustomIcon className={cn(styles.iconX, className)} {...iconProps} />
    );
  }

  return null;
};

function Tab({ icon, title, badge, name, pluginName, onMobileClick, customTabInfo }: Props) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { showPremium, pluginDisabled, active, enabled } = useTabState(name, pluginName);
  const { t } = useTranslation();

  const tabTitle = customTabInfo?.title || (customTabInfo?.translationKey ? t(customTabInfo?.translationKey) : title);

  const handleTabClick = () => {
    if (pluginName) {
      logEvent('tab opened', { name: pluginName });

      if (pluginDisabled) {
        sendPostMessage('plugins.disabledPluginClick', { name: pluginName });
        return;
      }

      if (showPremium) {
        sendPostMessage('plugins.pluginOpened', { pluginName });
      }
    } else {
      logEvent('tab opened', { name });
      sendPostMessage('log.tabChanged', { tab: name });
    }

    if (active) {
      if (isMobile) {
        dispatch(setSidebarActiveTabOperation(-1));
      }
    } else {
      dispatch(setSidebarActiveTabByNameOperation(name));
    }

    if (isMobile) {
      onMobileClick?.(active);
    }

    dispatch(setActivePreviewOperation(undefined));
  };

  const lowerCasedName = name.toLowerCase();
  return (
    <div
      className={cn(
        'cy-sidebar-tab',
        `cy-sidebar-tab-${lowerCasedName}`,
        `intro-tour-tab-${lowerCasedName}`,
        styles.tab,
        {
          'cy-sidebar-tab-active': active,
          'cy-sidebar-tab-disabled': pluginDisabled || !enabled,
          active,
          [styles.active]: active,
          [styles.disabled]: !enabled,
          [styles.mobile]: isMobile,
        },
      )}
      onClick={handleTabClick}
    >
      <div className={styles.tabContent}>
        {badge}
        <div
          className={cn(styles.iconContainer, {
            [styles.iconContainerMobile]: isMobile,
          })}
        >
          {customTabInfo ? getIcon(customTabInfo, undefined, { active }) : icon}
        </div>
        <span className={styles.title}>{tabTitle}</span>
      </div>
    </div>
  );
}

export default React.memo(Tab);
