import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getIntroTourSteps from 'editor/src/store/editorModules/tour/selector/getIntroTourSteps';
import { setActiveIntroStepAction } from 'editor/src/store/editorModules/tour/slice';
import { getToursStepsKey, resetTourElement } from 'editor/src/store/editorModules/tour/utils/tourStorage';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const triggerIntroTourOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const tourSteps = getIntroTourSteps(state);

  resetTourElement('introTour', getToursStepsKey(tourSteps));
  dispatch(setSidebarActiveTabOperation(-1));

  // wait until tab is switched to find proper element to highlight
  setTimeout(() => {
    dispatch(setActiveIntroStepAction(1));
  }, 0);
};

export default triggerIntroTourOperation;
