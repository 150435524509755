import getActiveIntroTourStep from 'editor/src/store/editorModules/tour/selector/getActiveIntroTourStep';
import { setActiveIntroStepAction } from 'editor/src/store/editorModules/tour/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import closeIntroTourOperation from './closeIntroTourOperation';

const setPreviousIntroStepOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const currentActiveStep = getActiveIntroTourStep(state) || 0;

  if (currentActiveStep === 1) {
    dispatch(closeIntroTourOperation());
    return;
  }

  dispatch(setActiveIntroStepAction(currentActiveStep - 1));
};

export default setPreviousIntroStepOperation;
