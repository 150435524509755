import cn from 'classnames';
import React, { useState } from 'react';

import updateMediaElementFiltersOperation from 'editor/src/store/design/operation/updateMediaElementFiltersOperation';
import getImageElementUrl from 'editor/src/store/design/selector/getImageElementUrl';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { ALLOW_FREE } from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent';
import Filter from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent/Filter';
import FILTERS, { FilterInfo } from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent/filters';

import FilterButton from './FilterButton';

import styles from './index.module.scss';

function FiltersList() {
  const [active, setActive] = useState(FILTERS[0].name);
  const activeFilter = FILTERS.find((filter) => filter.name === active);
  const nonFunctional = useSelector(
    (state) => getPlugin(state, PluginName.Filters)?.state === PluginState.NON_FUNCTIONAL,
  );
  const elements = useSelector(getSelectedMediaElementsObjects);
  const firstElement = elements[0];
  const address = useSelector((state) =>
    firstElement ? getStructureIndexByElementUuidMemoized(state, firstElement.uuid) : undefined,
  );
  const imageUrl = useSelector((store) => {
    const image = address && getMediaElement(store, address);
    if (image?.type !== 'image') {
      return undefined;
    }
    const { imageUrl, crossOrigin } = getImageElementUrl(store, image, true);
    return crossOrigin === 'anonymous' ? imageUrl : undefined;
  });
  const premiumEnabled = useSelector((state) => getPlugin(state, PluginName.Filters)?.state === PluginState.ENABLED);
  const dispatch = useDispatch();

  if (!imageUrl || !address || firstElement?.type !== 'image') {
    return null;
  }

  const onFilterClick = (filter: FilterInfo) => {
    setActive(filter.name);
    dispatch(updateMediaElementFiltersOperation(address, filter, filter.default));
  };
  const onDisabledClick = (filter: FilterInfo) => {
    sendPostMessage('plugins.disabledPluginClick', {
      name: PluginName.Filters,
      filter: filter.name,
    });
  };

  return (
    <>
      {activeFilter && (
        <div
          className={cn(styles.adjustmentSliderRow, {
            [styles.empty]: activeFilter.empty,
          })}
        >
          <Filter
            filter={activeFilter}
            address={address}
            imageUrl={imageUrl}
            element={firstElement}
            mobile
            showPremiumIcon={premiumEnabled}
          />
        </div>
      )}
      <div className={styles.filtersWrapper}>
        {FILTERS.map((filter, idx) => {
          const disabled = nonFunctional && idx >= ALLOW_FREE;
          return (
            <FilterButton
              filter={filter}
              on={filter.name === active}
              onClick={disabled ? onDisabledClick : onFilterClick}
              imageUrl={imageUrl}
              key={filter.name}
              disabled={disabled}
              showPremiumIcon={premiumEnabled && idx >= ALLOW_FREE}
            />
          );
        })}
      </div>
    </>
  );
}

export default React.memo(FiltersList);
