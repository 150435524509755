import { Canvas as FabricCanvas } from 'fabric';

import { isFabricPathText } from 'editor/src/fabric/FabricPathText';

export default function removeTextEditing(fabricCanvas: FabricCanvas): void {
  const activeObject = fabricCanvas.getActiveObject();
  if (activeObject && isFabricPathText(activeObject) && activeObject.isEditing) {
    activeObject.exitEditing();
  }
}

export const MAX_ZOOM = 4;
