import { DesignData } from 'editor/src/store/design/types';
import { setAdvancedFlatPreviewsAction } from 'editor/src/store/editorModules/preview/slice';
import { FlatPreview } from 'editor/src/store/editorModules/preview/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const setAdvancedFlatPreviewsOperation =
  (productAdvancedFlatPreviews: FlatPreview[], designStructure?: DesignData) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const advancedFlatPreviews = state.editorModules.preview.advancedFlatPreviews || {};
    const productUid = designStructure?.product_uid;
    if (!productUid) {
      return;
    }

    const newAdvancedFlatPreviews = { ...advancedFlatPreviews, [productUid]: productAdvancedFlatPreviews };
    dispatch(setAdvancedFlatPreviewsAction(newAdvancedFlatPreviews));
  };

export default setAdvancedFlatPreviewsOperation;
