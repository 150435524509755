import { util as fabricNativeUtils, Point as FabricPoint } from 'fabric';

import { Coords, SpreadGroundImage, Content, Page, Dimensions } from 'editor/src/store/design/types';

import { getSpreadGroundOffsets } from 'editor/src/component/SpreadPreview/getThumbnailZoomAndPan';

import { getFitZoom, getMaxElementHeight, getMaxElementWidth } from './getBestFitZoom';

// the idea here is to take into account background & foreground when computing the center
// we compute the zoom that matches the spread dimensions. then, if the zoom level is smaller (zoomed out) than the spread zoom
// we will center based on the background/foreground. if the zoom level is bigged (zoomed in) than the spread zoom, we wil
// center based on the spread itself.
function getContentCenter(
  canvasDim: { width: number; height: number },
  spreadWidth: number,
  spreadHeight: number,
  background: SpreadGroundImage | undefined,
  foreground: SpreadGroundImage | undefined,
  spreadCoords: Coords,
  zoom: number,
  minZoom: number,
  mm2px: (size: number) => number,
  focusedContentInfo: { content: Content; page: Page } | undefined,
  defaultZoom: number | undefined,
  bottomBarHeight: number,
): FabricPoint {
  const spreadWidthPx = mm2px(spreadWidth);
  const spreadHeightPx = mm2px(spreadHeight);

  const contentWidth = focusedContentInfo ? mm2px(focusedContentInfo.content.width) : spreadWidthPx;
  const contentHeight = focusedContentInfo ? mm2px(focusedContentInfo.content.height) : spreadHeightPx;
  const coordsLeft = focusedContentInfo
    ? spreadCoords.left + mm2px(focusedContentInfo.page.x + focusedContentInfo.content.x)
    : spreadCoords.left;
  const coordsTop = focusedContentInfo
    ? spreadCoords.top + mm2px(focusedContentInfo.page.y + focusedContentInfo.content.y)
    : spreadCoords.top;
  const xSpreadCenter = (contentWidth / 2 + coordsLeft) * zoom - canvasDim.width / 2;
  const ySpreadCenter = (contentHeight / 2 + coordsTop) * zoom - (canvasDim.height - bottomBarHeight) / 2;

  // will compute the zoom level that matches the spread (or focused content) dimensions
  const spreadFitZoom = getFitZoom(
    canvasDim,
    { width: contentWidth, height: contentHeight },
    !!(background || foreground),
    bottomBarHeight,
  );
  // the zoom level at which we know we need to start centering on the spread
  const spreadCenterZoom = Math.min(defaultZoom ?? spreadFitZoom, spreadFitZoom);

  let x;
  let y;
  // when we are at spread zoom level or closer, we want to center based on the middle of the spread
  if (zoom >= spreadCenterZoom || (!background && !foreground)) {
    x = xSpreadCenter;
    y = ySpreadCenter;
  } else {
    // when we are smaller than spread level, we want to center based on the middle of the biggest between background/foreground/spread/foused content.
    const width = getMaxElementWidth(background, foreground, contentWidth);
    const height = getMaxElementHeight(background, foreground, contentHeight);

    const spreadDim: Dimensions = {
      width: spreadWidthPx,
      height: spreadHeightPx,
    };
    const backgroundOffset = (background && getSpreadGroundOffsets(background, spreadDim)) || null;
    const foregroundOffset = (foreground && getSpreadGroundOffsets(foreground, spreadDim)) || null;
    const offsetLeft = Math.max(backgroundOffset?.offsetLeft || 0, foregroundOffset?.offsetLeft || 0) * zoom;
    const offsetTop = Math.max(backgroundOffset?.offsetTop || 0, foregroundOffset?.offsetTop || 0) * zoom;

    // the x/y center taking into account the biggest between background/foreground/spread
    const xFullCenter = (width / 2 + coordsLeft) * zoom - canvasDim.width / 2 - offsetLeft;
    const yFullCenter = (height / 2 + coordsTop) * zoom - (canvasDim.height - bottomBarHeight) / 2 - offsetTop;

    // if the zoom is close to the spread zoom, we want the position to be close to xSpreadCenter,ySpreadCenter.
    const zoomSpan = spreadCenterZoom - minZoom;
    const zoomDistToMin = spreadCenterZoom - zoom;
    x = (zoomDistToMin / zoomSpan) * xFullCenter + ((zoomSpan - zoomDistToMin) / zoomSpan) * xSpreadCenter;
    y = (zoomDistToMin / zoomSpan) * yFullCenter + ((zoomSpan - zoomDistToMin) / zoomSpan) * ySpreadCenter;
  }

  return fabricNativeUtils.rotatePoint(
    new FabricPoint(x, y),
    new FabricPoint(x, y - bottomBarHeight / 2),
    fabricNativeUtils.degreesToRadians(focusedContentInfo?.content.rotate || 0),
  );
}

export default getContentCenter;
