import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import applyImageScaleDownOperation from 'editor/src/store/design/operation/applyImageScaleDownOperation';
import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import { MediaElement } from 'editor/src/store/design/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import hasSidebarTab from 'editor/src/store/editorModules/sidebar/selector/hasSidebarTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { DPIWarning, WarningLevel } from 'editor/src/store/editorModules/warnings/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { DEFAULT_MEDIUM_DPI } from 'editor/src/store/hostSettings/slice';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import useCustomToastr from 'editor/src/component/CustomToastrComponent/useCustomToastr';
import LinkButton from 'editor/src/component/LinkButton';
import { getDPIWarningTooltip } from 'editor/src/component/TopMenuDesktop/buttons/ButtonWarnings/useElementWarnings';

import AbstractWarningItem from './AbstractWarningItem';

import styles from './index.module.scss';

interface Props {
  warning: DPIWarning;
  element: MediaElement;
  selected: boolean;
}

function LowDpiWarningItem({ warning, element, selected }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showToastr = useCustomToastr();
  const dpiLevelsSettings = useSelector(getCurrentDpiLevels, shallowEqual);
  const isLowDpi = warning.level === WarningLevel.High;
  const title = isEmbedded ? t('Low quality image') : getDPIWarningTooltip(warning.dpi, warning.level, t);
  const message = useMemo(() => {
    if (isLowDpi) {
      return t('editor-poor-quality-info', { dpi: Math.round(dpiLevelsSettings?.low ?? DEFAULT_MEDIUM_DPI) });
    }
    if (!isEmbedded) {
      return t('editor-average-quality-info', { dpi: Math.round(dpiLevelsSettings?.medium ?? DEFAULT_MEDIUM_DPI) });
    }
    return t('Low quality image message');
  }, [warning.level, dpiLevelsSettings, isLowDpi]);

  const { isGalleryTabActive, dpiLimits } = useSelector((state) => ({
    isGalleryTabActive: hasSidebarTab(state, TAB_NAMES.GALLERY),
    dpiLimits: getCurrentDpiLevels(state),
  }));

  const activateFilesTab = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.GALLERY));
  };

  const resizeLowDpiImage = () => {
    const scale = warning.dpi / (dpiLimits.low + 1);
    dispatch(applyImageScaleDownOperation(element, scale));

    showToastr('success', t('Image was adjusted'), { timeOut: 5000 });
  };

  return (
    <AbstractWarningItem warning={warning} element={element} selected={selected} title={title}>
      {message}
      {isLowDpi && (
        <>
          <LinkButton
            className={cn('cy-scale-down-image', styles.actionLinkButton)}
            black={false}
            onClick={resizeLowDpiImage}
          >
            {t('Scale image down')}
          </LinkButton>
          {isGalleryTabActive && (
            <LinkButton
              className={cn('cy-choose-different-image', styles.actionLinkButton)}
              black={false}
              onClick={activateFilesTab}
            >
              {t('Choose different image')}
            </LinkButton>
          )}
        </>
      )}
    </AbstractWarningItem>
  );
}

export default React.memo(LowDpiWarningItem);
