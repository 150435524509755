import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrintTechnology } from 'editor/src/store/design/types';
import { ProductControlOption, ProductControlSingle } from 'editor/src/store/variants/types';

import printTechnicImg from 'editor/src/static/print-technic.png';
import printTechnologyImg from 'editor/src/static/print-technology.png';

import { SingleProductControlProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import SingleValueProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleValueProductControl';

import ButtonElement from '../../elements/ButtonElement';
import InfoBtnControl from '../../elements/InfoBtnControl';
import PrintTechnicInfoBanner from '../../elements/PrintTechnicInfoBanner';

import styles from './index.module.scss';

function SingleControlTechnology({
  control,
  element,
  multiMode,
  toggleOption,
  value,
  noTopBar,
  availableOptions,
  unAvailableOptions,
  disabled,
  disabledControlDescription,
}: SingleProductControlProps) {
  const { t } = useTranslation();

  // if only one option
  if (control.options.length < 2) {
    return null;
  }

  const { options } = control;
  const printTechnicOptions = options.filter(({ title }) => title !== PrintTechnology.Embroidery);
  const selectedPrintTechnic = [PrintTechnology.DTF.toLowerCase(), PrintTechnology.DTG.toLowerCase()].includes(value)
    ? value
    : undefined;

  // show Technology section if Embroidery and DTF/DTG
  const embroideryOption = options.find(({ title }) => title === PrintTechnology.Embroidery);
  const printingOption: ProductControlOption | undefined = printTechnicOptions.length
    ? {
        title: 'Printing',
        value: selectedPrintTechnic || printTechnicOptions[0].value,
      }
    : undefined;

  const toggleOptionOverwrite = (control: ProductControlSingle, option: ProductControlOption): void => {
    if (option.title === printingOption?.title && !selectedPrintTechnic) {
      return toggleOption(control, printTechnicOptions[0]);
    }
    return toggleOption(control, option);
  };

  const printTechnologyInfo = () => (
    <InfoBtnControl
      title="print-technology-tooltip-title"
      description="print-technology-tooltip-description"
      components={{ image: <img src={printTechnologyImg} alt={t('Print technology')} /> }}
    />
  );
  const technologyProductControl =
    control.options.length > 1 && embroideryOption && printingOption ? (
      <SingleValueProductControl
        className={styles.technologyControl}
        options={[printingOption, embroideryOption]}
        title={control.title}
        control={control}
        availableOptions={availableOptions}
        toggleOption={toggleOptionOverwrite}
        selectedValue={value}
        Element={ButtonElement}
        noTopBar={noTopBar}
        IconElement={printTechnologyInfo}
        unAvailableOptions={unAvailableOptions}
        disabled={disabled}
        disabledControlDescription={disabledControlDescription}
      />
    ) : undefined;

  // show Print Technic Radio Btn if DTF and DTG and Technology == Printing
  const printTechnicInfo = () => (
    <InfoBtnControl
      title="print-technic-tooltip-title"
      description="print-technic-tooltip-description"
      components={{
        image: <img src={printTechnicImg} alt={t('Print technic')} />,
        ul: <ul />,
        li: <li />,
      }}
    />
  );
  const printTechnicProductControl =
    printTechnicOptions.length > 1 && PrintTechnology.Embroidery.toLowerCase() !== value ? (
      <>
        <SingleValueProductControl
          className={cn(styles.printTechnicControl, !multiMode ? styles.singlePrintTechnicControl : undefined)}
          options={printTechnicOptions}
          title={t('Print technic')}
          control={control}
          availableOptions={availableOptions}
          toggleOption={toggleOption}
          selectedValue={value}
          Element={element}
          noTopBar={Boolean(embroideryOption) || noTopBar}
          IconElement={printTechnicInfo}
          unAvailableOptions={unAvailableOptions}
          disabled={disabled}
          disabledControlDescription={disabledControlDescription}
        />
        <PrintTechnicInfoBanner selectedTechnics={[value]} />
      </>
    ) : undefined;

  return (
    <>
      {technologyProductControl}
      {printTechnicProductControl}
    </>
  );
}

export default React.memo(SingleControlTechnology);
