import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  on: boolean;
  disabled?: boolean;
  className?: string;
  stopPropagation?: boolean;
  onSwitch: (on: boolean) => void;
}

function SwitchButton({ on, disabled, onSwitch, className, stopPropagation }: Props) {
  const toggleButton = (e: React.MouseEvent) => {
    stopPropagation && e.stopPropagation();
    onSwitch(!on);
  };

  return (
    <div
      className={cn(styles.SwitchButton, className, 'cy-switch-button', {
        [styles.on]: on,
        'cy-switch-button--on': on,
        [styles.disabled]: disabled,
      })}
      onClick={toggleButton}
    >
      <div className={styles.in} />
    </div>
  );
}

export default React.memo(SwitchButton);
