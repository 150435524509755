import { FabricObject } from 'fabric';
import React, { Suspense, useMemo } from 'react';

import { Coords, ElementAddress, MediaElement } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import {
  isLineVisibleInSpread,
  isRectangleElementVisibleInSpread,
} from 'editor/src/store/editorModules/warnings/utils/getNotVisibleWarning';

import retryPromiseFn from 'editor/src/util/retryPromiseFn';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import Image from './Image';
import { AreaClip } from './Image/types';
import Line from './Line';
import MockupPlaceholder from './MockupPlaceholder';
import Rectangle from './Rectangle';
import Text from './Text';

import type { Polygon } from 'polygon-clipping';

const CalendarGrid = React.lazy(
  retryPromiseFn(() => import('editor/src/component/EditorArea/Spread/Page/MediaElement/CalendarGrid')),
);

interface Props {
  elementData: MediaElement;
  elementIndex: number;
  spreadIndex: number;
  pageIndex: number;
  pageCoords: Coords;
  areaWidth: number;
  canvasRotation: CanvasRotation;
  contentClipPolygons: Polygon[];
  selected: boolean;
  isMobile: boolean;
  ignorePersonalizationLock: boolean;
  showGuides: boolean;
  focusedRect: AreaClip | undefined;
  contentClipPath?: FabricObject | undefined;
}

function MediaElementComponent({
  elementData,
  spreadIndex,
  pageIndex,
  elementIndex,
  pageCoords,
  areaWidth,
  canvasRotation,
  contentClipPolygons,
  selected,
  isMobile,
  ignorePersonalizationLock,
  showGuides,
  focusedRect,
  contentClipPath,
}: Props) {
  const elementAddress: ElementAddress = useMemo(
    () => ({ spreadIndex, pageIndex, elementIndex }),
    [spreadIndex, pageIndex, elementIndex],
  );

  const isVisible = useMemo(() => {
    if (!focusedRect || elementData.belongs_to) {
      return true;
    }

    if (elementData.type === 'line') {
      if (
        isLineVisibleInSpread(
          elementData,
          { left: focusedRect.x, top: focusedRect.y },
          focusedRect.width,
          focusedRect.height,
        )
      ) {
        return false;
      }
    } else if (
      !isRectangleElementVisibleInSpread(
        elementData,
        { left: focusedRect.x, top: focusedRect.y },
        focusedRect.width,
        focusedRect.height,
      )
    ) {
      return false;
    }

    return true;
  }, [focusedRect]);

  if (!isVisible) {
    return null;
  }

  switch (elementData.type) {
    case 'addon':
    case 'image': {
      if (isMediaMockupPlaceholder(elementData)) {
        return (
          <MockupPlaceholder
            elementData={elementData}
            elementAddress={elementAddress}
            pageCoords={pageCoords}
            canvasRotation={canvasRotation}
            selected={selected}
            isMobile={isMobile}
            ignorePersonalizationLock={ignorePersonalizationLock}
          />
        );
      }

      return (
        <Image
          elementData={elementData}
          elementAddress={elementAddress}
          pageCoords={pageCoords}
          canvasRotation={canvasRotation}
          contentClipPolygons={contentClipPolygons}
          selected={selected}
          isMobile={isMobile}
          ignorePersonalizationLock={ignorePersonalizationLock}
          showGuides={showGuides}
          contentClipPath={contentClipPath}
        />
      );
    }
    case 'text':
      return (
        <Text
          elementData={elementData}
          elementAddress={elementAddress}
          pageCoords={pageCoords}
          areaWidth={areaWidth}
          canvasRotation={canvasRotation}
          contentClipPolygons={contentClipPolygons}
          selected={selected}
          isMobile={isMobile}
          ignorePersonalizationLock={ignorePersonalizationLock}
          showGuides={showGuides}
          contentClipPath={contentClipPath}
        />
      );
    case 'grid':
      return (
        <Suspense fallback="">
          <CalendarGrid
            elementData={elementData}
            elementAddress={elementAddress}
            pageCoords={pageCoords}
            canvasRotation={canvasRotation}
            contentClipPolygons={contentClipPolygons}
            selected={selected}
            isMobile={isMobile}
            showGuides={showGuides}
          />
        </Suspense>
      );
    case 'line':
      return (
        <Line
          elementData={elementData}
          pageCoords={pageCoords}
          canvasRotation={canvasRotation}
          ignorePersonalizationLock={ignorePersonalizationLock}
          selected={selected}
          elementAddress={elementAddress}
          contentClipPolygons={contentClipPolygons}
          showGuides={showGuides}
          isMobile={isMobile}
          contentClipPath={contentClipPath}
        />
      );
    case 'rectangle':
      return (
        <Rectangle
          elementData={elementData}
          canvasRotation={canvasRotation}
          pageCoords={pageCoords}
          ignorePersonalizationLock={ignorePersonalizationLock}
          selected={selected}
          elementAddress={elementAddress}
          contentClipPolygons={contentClipPolygons}
          showGuides={showGuides}
          isMobile={isMobile}
          contentClipPath={contentClipPath}
        />
      );
    default:
      return null;
  }
}

export default MediaElementComponent;
