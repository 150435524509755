import { setIntroTourStepsAction } from 'editor/src/store/editorModules/tour/slice';
import { IntroTourStepWithoutOrder } from 'editor/src/store/editorModules/tour/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const setIntroTourStepsOperation = (steps: IntroTourStepWithoutOrder[]) => (dispatch: ThunkDispatch) => {
  dispatch(
    setIntroTourStepsAction(
      steps.map((step, index) => ({
        id: step.id,
        order: index + 1,
      })),
    ),
  );
};

export default setIntroTourStepsOperation;
