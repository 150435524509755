import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setLastGroupItemsAction, setStickyTabsAction } from 'editor/src/store/editorModules/sidebar/slice';
import {
  CustomTabInfo,
  GroupedTabData,
  isNotDefaultTab,
  TAB_NAMES,
  TabInfo,
} from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import setBaseTabsOperation from './setBaseTabsOperation';

const setGroupedTabsOperation = (tabs: GroupedTabData) => (dispatch: ThunkDispatch) => {
  const lastGroupItems: Array<TAB_NAMES | string> = [];
  const allTabs: TabInfo[] = [];
  tabs.groupedItems.forEach((group, index) => {
    const lastTabItem = group[group.length - 1] as TabInfo;
    if (index === tabs.groupedItems.length - 1 || !lastTabItem) {
      return;
    }

    lastGroupItems.push(isNotDefaultTab(lastTabItem) ? lastTabItem.id : (lastTabItem as TAB_NAMES));
  });

  const stickyItems = [...tabs.stickyItems];
  // HELP_CENTER should be by default
  if (!stickyItems.includes(TAB_NAMES.HELP_CENTER)) {
    stickyItems.push(TAB_NAMES.HELP_CENTER);
  }

  tabs.groupedItems.forEach((group) => {
    allTabs.push(...group);
  });
  allTabs.push(...stickyItems);

  batch(() => {
    dispatch(setLastGroupItemsAction(lastGroupItems));
    dispatch(setStickyTabsAction(stickyItems.map((tab) => (isNotDefaultTab(tab) ? tab.id : (tab as TAB_NAMES)))));
    dispatch(setBaseTabsOperation(allTabs as Array<TAB_NAMES | CustomTabInfo>));
  });
};

export default setGroupedTabsOperation;
