import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import iconInfo from 'editor/src/static/info-icon.svg';

import RichTooltip from 'editor/src/component/RichTooltip';

import styles from './index.module.scss';

interface Props {
  title: string;
  description?: string;
  components?: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement };
}
function InfoBtnControl({ title, description, components }: Props) {
  const { t } = useTranslation();
  const infoBtnRef = useRef<HTMLDivElement>(null);
  const [isInfoTooltipOpen, setIsInfoTooltipOpen] = useState(false);

  return (
    <div
      className={cn(styles.infoTooltipBlock, 'cy-info-tooltip')}
      ref={infoBtnRef}
      onClick={() => setIsInfoTooltipOpen(true)}
    >
      <RichTooltip
        title={t(title)}
        isVisible={isInfoTooltipOpen}
        className={styles.printTechnicTooltip}
        triggerBtnRef={infoBtnRef}
        onClose={() => setIsInfoTooltipOpen(false)}
        pointerOffset={60}
      >
        <Trans i18nKey={description} components={components} />
      </RichTooltip>
      <img src={iconInfo} alt={t(title)} draggable={false} />
    </div>
  );
}

export default React.memo(InfoBtnControl);
