import cn from 'classnames';
import React, { useMemo } from 'react';

import { useDispatch, useSelector } from 'editor/src/store/hooks';
import resetPageCountOperation from 'editor/src/store/variants/operation/resetPageCountOperation';
import getIsAProductSelected from 'editor/src/store/variants/selector/getIsAProductSelected';
import { PageOptions } from 'editor/src/store/variants/types';

import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';
import Select, { SelectOption } from 'editor/src/component/Select';

import generatePageOptions from './generatePageOptions';

import styles from './index.module.scss';
import controlStyles from '../ProductControl.module.scss';

interface Props {
  selectedPageCount: number;
  pages: PageOptions;
}

function PageCountControl({ pages, selectedPageCount }: Props) {
  const dispatch = useDispatch();
  const isAProductSelected = useSelector(getIsAProductSelected);
  const isSingleSelection = useSelector((state) => state.variants.configuration.singleSelection);
  const productUid = useSelector((state) => state.design.designData?.product_uid) || '';
  const dimensions = useSelector((state) => state.design.designData?.dimensions);
  const options = useMemo(() => generatePageOptions(pages), [pages]);

  const onSelect = (option: SelectOption) => {
    const selectedOption = options.find(({ value }) => value === option.value);
    if (selectedOption) {
      dispatch(resetPageCountOperation(selectedOption.value, dimensions, productUid));
    }
  };

  const selectedOption = options.find((option) => option.value === selectedPageCount);

  return (
    <>
      <PropertySeparator bigMargin />
      <div className={controlStyles.controlTitle}>{pages.name}</div>
      <Select
        options={options}
        onSelect={onSelect}
        selected={[selectedOption?.value || '']}
        className={cn('cy-page-count-select', {
          [styles.disabled]: !isSingleSelection && isAProductSelected,
        })}
      />
    </>
  );
}

export default React.memo(PageCountControl);
