import { Canvas as FabricCanvas } from 'fabric';

import getSpreadCoordinates from 'editor/src/store/design/selector/getSpreadCoordinates';
import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { MediaElement, Spread } from 'editor/src/store/design/types';

import { convFn } from 'editor/src/util/convFn';
import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';
import { Bbox, getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

let canvas: FabricCanvas | undefined;
let mm2px: convFn | undefined;

function setup(fabricCanvas: FabricCanvas, mm2pxFn: convFn) {
  canvas = fabricCanvas;
  mm2px = mm2pxFn;
}

function getHTMLCanvas() {
  return (canvas as any)?.lowerCanvasEl as HTMLCanvasElement | undefined;
}

function getElementCanvasBbox(element: MediaElement, spread: Spread, canvasRotation: number) {
  const htmlCanvas = getHTMLCanvas();
  if (!canvas || !htmlCanvas || !mm2px) {
    return undefined;
  }

  const spreadWidth = mm2px(getSpreadWidthFromSpread(spread));
  const spreadHeight = mm2px(getSpreadHeightFromSpread(spread));
  const spreadCoods = getSpreadCoordinates(canvas.getWidth(), canvas.getHeight(), spreadWidth, spreadHeight);
  const canvasBbox = htmlCanvas.getBoundingClientRect();
  const bounds = canvas.calcViewportBoundaries();

  const elementOffsetX = spreadCoods.left + mm2px(spread.pages[0].x);
  const elementOffsetY = spreadCoods.top + mm2px(spread.pages[0].y);

  let bbox: Bbox;
  if (element.type === 'line') {
    bbox = {
      width: mm2px(Math.max(Math.abs(element.x1 - element.x2), element.strokeWidth)),
      height: mm2px(Math.max(Math.abs(element.y1 - element.y2), element.strokeWidth)),
      x: elementOffsetX + mm2px(Math.min(element.x1, element.x2)),
      y: elementOffsetY + mm2px(Math.min(element.y1, element.y2)),
    };
  } else {
    bbox = getRectangleBoundingBox(
      elementOffsetX + mm2px(element.x),
      elementOffsetY + mm2px(element.y),
      mm2px(element.width),
      mm2px(element.height),
      element.r + canvasRotation,
    );
  }

  const vpCenter = canvas.getVpCenter();
  const coords = getPointPositionRotatedOnPoint(bbox.x, bbox.y, vpCenter.x, vpCenter.y, canvasRotation);

  const width = Math.min(bbox.width, canvasBbox.width) * canvas.getZoom();
  const height = Math.min(bbox.height, canvasBbox.height) * canvas.getZoom();
  const x = Math.min(canvasBbox.width, Math.max(0, coords[0] - bounds.tl.x)) * canvas.getZoom();
  const y = Math.min(canvasBbox.height, Math.max(0, coords[1] - bounds.tl.y)) * canvas.getZoom();

  return {
    x,
    y,
    width,
    height,
  };
}

const canvasService = {
  setup,
  getElementCanvasBbox,
  getHTMLCanvas,
};

export default canvasService;
