import { FabricObject } from 'fabric';

import { ObjectRect } from './Image/types';

function fabricElementToObjectRect(
  object: FabricObject,
  defaultValues?: ObjectRect,
  includeStrokeAndScaling = true,
): ObjectRect {
  return {
    left: object.left || defaultValues?.left || 0,
    top: object.top || defaultValues?.top || 0,
    width: (includeStrokeAndScaling ? object.getScaledWidth() : object.width) || defaultValues?.width || 1,
    height: (includeStrokeAndScaling ? object.getScaledHeight() : object.height) || defaultValues?.height || 1,
    angle: object.angle || defaultValues?.angle || 0,
  };
}

export default fabricElementToObjectRect;
