import { Point as FabricPoint, FabricObject, Transform as FabricTransform } from 'fabric';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';

import { tl2tl, tr2tl, bl2tl } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/transformUtils';

import getLineIntersection from './getLineIntersection';

type Segment = [FabricPoint, FabricPoint];

type Section = [
  dragP1: FabricPoint,
  dragOriginP1: FabricPoint,
  dragP1End: FabricPoint,
  dragP2: FabricPoint,
  dragOriginP2: FabricPoint,
  dragP2End: FabricPoint,
  scaleOrigin: FabricPoint,
  projectedImageP: FabricPoint,
  coordFn: (x: number, y: number, imageOriginal: FabricObject) => { x: number; y: number },
];

// returns 0 if point is on the line, <0 or >0 otherwise
// https://math.stackexchange.com/questions/274712/calculate-on-which-side-of-a-straight-line-is-a-given-point-located
function getPointSideRelativeToSegment(point: FabricPoint, segment: Segment) {
  return (
    (segment[1].x - segment[0].x) * (point.y - segment[0].y) - (segment[1].y - segment[0].y) * (point.x - segment[0].x)
  );
}

// returns all segments where the points is considered outside
export function getOutsideSegments(point: FabricPoint, object: FabricObject) {
  if (!object.aCoords) {
    return [];
  }
  const { tl, tr, br, bl } = object.aCoords;

  const out: Segment[] = [];
  if (getPointSideRelativeToSegment(point, [tl, tr]) < 0) {
    out.push([tl, tr]);
  }
  if (getPointSideRelativeToSegment(point, [tr, br]) < 0) {
    out.push([tr, br]);
  }
  if (getPointSideRelativeToSegment(point, [br, bl]) < 0) {
    out.push([br, bl]);
  }
  if (getPointSideRelativeToSegment(point, [bl, tl]) < 0) {
    out.push([bl, tl]);
  }
  return out;
}

// returns a segment if the point is outside of the object. the segment is chosen based on its proximity to refPoint.
export function getOutsideSegment(point: FabricPoint, refPoint: FabricPoint, object: FabricObject): Segment | null {
  const segments = getOutsideSegments(point, object);
  if (!segments.length) {
    return null;
  }

  // to get consistenly the same segment
  segments.sort((s1, s2) => {
    const d1 = getDistanceBetweenRefPointAndSegment(point, refPoint, s1);
    const d2 = getDistanceBetweenRefPointAndSegment(point, refPoint, s2);
    return d1 - d2;
  });

  return segments[0];
}

// computes the distance of the intersection between the [dragPoint, refPoint] and out segment.
// if they are parallel, it returns the distance between refPoint and the furthest out point
export function getDistanceBetweenRefPointAndSegment(
  dragPoint: FabricPoint,
  refPoint: FabricPoint,
  outSegment: Segment | null,
): number {
  if (!outSegment) {
    return Infinity;
  }

  const imgOnDragLineIntersection = getLineIntersection(dragPoint, refPoint, outSegment[0], outSegment[1]);
  if (imgOnDragLineIntersection) {
    return refPoint.distanceFrom(new FabricPoint(imgOnDragLineIntersection.x, imgOnDragLineIntersection.y));
  }

  return Math.max(refPoint.distanceFrom(outSegment[0]), refPoint.distanceFrom(outSegment[1]));
}

function getTransformationData(
  corner: FabricTransform['corner'],
  mask: FabricObject,
  maskOrigin: FabricObject,
  imageOrigin: FabricObject,
): Section | null {
  if (!mask.aCoords || !maskOrigin.aCoords || !imageOrigin.aCoords) {
    return null;
  }

  const { tl: maskTL, tr: maskTR, bl: maskBL, br: maskBR } = mask.aCoords;
  const { tl: maskOriginTL, tr: maskOriginTR, bl: maskOriginBL, br: maskOriginBR } = maskOrigin.aCoords;
  const { tl: imgOriginTL, tr: imgOriginTR, bl: imgOriginBL } = imageOrigin.aCoords;

  switch (corner) {
    case 'ml': {
      const maskOriginMR = new FabricPoint(
        (maskOriginTR.x + maskOriginBR.x) / 2,
        (maskOriginTR.y + maskOriginBR.y) / 2,
      );
      return [maskBL, maskOriginBL, maskOriginBR, maskTL, maskOriginTL, maskOriginTR, maskOriginMR, imgOriginTL, tl2tl];
    }
    case 'mr': {
      const maskOriginML = new FabricPoint(
        (maskOriginTL.x + maskOriginBL.x) / 2,
        (maskOriginTL.y + maskOriginBL.y) / 2,
      );
      return [maskBR, maskOriginBR, maskOriginBL, maskTR, maskOriginTR, maskOriginTL, maskOriginML, imgOriginTR, tr2tl];
    }
    case 'mt': {
      const maskOriginMB = new FabricPoint(
        (maskOriginBL.x + maskOriginBR.x) / 2,
        (maskOriginBL.y + maskOriginBR.y) / 2,
      );
      return [maskTL, maskOriginTL, maskOriginBL, maskTR, maskOriginTR, maskOriginBR, maskOriginMB, imgOriginTL, tl2tl];
    }
    case 'mb': {
      const maskOriginMT = new FabricPoint(
        (maskOriginTL.x + maskOriginTR.x) / 2,
        (maskOriginTL.y + maskOriginTR.y) / 2,
      );
      return [maskBL, maskOriginBL, maskOriginTL, maskBR, maskOriginBR, maskOriginTR, maskOriginMT, imgOriginBL, bl2tl];
    }
    default:
      return null;
  }
}

function getImageUpdateOnScale(
  transform: FabricTransform,
  mask: FabricObject,
  maskOrigin: FabricObject,
  imageOrigin: FabricObject,
): Partial<CustomFabricImage> {
  const section = getTransformationData(transform.corner, mask, maskOrigin, imageOrigin);
  if (!section) {
    return {};
  }

  const [dragP1, dragOriginP1, dragP1End, dragP2, dragOriginP2, dragP2End, scaleOrigin, projectedImageP, coordFn] =
    section;

  // check if the edges of the dragged segment are outside of the image
  const outSegment1 = getOutsideSegment(dragP1, dragOriginP1, imageOrigin);
  const outSegment2 = getOutsideSegment(dragP2, dragOriginP2, imageOrigin);

  if (outSegment1 || outSegment2) {
    // get the distance between the drag direction edge and the image borders.
    // for example: when dragging from the left, we check the top and bottom segment intersection with the image
    const d1 = getDistanceBetweenRefPointAndSegment(dragOriginP1, dragP1End, outSegment1);
    const d2 = getDistanceBetweenRefPointAndSegment(dragOriginP2, dragP2End, outSegment2);

    const distance = Math.min(d1, d2);
    const maskDist = dragP1End.distanceFrom(dragP1);

    if (distance === Infinity || distance > maskDist) {
      return {
        left: imageOrigin.left,
        top: imageOrigin.top,
        scaleX: imageOrigin.scaleX,
        scaleY: imageOrigin.scaleY,
      };
    }

    const ratio = maskDist / distance;

    // the coordFn is used when projecting another corner than top-left. the images are placed based on the top left corner.
    const adjustedCoords = coordFn(projectedImageP.x - scaleOrigin.x, projectedImageP.y - scaleOrigin.y, imageOrigin);

    // we just apply the ratio on the coordinates between the scale origin anf the projected corner;
    return {
      left: scaleOrigin.x + adjustedCoords.x * ratio,
      top: scaleOrigin.y + adjustedCoords.y * ratio,
      scaleX: (imageOrigin.scaleX || 1) * ratio,
      scaleY: (imageOrigin.scaleY || 1) * ratio,
    };
  }

  // the projected point is inside the image, nothing to do
  return {
    left: imageOrigin.left,
    top: imageOrigin.top,
    scaleX: imageOrigin.scaleX,
    scaleY: imageOrigin.scaleY,
  };
}

export default getImageUpdateOnScale;
