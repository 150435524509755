import { FabricObject, Transform as FabricTransform } from 'fabric';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';

export type ImageTransformFn = (
  transform: FabricTransform,
  mask: FabricObject,
  maskOrigin: FabricObject,
  imageOrigin: FabricObject,
) => Partial<CustomFabricImage>;

export function tr2tl(x: number, y: number, imageOriginal: FabricObject): { x: number; y: number } {
  if (!imageOriginal.aCoords) {
    return { x, y };
  }
  return {
    x: x - (imageOriginal.aCoords.tr.x - imageOriginal.aCoords.tl.x),
    y: y - (imageOriginal.aCoords.tr.y - imageOriginal.aCoords.tl.y),
  };
}

export function tl2tl(x: number, y: number): { x: number; y: number } {
  return { x, y };
}

export function bl2tl(x: number, y: number, imageOriginal: FabricObject): { x: number; y: number } {
  if (!imageOriginal.aCoords) {
    return { x, y };
  }
  return {
    x: x - (imageOriginal.aCoords.bl.x - imageOriginal.aCoords.tl.x),
    y: y - (imageOriginal.aCoords.bl.y - imageOriginal.aCoords.tl.y),
  };
}

export function br2tl(x: number, y: number, imageOriginal: FabricObject): { x: number; y: number } {
  if (!imageOriginal.aCoords) {
    return { x, y };
  }
  return {
    x: x - (imageOriginal.aCoords.br.x - imageOriginal.aCoords.tl.x),
    y: y - (imageOriginal.aCoords.br.y - imageOriginal.aCoords.tl.y),
  };
}
