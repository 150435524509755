import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import setGridFirstDayOfWeekOperation from 'editor/src/store/design/operation/calendarGrid/setGridFirstDayOfWeekOperation';
import { MediaGrid } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import { getWeekDays } from 'editor/src/util/dateUtils';

import Select, { SelectOption } from 'editor/src/component/Select';

import controlStyles from '../ProductControls/ProductControl.module.scss';

interface Props {
  gridElement: MediaGrid;
}

function FirstDayOfWeekControl({ gridElement }: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { firstDayOfWeek = 1 } = gridElement.grid;

  const onSelect = useCallback((option: SelectOption) => {
    const selectedOption = options.find(({ value }) => value === option.value);
    if (selectedOption) {
      dispatch(setGridFirstDayOfWeekOperation(selectedOption.value));
    }
  }, []);

  const options = useMemo(() => getWeekDays(i18n), [i18n.language]);

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === firstDayOfWeek),
    [options, firstDayOfWeek],
  );

  return (
    <>
      <div className={controlStyles.controlTitleContainer}>
        <div className={controlStyles.controlTitle}>{t('start-week-on')}</div>
      </div>
      <Select
        options={options}
        onSelect={onSelect}
        selected={[selectedOption?.value || 0]}
        className="cy-first-day-of-week"
      />
    </>
  );
}

export default React.memo(FirstDayOfWeekControl);
