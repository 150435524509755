import { util as fabricNativeUtils, Point as FabricPoint } from 'fabric';
import { useState, useMemo, useEffect } from 'react';

import { Coords } from 'editor/src/store/design/types';
import { DraggableItem } from 'editor/src/store/editor/types';
import { useSelector } from 'editor/src/store/hooks';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

function useDropZone(
  spreadCoords: Coords,
  onDrop: (item: DraggableItem) => void,
  enabled: boolean,
  dropToElementUuid: number | undefined,
  canvasRotation: CanvasRotation,
) {
  const { px2mm } = useFabricUtils();
  const isDragging = useSelector((state) => state.editor.dragState !== undefined);
  const [isDraggingOver, setDragOver] = useState(false);
  const isDraggingActive = enabled && isDragging;

  const events = useMemo(() => {
    const events: { [eventName: string]: (...e: any[]) => void } = {
      drop: (dropData: { x: number; y: number; draggableItem: DraggableItem }) => {
        const rotatedPoint = fabricNativeUtils.rotatePoint(
          new FabricPoint(dropData.x, dropData.y),
          canvasRotation.canvasCenter,
          canvasRotation.angleRad,
        );

        onDrop({
          ...dropData.draggableItem,
          dropCanvasCoords: {
            x: px2mm(rotatedPoint.x - spreadCoords.left),
            y: px2mm(rotatedPoint.y - spreadCoords.top),
          },
          dropToElementUuid,
        });
      },
    };

    if (isDraggingActive) {
      events.mouseover = () => setDragOver(true);
      events.mouseout = () => setDragOver(false);
    }

    return events;
  }, [isDraggingActive]);

  useEffect(() => {
    if (!isDraggingActive && isDraggingOver) {
      setDragOver(false);
    }
  }, [isDraggingActive]);

  return { events, isDraggingOver, isDraggingActive };
}

export default useDropZone;
