import { GalleryImage } from 'editor/src/store/gallery/types';

const ALLOWED_SOURCES_FOR_RENAME = ['url', 'Dashboard'];

const getImageName = (image: GalleryImage) => {
  // if image was provided by any other provider like shutterstock we don't allow renaming it
  if (image.source && !ALLOWED_SOURCES_FOR_RENAME.includes(image.source)) {
    return undefined;
  }

  return image.name;
};

export default getImageName;
