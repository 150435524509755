import { Point as FabricPoint } from 'fabric';

import { Point, Segment } from 'editor/src/util/2d/types';

import { Snap } from './snapsDataUtils';

export function getDistanceToSnap(snap: Snap, elementSnap: Snap, offset: Point): number {
  const point = elementSnap.segment[0];
  if (snap.isVertical) {
    return Math.abs(snap.segment[0].x - (point.x + offset.x));
  }
  const yInterceptElt = point.y + offset.y - snap.slope * (point.x + offset.x);
  return Math.abs(snap.yIntercept - yInterceptElt) / snap.distDenom;
}

export function getPointProjectionOnSegment(point: Point, segment: [Point, Point]) {
  const dX = segment[0].x - segment[1].x;
  const isVertical = dX === 0;
  if (isVertical) {
    // easy, we put the snap point as x and keep the current y
    return { x: segment[0].x, y: point.y };
  }

  const slope = (segment[0].y - segment[1].y) / dX;
  let intersectX: number;
  let intersectY: number;
  if (slope === 0) {
    // easy, we put the snap point as y and keep the current x
    intersectX = point.x;
    intersectY = segment[0].y;
  } else {
    // we compute the perpendicular line of the snap segment that passes through the current element coords
    const yIntercept = segment[0].y - slope * segment[0].x;
    const slopeInv = -1 / slope;
    const yInterceptInv = point.y - slopeInv * point.x;
    intersectX = (yInterceptInv - yIntercept) / (slope - slopeInv);
    intersectY = slope * intersectX + yIntercept;
  }

  return { x: intersectX, y: intersectY };
}

export function findSnapIntersection(snap: Snap, elementSnap: Snap, offset: Point, snapPoint: Point) {
  if (elementSnap.slopeVal === snap.slopeVal) {
    return findSingleParallelSnapIntersection(snap, elementSnap, offset, snapPoint);
  }

  // perpendicular segment, no intersection
  if (
    (elementSnap.isVertical && snap.slope === 0) ||
    (snap.isVertical && elementSnap.slope === 0) ||
    snap.slope * elementSnap.slope === -1
  ) {
    return null;
  }

  let intersectX: number;
  let intersectY: number;

  const point = { x: snapPoint.x + offset.x, y: snapPoint.y + offset.y };
  if (elementSnap.isVertical && snap.slope !== 0) {
    // elt segment is vertical and not perpendicular to snap segment
    intersectY = point.y;
    intersectX = (intersectY - snap.yIntercept) / snap.slope;
  } else if (snap.isVertical && elementSnap.slope !== 0) {
    // snap segment is vertical and not perpendicular to elt segment
    const slopeInv = -1 / elementSnap.slope;
    const yInterceptInv = point.y - slopeInv * point.x;
    intersectX = snap.segment[0].x;
    intersectY = slopeInv * intersectX + yInterceptInv;
  } else if (elementSnap.slope === 0) {
    // elt segment is horizontal
    intersectX = point.x;
    intersectY = snap.slope * intersectX + snap.yIntercept;
  } else if (snap.slope === 0) {
    // snap segment is horizontal
    intersectY = snap.yIntercept;
    const slopeInv = -1 / elementSnap.slope;
    const yInterceptInv = point.y - slopeInv * point.x;
    intersectX = (intersectY - yInterceptInv) / slopeInv;
  } else {
    // every other cases
    const slopeInv = -1 / elementSnap.slope;
    const yInterceptInv = point.y - slopeInv * point.x;
    intersectX = (yInterceptInv - snap.yIntercept) / (snap.slope - slopeInv);
    intersectY = snap.slope * intersectX + snap.yIntercept;
  }

  return {
    point: { x: intersectX, y: intersectY },
    offsetToTL: {
      x: elementSnap.tlElement.x - snapPoint.x,
      y: elementSnap.tlElement.y - snapPoint.y,
    },
  };
}

export function findSingleParallelSnapIntersection(
  snap: Snap,
  elementSnap: Snap,
  offset: Point,
  snapPoint = elementSnap.segment[0],
) {
  let intersectX: number;
  let intersectY: number;
  let matchSide: 'x' | 'y' | undefined;

  const point = { x: snapPoint.x + offset.x, y: snapPoint.y + offset.y };
  if (elementSnap.isVertical) {
    intersectX = snap.segment[0].x;
    intersectY = point.y;
    matchSide = 'y';
  } else if (elementSnap.slope === 0) {
    intersectY = snap.segment[0].y;
    intersectX = point.x;
    matchSide = 'x';
  } else {
    const slopeInv = -1 / elementSnap.slope;
    const yInterceptInv = point.y - slopeInv * point.x;
    intersectX = (yInterceptInv - snap.yIntercept) / (snap.slope - slopeInv);
    intersectY = snap.slope * intersectX + snap.yIntercept;
  }

  return {
    point: { x: intersectX, y: intersectY },
    offsetToTL: {
      x: elementSnap.tlElement.x - snapPoint.x,
      y: elementSnap.tlElement.y - snapPoint.y,
    },
    matchSide,
  };
}

export function findSnapsIntersection(snap1: Snap, snap2: Snap) {
  let intersectX: number;
  let intersectY: number;

  if (snap1.isVertical) {
    // if snap1 is vertical, you compute intersection with snap2
    intersectX = snap1.segment[0].x;
    intersectY = snap2.slope * intersectX + snap2.yIntercept;
  } else if (snap2.isVertical) {
    // if snap2 is vertical, you compute intersection with snap1
    intersectX = snap2.segment[0].x;
    intersectY = snap1.slope * intersectX + snap1.yIntercept;
  } else {
    // compute intersection of both lines
    intersectX = (snap1.yIntercept - snap2.yIntercept) / (snap2.slope - snap1.slope);
    intersectY = snap1.slope * intersectX + snap1.yIntercept;
  }

  return { x: intersectX, y: intersectY };
}

export function findDoubleSnapIntersection(snap1: Snap, snap2: Snap, elementSnap1: Snap, elementSnap2: Snap) {
  const snapPoint = findSnapsIntersection(snap1, snap2);
  const eltSnapPoint = findSnapsIntersection(elementSnap1, elementSnap2);

  return {
    point: snapPoint,
    offsetToTL: {
      x: elementSnap1.tlElement.x - eltSnapPoint.x,
      y: elementSnap1.tlElement.y - eltSnapPoint.y,
    },
    offset: {
      x: snapPoint.x - eltSnapPoint.x,
      y: snapPoint.y - eltSnapPoint.y,
    },
  };
}

export function getSquaredDistance(p1: Point, p2: Point) {
  return (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2;
}

export function get2FurthestPoints(segment1: Segment, segment2: Segment): [Point, Point] {
  const first =
    getSquaredDistance(segment1[0], segment2[1]) > getSquaredDistance(segment2[0], segment2[1])
      ? segment1[0]
      : segment2[0];

  const second =
    getSquaredDistance(segment1[1], segment1[0]) > getSquaredDistance(segment2[1], segment1[0])
      ? segment1[1]
      : segment2[1];

  return [first, second];
}

export function addOffsetToSegment(delta: Point, segment: Segment): Segment {
  return [
    { x: segment[0].x + delta.x, y: segment[0].y + delta.y },
    { x: segment[1].x + delta.x, y: segment[1].y + delta.y },
  ];
}

/**
 * This calculation is based on formula to determine on which side of a line is the point located.
 * 𝑑=(𝑥−𝑥1)(𝑦2−𝑦1)−(𝑦−𝑦1)(𝑥2−𝑥1)
 * @returns a negative number if the point is on one side, positive if on the other side and 0 if on the line.
 */
export function checkSide(seg1: FabricPoint, seg2: FabricPoint, point: FabricPoint): number {
  return (point.x - seg1.x) * (seg2.y - seg1.y) - (point.y - seg1.y) * (seg2.x - seg1.x);
}

/**
 * finds closest multiple to a number.
 * findMultiple(5.1, 0.5) => 5
 * findMultiple(5.3, 0.5) => 5.5
 */
export function findMultiple(value: number, step: number) {
  return Math.round(value / step) * step;
}
