import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import { useDispatch } from 'editor/src/store/hooks';

import { PopupName } from 'editor/src/component/Popup/popups';

import styles from './index.module.scss';

function useEditorTourWelcomeModal(
  isMobile: boolean,
  showWelcomeModal: boolean,
  onConfirm: () => void,
  onCancel: () => void,
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onModalCancel = () => {
    onCancel();
  };

  const onModalConfirm = () => {
    onConfirm();
  };

  useEffect(() => {
    if (!isMobile && showWelcomeModal) {
      dispatch(
        openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
          popupTitle: t('tour-welcome-modal-title'),
          textLines: [t('tour-welcome-modal-description')],
          className: `${styles.welcomePopup} cy-editor-tour-welcome-popup`,
          onCancel: onModalCancel,
          options: [
            {
              title: t('Skip for now'),
              onConfirm: onModalCancel,
            },
            {
              title: t('Take the tour'),
              onConfirm: onModalConfirm,
            },
          ],
          hideTitleIcon: true,
        }),
      );
    }
  }, [isMobile, showWelcomeModal]);

  return { showWelcomeModal };
}

export default useEditorTourWelcomeModal;
