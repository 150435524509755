import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';

import GroupCarousel from 'editor/src/component/Carousel/GroupCarousel';
import LinkButton from 'editor/src/component/LinkButton';

import styles from './index.module.scss';

interface Props {
  title?: string;
  children: React.ReactNode;
  showAll?: (group: MouseEvent) => void;
  className?: string;
}

function CarouselContainer({ title, children, showAll, className }: Props) {
  const { t } = useTranslation();

  return (
    <div className={cn('mb-2', 'cy-plugin-group', className, styles.carousel)}>
      <div className={cn(styles.title, 'row-flex row-flex--between')}>
        <div>{title}</div>
        {showAll && (
          <LinkButton className={cn('cy-plugin-show-all', styles.showAll)} onClick={showAll}>
            {t('show-all')}
          </LinkButton>
        )}
      </div>
      <GroupCarousel>{children}</GroupCarousel>
    </div>
  );
}

export default CarouselContainer;
