import cn from 'classnames';
import React, { useCallback } from 'react';

import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import RadioButton from 'editor/src/component/RadioButton';

import styles from './index.module.scss';

function RadioElement({ option, selected, disabled, toggle, control }: ControlOptionProps) {
  const toggleCb = useCallback(() => toggle(control, option), [control, option]);
  return (
    <RadioButton
      type="button"
      className={cn(`cy-checkbox-${option.value}`, {
        [styles.active]: selected,
        [styles.enabled]: !disabled,
        'cy-active': selected,
      })}
      label={option.title}
      previewImg={option.optionDetails?.previewImage}
      description={option.optionDetails?.subtitle}
      on={selected}
      disabled={disabled}
      onChange={toggleCb}
    />
  );
}

export default React.memo(RadioElement);
