import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import TabBar from 'editor/src/component/TabBar';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import HelpCenterArticles from './HelpCenterArticles';
import HelpCenterTutorials from './HelpCenterTutorials';
import ProductTourButton from './ProductTourButton';

import styles from './index.module.scss';

enum HelpCenterTabType {
  articles = 'help-topics',
  tutorials = 'tutorials',
}

function HelpCenterTabContent() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const {
    articles,
    tutorials,
    articlesSearchString = '',
  } = useSelector((state) => state.hostSettings.enableHelpCenter) || {};

  const helpCenterTabs: { key: HelpCenterTabType; label: string }[] = [
    {
      key: HelpCenterTabType.articles,
      label: t('Help topics'),
    },
    {
      key: HelpCenterTabType.tutorials,
      label: t('Tutorials'),
    },
  ];
  const [activeTab, setActiveTab] = useState(helpCenterTabs[0].key);

  return (
    <div className={styles.tabContainer}>
      <TabContentHeader
        title={t('Help')}
        className={cn(
          {
            [styles.tabHeader]: isMobile,
          },
          styles.headerBlock,
        )}
      />
      <ProductTourButton />
      {articles && tutorials && (
        <TabBar<HelpCenterTabType> tabs={helpCenterTabs} onSelect={setActiveTab} activeTab={activeTab} />
      )}
      <ContentBlock noBorder scroll>
        {activeTab === helpCenterTabs[0].key && articles ? (
          <HelpCenterArticles articles={articles} searchString={articlesSearchString} />
        ) : (
          tutorials && <HelpCenterTutorials tutorials={tutorials} />
        )}
      </ContentBlock>
    </div>
  );
}

export default React.memo(HelpCenterTabContent);
