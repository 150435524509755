import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Blank, Coords } from 'editor/src/store/design/types';

import CustomFabricText from 'editor/src/fabric/CustomFabricText';
import pt2mm from 'editor/src/util/pt2mm';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricTextComponent from '../../../fabricComponents/FabricTextComponent';
import { CanvasRotation } from '../../../types';
import {
  calculateFontSize,
  calculateLeftPosition,
  calculateTopPosition,
  calculateWidth,
} from '../../../utils/blankPageTextCalculations';
import zIndex from '../../zIndex';

import BlankElement from './BlankElement';

interface Props {
  blanks: Blank[] | undefined;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  showBlankAreaShadowOnly: boolean;
}

function BlankElements({ blanks, pageCoords, canvasRotation, showBlankAreaShadowOnly }: Props) {
  const { t } = useTranslation();
  const { mm2px } = useFabricUtils();
  const fabricCanvas = useFabricCanvas();
  const textRef = useRef<CustomFabricText>(null);

  const blankArea = useMemo(() => {
    return blanks?.find((blank) => blank.type === 'area');
  }, [blanks]);

  useEffect(() => {
    if (textRef.current && blankArea) {
      const blankAreaSize = Math.min(mm2px(blankArea.width), mm2px(blankArea.height));
      textRef.current.set(
        'top',
        calculateTopPosition(pageCoords, blankArea.height, textRef.current.getScaledHeight(), mm2px),
      );
      textRef.current.set('width', calculateWidth(blankArea.width, mm2px));
      textRef.current.set('fontSize', calculateFontSize(blankAreaSize));
      fabricCanvas.requestRenderAll();
    }
  });

  if (!blankArea) {
    return null;
  }

  return (
    <>
      {(blanks ?? []).map((blank: Blank, index: number) => (
        <BlankElement
          key={index}
          blank={blank}
          pageCoords={pageCoords}
          canvasRotation={canvasRotation}
          showBlankAreaShadowOnly={showBlankAreaShadowOnly}
        />
      ))}

      {!showBlankAreaShadowOnly && (
        <FabricTextComponent
          text={t('editor-blank-page-description')}
          left={calculateLeftPosition(pageCoords, blankArea.x, blankArea.width, mm2px)}
          top={pageCoords.top + mm2px(blankArea.height) / 2} // top size is overriden in useEffect, but setting it to a default value here to avoid flickering
          fontSize={mm2px(pt2mm(24))} // font size is overriden in useEffect, but setting it to a default value here to avoid flickering
          textAlign="center"
          fill="rgba(51, 51, 51, 0.5)"
          fontFamily="Gelato Sans, sans-serif"
          zIndex={zIndex.BLANK}
          evented={false}
          ref={textRef}
        />
      )}
    </>
  );
}

export default React.memo(BlankElements);
