import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import getDesignData from 'editor/src/store/design/selector/getDesignData';
import closeIntroTourOperation from 'editor/src/store/editorModules/tour/operation/closeIntroTourOperation';
import setNextIntroStepOperation from 'editor/src/store/editorModules/tour/operation/setNextIntroStepOperation';
import setPreviousIntroStepOperation from 'editor/src/store/editorModules/tour/operation/setPreviousIntroStepOperation';
import getActiveIntroTourStep from 'editor/src/store/editorModules/tour/selector/getActiveIntroTourStep';
import getIntroTourSteps from 'editor/src/store/editorModules/tour/selector/getIntroTourSteps';
import { isIntroTourAllowed } from 'editor/src/store/editorModules/tour/utils/introTourConfig';
import { getToursStepsKey, isTourElementHidden } from 'editor/src/store/editorModules/tour/utils/tourStorage';
import { useSelector, useDispatch } from 'editor/src/store/hooks';

import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import EditorTourHighlightedAreas from './EditorTourHighlightedAreas';
import EditorTourInfoDialog from './EditorTourInfoDialog';
import useEditorTourWelcomeModal from './useEditorTourWelcomeModal';

import styles from './index.module.scss';

function EditorTour() {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const introTourSteps = useSelector(getIntroTourSteps);
  const activeIntroTourStep = useSelector(getActiveIntroTourStep);
  const designData = useSelector(getDesignData);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const hidWelcomeModal = useCallback(() => setShowWelcomeModal(false), []);
  const setNextStep = useCallback(() => dispatch(setNextIntroStepOperation()), []);
  const setPreviousStep = useCallback(() => dispatch(setPreviousIntroStepOperation()), []);
  const onWelcomeConfirm = useCallback(() => {
    setNextStep();
    hidWelcomeModal();
  }, [setNextStep, hidWelcomeModal]);

  const closeIntroTour = useCallback(() => {
    hidWelcomeModal();
    dispatch(closeIntroTourOperation());
  }, [hidWelcomeModal]);

  useEditorTourWelcomeModal(isMobile, showWelcomeModal, onWelcomeConfirm, closeIntroTour);

  const isAllowedToShow = useMemo(() => {
    return isIntroTourAllowed(designData);
  }, [designData]);

  useEffect(() => {
    const isHiddenByUser = isTourElementHidden('introTour', getToursStepsKey(introTourSteps));
    if (!isHiddenByUser && introTourSteps.length && !activeIntroTourStep && !isHiddenByUser && isAllowedToShow) {
      setShowWelcomeModal(true);
    }
  }, [introTourSteps.length, !!activeIntroTourStep, introTourSteps, isAllowedToShow]);

  const activeIntroTourId = activeIntroTourStep && introTourSteps[activeIntroTourStep - 1]?.id;

  if (!isAllowedToShow) {
    return null;
  }

  // hide on mobile, photobooks and if user closed
  if (
    isMobile ||
    showWelcomeModal ||
    !activeIntroTourId ||
    isTourElementHidden('introTour', getToursStepsKey(introTourSteps))
  ) {
    return null;
  }

  return (
    <>
      <div className={cn(styles.dummyTopElement, 'intro-tour-dummy-top-element')} />
      <div className={styles.overlay}>
        <EditorTourHighlightedAreas activeIntroTourId={activeIntroTourId} />
        <EditorTourInfoDialog
          activeIntroTourId={activeIntroTourId}
          totalStepsCount={introTourSteps.length}
          stepIndex={activeIntroTourStep}
          onCloseTour={closeIntroTour}
          onNextStep={setNextStep}
          onPreviousStep={setPreviousStep}
        />
      </div>
    </>
  );
}

export default React.memo(EditorTour);
