import { CanvasEvents, util as fabricNativeUtils, Point as FabricPoint, FabricObject } from 'fabric';
import { TOriginX, TOriginY } from 'fabric/src/typedefs';
import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { Coords, ElementAddress, MediaMockupPlaceholder, SpreadGroundImage } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';
import CustomFabricObject from 'editor/src/fabric/CustomFabricObject';
import CustomFabricRect from 'editor/src/fabric/CustomFabricRect';
import CustomFabricText from 'editor/src/fabric/CustomFabricText';
import useFabricUtils from 'editor/src/util/useFabricUtils';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import FabricGroupAsImageComponent, {
  FabricGroupImage,
} from 'editor/src/component/EditorArea/fabricComponents/FabricGroupAsImageComponent';
import FabricImageComponent from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';
import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import FabricSVGComponent from 'editor/src/component/EditorArea/fabricComponents/FabricSVGComponent';
import FabricTextComponent from 'editor/src/component/EditorArea/fabricComponents/FabricTextComponent';
import { ELEMENT_FRAME_COLOR } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/config';
import fabricElementToObjectRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/fabricElementToObjectRect';
import getElementRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getElementRect';
import ImageShadow, {
  Ref as ShadowRef,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow';
import getImageShadow from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow/getImageShadow';
import LeaningShadowSvg, {
  LeaningShadowRef,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow/LeaningShadow';
import useFilters from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/useFilters';
import usePerspectiveTranform from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/usePerspectiveTranform';
import useHoverBox from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useHoverBox';
import useIsInteractable from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useIsInteractable';
import useStoreSelection from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useStoreSelection';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

const GELATO_ICON =
  // eslint-disable-next-line max-len
  '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.0007 18.1095L15.1153 13.2241L20.0007 8.33912L24.8861 13.2241L20.0007 18.1095ZM34.655 13.2241L38.9882 8.89125C40.3373 7.54078 40.3373 5.35447 38.9882 4.00584C37.6396 2.65675 35.4533 2.65675 34.1033 4.00584L29.77 8.33912L22.4425 1.01147C21.0938 -0.337156 18.9075 -0.337156 17.5585 1.01147L7.7882 10.7823C6.43912 12.1309 6.43912 14.3172 7.7882 15.6663L20.0007 27.8789L29.77 18.1095L31.6611 20.0005L20.0007 31.661L5.89718 17.5574C4.5481 16.2087 2.36181 16.2087 1.01181 17.5574C-0.337269 18.9064 -0.337269 21.0937 1.01181 22.4428L17.5585 38.9882C18.9075 40.3373 21.0938 40.3373 22.4425 38.9882L38.9882 22.4428C40.3373 21.0937 40.3373 18.9064 38.9882 17.5574L34.655 13.2241Z" fill="#212121" /></svg>';
const FORMAT_SIZE_CONTROL_KEY = 'formatsize';

interface Props {
  elementData: MediaMockupPlaceholder;
  elementAddress: ElementAddress;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  selected: boolean;
  isMobile: boolean;
  ignorePersonalizationLock: boolean;
}

export const CONTROLS_VISIBILITY = {
  mt: false,
  mb: false,
  ml: false,
  mr: false,
  bl: true,
  br: true,
  tl: true,
  tr: true,
  mtr: false,
};

export const CONTROLS_VISIBILITY_FIT = {
  mt: true,
  mb: true,
  ml: true,
  mr: true,
  bl: true,
  br: true,
  tl: true,
  tr: true,
  mtr: false,
};
export interface PositionInfo {
  left: number;
  top: number;
  width: number;
  height: number;
  innerLeft: number;
  innerTop: number;
  innerWidth: number;
  innerHeight: number;
}

function LocalPlaceholder({
  elementData,
  elementAddress,
  pageCoords,
  canvasRotation,
  selected,
  isMobile,
  ignorePersonalizationLock,
}: Props) {
  const { mm2px, px2mm } = useFabricUtils();
  const shadowRef = useRef<ShadowRef>(null);
  const leaningShadowRef = useRef<LeaningShadowRef>(null);
  const areaRef = useRef<CustomFabricRect>(null);
  const placeholderImageRef = useRef<FabricGroupImage>(null);
  const bgRef = useRef<CustomFabricRect>(null);
  const foregroundRef = useRef<CustomFabricImage>(null);
  const iconRef = useRef<CustomFabricObject>(null);
  const textRef = useRef<CustomFabricText>(null);
  const subTextRef = useRef<CustomFabricText>(null);
  const dispatch = useDispatch();
  const { liveElement: element } = useMediaElementLiveUpdates(elementData);
  const { t } = useTranslation();

  const sceneImages = useSelector(
    (state) => state.mockup.productPlaceholder.sceneImages[elementData.variant.productUid],
  );
  const { activeProductUid, variations, controls } = useSelector((state) => state.mockup.productPlaceholder);

  const selectedSizeTitle = useMemo(() => {
    const selectedVariation = variations.find((variation) => variation.productUid === activeProductUid);
    const selectedSize = selectedVariation && selectedVariation[FORMAT_SIZE_CONTROL_KEY];
    return selectedSize
      ? controls.find(({ key }) => key === FORMAT_SIZE_CONTROL_KEY)?.options.find(({ value }) => value === selectedSize)
          ?.title
      : undefined;
  }, [activeProductUid, variations, controls]);

  const foreground = sceneImages?.foregrounds[0];

  const elementRect = useMemo(
    () => getElementRect(element, pageCoords, canvasRotation, mm2px),
    [element.x, element.y, element.r, element.width, element.height, pageCoords, canvasRotation, mm2px],
  );

  useStoreSelection(element.area_fit ? areaRef : placeholderImageRef, element.uuid, element.type, selected);
  const isInteractable = useIsInteractable(element, ignorePersonalizationLock);
  const hoverBox = useHoverBox(elementRect, isMobile, selected || !!element.hidden, canvasRotation);
  const perspectiveTransform = usePerspectiveTranform(
    element,
    elementAddress,
    false,
    selected,
    elementRect,
    placeholderImageRef,
    false,
  );

  function computeProductPosition(
    fit: true | undefined,
    left: number,
    top: number,
    width: number,
    height: number,
    foreground: SpreadGroundImage | undefined,
  ) {
    const productPlacement = {
      width,
      height,
      innerWidth: width,
      innerHeight: height,
      innerLeft: 0,
      innerTop: 0,
      left,
      top,
      angle: 0,
    };

    if (fit) {
      if (element.variant.width / width > element.variant.height / height) {
        productPlacement.width = width;
        productPlacement.height = (element.variant.height * width) / element.variant.width;
      } else {
        productPlacement.height = height;
        productPlacement.width = (element.variant.width * height) / element.variant.height;
      }

      switch (element.anchoring_x) {
        case 'left':
          productPlacement.left = left;
          break;
        case 'right':
          productPlacement.left = left + width - productPlacement.width;
          break;
        default:
          productPlacement.left = left + (width - productPlacement.width) / 2;
          break;
      }

      switch (element.anchoring_y) {
        case 'top':
          productPlacement.top = top;
          break;
        case 'bottom':
          productPlacement.top = top + height - productPlacement.height;
          break;
        default:
          productPlacement.top = top + (height - productPlacement.height) / 2;
          break;
      }
    }
    productPlacement.innerWidth = productPlacement.width;
    productPlacement.innerHeight = productPlacement.height;

    if (foreground) {
      const scaleX = productPlacement.width / foreground.imgWidth;
      const scaleY = productPlacement.height / foreground.imgHeight;
      productPlacement.innerWidth = foreground.width * scaleX;
      productPlacement.innerHeight = foreground.height * scaleY;

      const offsetX = foreground.left * (productPlacement.width / foreground.width);
      const offsetY = foreground.top * (productPlacement.height / foreground.height);
      productPlacement.innerLeft += offsetX;
      productPlacement.innerTop += offsetY;
    }

    const renderRatio = Math.max(productPlacement.width, productPlacement.height) / 216;
    return { productPlacement, renderRatio };
  }

  const { productPlacement, renderRatio } = computeProductPosition(
    element.area_fit,
    elementRect.left,
    elementRect.top,
    elementRect.width,
    elementRect.height,
    foreground,
  );

  function updateTextAndIconPosition(productPlacement: PositionInfo, renderRatio: number) {
    if (!iconRef.current || !textRef.current) {
      return;
    }

    const textHeight = textRef.current.getScaledHeight();
    const iconHeight = iconRef.current.getScaledHeight();

    const space = 10 * renderRatio;
    const offset = -(textHeight + space + iconHeight) / 2;

    textRef.current.top = productPlacement.innerTop + productPlacement.innerHeight / 2 + offset;
    iconRef.current.top = productPlacement.innerTop + productPlacement.innerHeight / 2 + offset + space + textHeight;
  }

  useLayoutEffect(() => updateTextAndIconPosition(productPlacement, renderRatio));

  function getIconScale(element: FabricObject, productPlacement: PositionInfo) {
    const { viewBoxWidth = 1, viewBoxHeight = 1 } = element as any;
    const scaleX = productPlacement.width / 8 / viewBoxWidth;
    const scaleY = productPlacement.height / 8 / viewBoxHeight;
    const scale = Math.min(scaleX, scaleY);

    return { scaleX: scale, scaleY: scale };
  }

  const getGelatoIconOnUpdate = useCallback(
    (element: FabricObject) => getIconScale(element, productPlacement),
    [productPlacement.width, productPlacement.height],
  );

  const onObjectModified = useCallback(() => {
    const controlRef = areaRef.current || placeholderImageRef.current;
    if (controlRef) {
      const frameRect = fabricElementToObjectRect(controlRef, elementRect);
      const unrotatedFrameCoords = fabricNativeUtils.rotatePoint(
        new FabricPoint(frameRect.left, frameRect.top),
        canvasRotation.canvasCenter,
        -canvasRotation.angleRad,
      );

      dispatch(
        updateMediaElementOperation(
          elementAddress,
          {
            x: px2mm(unrotatedFrameCoords.x - pageCoords.left),
            y: px2mm(unrotatedFrameCoords.y - pageCoords.top),
            width: px2mm(frameRect.width),
            height: px2mm(frameRect.height),
            pw: px2mm(frameRect.width),
            ph: px2mm(frameRect.height),
            r: frameRect.angle - canvasRotation.angleDeg,
          },
          MediaUpdateActionName.MOCKUP_UPDATED,
        ),
      );
    }
  }, [px2mm, pageCoords, elementAddress, elementRect]);

  const getBGProps = (position: PositionInfo) => ({
    left: position.innerLeft,
    top: position.innerTop,
    width: position.innerWidth,
    height: position.innerHeight,
    scaleX: 1,
    scaleY: 1,
  });

  const getMainTextProps = (position: PositionInfo, renderRatio: number) => {
    const fontSize = 20 * renderRatio;
    const margin = 30 * renderRatio;

    return {
      left: position.innerLeft + position.innerWidth / 2,
      top: position.innerTop + position.innerHeight / 2,
      originX: 'center' as TOriginX,
      originY: 'center' as TOriginY,
      width: position.innerWidth - margin,
      text: t('Your Design Here'),
      fontSize,
    };
  };

  const getIconProps = (position: PositionInfo) => ({
    originX: 'center' as TOriginX,
    originY: 'top' as TOriginY,
    left: position.innerLeft + position.innerWidth / 2,
    top: position.innerTop + position.innerHeight / 2,
  });

  const getSubTextProps = (position: PositionInfo, renderRatio: number) => {
    const fontSize = 20 * renderRatio;
    const subFontSize = fontSize * 0.5;
    const margin = 30 * renderRatio;
    const opacity = position.innerWidth > 100 ? 1 : 0;

    return {
      originX: 'center' as TOriginX,
      originY: 'bottom' as TOriginY,
      left: position.innerLeft + position.innerWidth / 2 + margin / 4,
      top: position.innerTop + position.innerHeight - subFontSize,
      width: position.innerWidth - margin / 2,
      fontSize: subFontSize,
      opacity,
    };
  };

  const getForegroundProps = (foreground: SpreadGroundImage, position: PositionInfo) => {
    const scaleX = position.innerWidth / foreground.width;
    const scaleY = position.innerHeight / foreground.height;
    return {
      scaleX,
      scaleY,
      left: position.innerLeft - foreground.left * scaleX,
      top: position.innerTop - foreground.top * scaleY,
    };
  };

  const onMouseMove = useCallback(
    (e: CanvasEvents['mouse:move']) => {
      const controlRef = areaRef.current || placeholderImageRef.current;
      if (!e.transform || !controlRef) {
        return;
      }

      const { productPlacement, renderRatio } = computeProductPosition(
        element.area_fit,
        controlRef.left ?? 0,
        controlRef.top ?? 0,
        controlRef.getScaledWidth(),
        controlRef.getScaledHeight(),
        foreground,
      );

      if (bgRef.current) {
        bgRef.current.set(getBGProps(productPlacement));
      }
      if (textRef.current) {
        textRef.current.set(getMainTextProps(productPlacement, renderRatio));
      }

      if (iconRef.current) {
        iconRef.current.set(getIconProps(productPlacement));
        iconRef.current.set(getIconScale(iconRef.current, productPlacement));
      }

      if (foregroundRef.current && foreground) {
        foregroundRef.current.set(getForegroundProps(foreground, productPlacement));
      }

      if (areaRef.current && placeholderImageRef.current) {
        placeholderImageRef.current.set({
          left: productPlacement.left,
          top: productPlacement.top,
        });
        placeholderImageRef.current.updateSize(productPlacement.width, productPlacement.height);
      }

      if (shadowRef.current) {
        shadowRef.current.udpate(productPlacement);
      }

      if (leaningShadowRef.current) {
        leaningShadowRef.current.update(productPlacement);
      }

      updateTextAndIconPosition(productPlacement, renderRatio);

      if (subTextRef.current) {
        subTextRef.current.set(getSubTextProps(productPlacement, renderRatio));
      }
    },
    [foreground, element.area_fit, element.variant, element.anchoring_x, element.anchoring_y],
  );

  const fabricZIndex = zIndex.MEDIA + elementAddress.elementIndex;
  const filters = useFilters(element);

  const controlProps = {
    onMouseMove,
    onMouseOver: hoverBox.onMouseOver,
    onMouseOut: hoverBox.onMouseOut,
    uuid: element.uuid,
    selectable: !isMobile,
    evented: isInteractable,
    scaleX: 1,
    scaleY: 1,
    lockScalingFlip: true,
    hoverCursor: selected ? 'move' : 'pointer',
    controlVisibility: element.area_fit ? CONTROLS_VISIBILITY_FIT : CONTROLS_VISIBILITY,
    borderColor: ELEMENT_FRAME_COLOR,
    cornerColor: ELEMENT_FRAME_COLOR,
    cornerStrokeColor: ELEMENT_FRAME_COLOR,
    onModified: onObjectModified,
    hasControls: !perspectiveTransform.isActive,
  };

  const shadow = useMemo(() => getImageShadow(element.shadow, mm2px), [element.shadow, mm2px]);
  const onPlaceholderReady = useCallback((source) => shadowRef.current?.sourceUpdate(source), []);

  return (
    <>
      {element.area_fit && (
        <FabricRectComponent
          left={elementRect.left}
          top={elementRect.top}
          width={elementRect.width}
          height={elementRect.height}
          angle={elementRect.angle}
          objectCaching={false}
          fill="rgba(170, 192, 236, 0.5)"
          zIndex={fabricZIndex}
          strokeWidth={0}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...controlProps}
          ref={areaRef}
        />
      )}
      {shadow && placeholderImageRef.current && (
        <ImageShadow
          ref={shadowRef}
          source={placeholderImageRef.current.getElement()}
          crossOrigin="anonymous"
          element={element}
          zIndex={fabricZIndex}
          frameRect={productPlacement}
          shadow={shadow}
          contentClipPath={undefined}
        />
      )}
      {element.shadow && element.perspective_transform && element.shadow.type === 'leaning-shadow' && (
        <LeaningShadowSvg
          ref={leaningShadowRef}
          shadow={element.shadow}
          perspectiveTransform={element.perspective_transform}
          zIndex={fabricZIndex}
          rect={productPlacement}
        />
      )}
      <FabricGroupAsImageComponent
        key={element.variant.productUid} // force deleting the element when the product is changed
        left={productPlacement.left}
        top={productPlacement.top}
        width={productPlacement.width}
        height={productPlacement.height}
        zIndex={fabricZIndex + 0.01}
        filters={filters}
        onReady={onPlaceholderReady}
        strokeWidth={0}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(element.area_fit ? { evented: false } : controlProps)}
        ref={placeholderImageRef}
        canvasMinSize={500}
      >
        <FabricRectComponent
          objectCaching={false}
          fill="white"
          evented={false}
          zIndex={fabricZIndex + 0.1}
          strokeWidth={0}
          ref={bgRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getBGProps(productPlacement)}
        />
        <FabricTextComponent
          textAlign="center"
          fontFamily="Roboto, Arial, sans-serif"
          fill="black"
          fontWeight={500}
          evented={false}
          zIndex={fabricZIndex + 0.2}
          ref={textRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getMainTextProps(productPlacement, renderRatio)}
        />
        <FabricSVGComponent
          svg={GELATO_ICON}
          evented={false}
          zIndex={fabricZIndex + 0.3}
          getFabricOptionsOnUpdate={getGelatoIconOnUpdate}
          ref={iconRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getIconProps(productPlacement)}
        />
        {selectedSizeTitle && (element.area_fit || productPlacement.width > 100) && (
          <FabricTextComponent
            text={selectedSizeTitle}
            evented={false}
            textAlign="center"
            fontFamily="Roboto, Arial, sans-serif"
            fill="#8A8A8A"
            zIndex={fabricZIndex + 0.4}
            ref={subTextRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getSubTextProps(productPlacement, renderRatio)}
          />
        )}
        {foreground && (
          <FabricImageComponent
            key={foreground.url}
            source={foreground.url}
            crossOrigin="anonymous"
            zIndex={fabricZIndex + 0.5}
            evented={false}
            ref={foregroundRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getForegroundProps(foreground, productPlacement)}
          />
        )}
      </FabricGroupAsImageComponent>
      {hoverBox.render()}
      {perspectiveTransform.render()}
    </>
  );
}

export default React.memo(LocalPlaceholder);
