import polygonClipping from 'polygon-clipping';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import { Point } from './2d/types';
import getPointPositionRotatedOnPoint from './getPointPositionRotatedOnPoint';

export function hasRectIntersection(
  objectRect: ObjectRect,
  spreadCoords: Point,
  spreadWidth: number,
  spreadHeight: number,
): boolean {
  const objLeft = objectRect.left;
  const objTop = objectRect.top;
  const objRight = objLeft + objectRect.width;
  const objBottom = objTop + objectRect.height;

  const spreadRight = spreadCoords.x + spreadWidth;
  const spreadBottom = spreadCoords.y + spreadHeight;

  // Fast AABB Check when not rotated
  if (objectRect.angle === 0) {
    return objRight > spreadCoords.x && objLeft < spreadRight && objBottom > spreadCoords.y && objTop < spreadBottom;
  }

  // Check if any corner is inside the spread (early exit)
  const corners: [number, number][] = [
    [objLeft, objTop], // Top-left
    [objRight, objTop], // Top-right
    [objRight, objBottom], // Bottom-right
    [objLeft, objBottom], // Bottom-left
  ].map(([x, y]) => getPointPositionRotatedOnPoint(x, y, objLeft, objTop, objectRect.angle));

  for (let i = 0; i < corners.length; i += 1) {
    const x = corners[i][0];
    const y = corners[i][1];

    if (x >= spreadCoords.x && x <= spreadRight && y >= spreadCoords.y && y <= spreadBottom) {
      return true; // If any rotated corner is inside, return early
    }
  }

  // If not fully outside, perform polygon intersection
  return hasIntersections(corners, spreadCoords, spreadWidth, spreadHeight);
}

function hasIntersections(
  rectPolygon: [number, number][],
  spreadCoords: Point,
  spreadWidth: number,
  spreadHeight: number,
): boolean {
  const spreadPolygon: [number, number][] = [
    [spreadCoords.x, spreadCoords.y], // top-left
    [spreadCoords.x + spreadWidth, spreadCoords.y], // top-right
    [spreadCoords.x + spreadWidth, spreadCoords.y + spreadHeight], // bottom-right
    [spreadCoords.x, spreadCoords.y + spreadHeight], // bottom-left
  ];

  return polygonClipping.intersection([rectPolygon], [spreadPolygon]).length > 0;
}

export default hasRectIntersection;
