import { Canvas as FabricCanvas, FabricObject, util as fabricUtils, Point as FabricPoint, iMatrix } from 'fabric';
import { TOptionalModifierKey } from 'fabric/src/EventTypeDefs';
import { useState, useEffect } from 'react';

import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import isAnyMediaElementInCropMode from 'editor/src/store/editor/selector/isAnyMediaElementInCropMode';
import { setZoomModeAction as setZoomModeOperation } from 'editor/src/store/editor/slice';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import orderObjects from './orderObjects';
import useRenderCanvasOnPageVisible from './useRenderCanvasOnPageVisible';

function useCreateFabricCanvas(canvasId: string) {
  const [fabricCanvas, setFabricCanvas] = useState<FabricCanvas>();
  const dispatch = useDispatch();
  const store = useStore();

  const isAnyImageInCropMode = useSelector(isAnyMediaElementInCropMode);

  useEffect(() => {
    if (fabricCanvas) {
      fabricCanvas.uniScaleKey = (isAnyImageInCropMode ? 'shiftKey' : null) as TOptionalModifierKey;
      fabricCanvas.requestRenderAll();
    }
  }, [isAnyImageInCropMode]);

  useEffect(() => {
    const canvas = new FabricCanvas(canvasId, {
      selection: false,
      renderOnAddRemove: false,
      preserveObjectStacking: true, // to avoid having the active elements always on top
      uniScaleKey: null,
      centeredKey: null,
    });

    (window as any).fabricCanvas = canvas;
    (window as any).getFabricObjectRect = (obj: FabricObject) => {
      if (!obj || !canvas) {
        return null;
      }

      // Get bounding rect (considers transformations like scaling/rotation)
      const boundingRect = obj.getBoundingRect();

      // Get canvas viewport transform (accounts for zoom & pan)
      const canvasTransform = canvas.viewportTransform || iMatrix; // Default to identity matrix if missing

      // Convert bounding box size to absolute scale
      const topLeft = fabricUtils.transformPoint(new FabricPoint(boundingRect.left, boundingRect.top), canvasTransform);
      const bottomRight = fabricUtils.transformPoint(
        new FabricPoint(boundingRect.left + boundingRect.width, boundingRect.top + boundingRect.height),
        canvasTransform,
      );

      return {
        left: topLeft.x,
        top: topLeft.y,
        width: bottomRight.x - topLeft.x,
        height: bottomRight.y - topLeft.y,
      };
    };

    canvas.on('selection:cleared', () => {
      const selectedMediaElements = getSelectedElementUuids(store.getState());
      if (selectedMediaElements.length) {
        dispatch(removeAllSelectedMediaElementsOperation());
        dispatch(setZoomModeOperation(false)); // what is that for ?
      }
    });

    canvas.on('selection:created', (event) => {
      const element = (event as any).selected?.[0];
      if (element && element.uuid) {
        dispatch(addSelectedMediaElementOperation(element.uuid));
      }
    });

    canvas.on('selection:updated', (event) => {
      const element = (event as any).selected?.[0];
      if (element && element.uuid) {
        dispatch(addSelectedMediaElementOperation(element.uuid));
      }
    });

    canvas.on('before:render', () => {
      orderObjects(canvas);
    });

    setFabricCanvas(canvas);
  }, []);

  useRenderCanvasOnPageVisible(fabricCanvas);

  return fabricCanvas;
}

export default useCreateFabricCanvas;
