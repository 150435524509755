import { Canvas as FabricCanvas, CanvasEvents } from 'fabric';
import { useRef, useCallback } from 'react';

/**
 * Hook that returns event handlers for unselecting image on click on mobile.
 */
function useUnselectOnClick(
  fabricCanvas: FabricCanvas,
  isMobile: boolean,
  isInCropMode: boolean,
  toggleCropMode: () => void,
) {
  const clickDistanceTolerance = 2;
  const clickMsTimeTolerance = 700;
  const mouseData = useRef({ x: 0, y: 0, time: 0 });

  const onMouseDown = useCallback((e: CanvasEvents['mouse:down']) => {
    if (e.pointer) {
      mouseData.current = { x: e.pointer.x, y: e.pointer.y, time: Date.now() };
    }
  }, []);

  const onFrameMouseUp = useCallback(
    (e) => {
      if (e.pointer && isMobile) {
        const distance = Math.sqrt((e.pointer.x - mouseData.current.x) ** 2 + (e.pointer.y - mouseData.current.y) ** 2);
        const time = Date.now() - mouseData.current.time;

        // check if event was actually a click and if image was selected before the click - deselect image
        if (distance < clickDistanceTolerance && time < clickMsTimeTolerance) {
          fabricCanvas.discardActiveObject();
        }
      }
    },
    [isMobile],
  );

  const onGhostMouseUp = useCallback(
    (e) => {
      const distance = Math.sqrt((e.pointer.x - mouseData.current.x) ** 2 + (e.pointer.y - mouseData.current.y) ** 2);
      const time = Date.now() - mouseData.current.time;
      // check if ghost was clicked in crop mode and if so - toggle crop mode, otherwise deselect image
      if (distance < clickDistanceTolerance && time < clickMsTimeTolerance) {
        if (isInCropMode) {
          toggleCropMode();
        } else {
          fabricCanvas.discardActiveObject();
        }
      }
    },
    [isMobile],
  );

  return { onMouseDown, onFrameMouseUp, onGhostMouseUp };
}

export default useUnselectOnClick;
