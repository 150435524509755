import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'editor/src/store/hooks';
import deleteDesignOptionOperation from 'editor/src/store/variants/operation/deleteDesignOptionOperation';
import getVariationGroupsFromDesignOptions from 'editor/src/store/variants/selector/getVariationGroupsFromDesignOptions';
import { updateDesignOptionAction } from 'editor/src/store/variants/slice';
import { DesignOption } from 'editor/src/store/variants/types';

import useSpreadPreview from 'editor/src/util/design/useSpreadPreview';

import IconBin from 'editor/src/component/Icon/IconBin';
import { useCanvasRendering } from 'editor/src/component/SpreadPreview';

import styles from './index.module.scss';

interface Props {
  designOption: DesignOption;
  showBinIcon: boolean;
  index: number;
}
function OptionEntry({ designOption, showBinIcon, index }: Props) {
  const dispatch = useDispatch();
  const spreadIndex = useSelector((state) => state.editor.currentSpreadIndex);
  const groups = useSelector(getVariationGroupsFromDesignOptions);
  const currentOptionGroup = groups?.find(
    (group) => group.name === designOption.title && group.key === designOption.value,
  );
  const designOptions = useSelector((state) => state.variants.designOptionsControl);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const group = currentOptionGroup?.value[0];
  const { designData } = group?.variationsInfo[0] ?? {};
  const spread = designData?.spreads[spreadIndex];
  const spreadGroup = designData?.spread_groups?.find((group) => group.spreadIndexes.includes(spreadIndex));
  const { requestRender } = useCanvasRendering();

  const { preview } = useSpreadPreview(
    requestRender,
    spread,
    group?.key || '',
    spreadIndex,
    { dimension: 'height', value: 40 },
    { showBlanks: true, showEmptyImages: true },
    undefined,
    spreadGroup,
  );

  const deleteDesignOption = (designOption: DesignOption) => {
    dispatch(deleteDesignOptionOperation(designOption));
  };

  const updateDesignOption = (index: number, title: string, value: string) => {
    if (designOptions?.find((designOption) => designOption.options.find((option) => option.title === title))) {
      setError(`${title} ${t('is not a unique name, option name should be unique')}`);
    } else {
      setError('');
    }
    dispatch(
      updateDesignOptionAction({
        controlKey: 'design-option',
        index,
        option: { title, value },
      }),
    );
  };

  return (
    <>
      <div className={cn(styles.designOption, 'mt-2')}>
        <div className="row-flex row-flex--middle">
          <img className={styles.designOptionImage} alt={designOption.title} src={preview?.dataURL} />
          <input
            value={designOption.title}
            placeholder={t('Design Options')}
            className={cn(styles.designOptionInput, 'cy-design-option-title')}
            onChange={(event) => updateDesignOption(index, event.target.value, designOption.value)}
          />
        </div>

        {showBinIcon && (
          <div
            onClick={() => {
              deleteDesignOption(designOption);
            }}
            className={cn(styles.iconContainer, 'cy-design-option-delete')}
          >
            <IconBin fill="#8A8A8A" className={styles.binIcon} />
          </div>
        )}
      </div>
      <div className={styles.error}>{error}</div>
    </>
  );
}

export default React.memo(OptionEntry);
