import React, { useEffect, useRef } from 'react';

import { Coords } from 'editor/src/store/design/types';

import CustomFabricLine from 'editor/src/fabric/CustomFabricLine';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricLineComponent from 'editor/src/component/EditorArea/fabricComponents/FabricLineComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';

import getLinesCoordinates from './getLinesCoordinates';

interface Props {
  spreadHeight: number;
  spreadWidth: number;
  spreadCoords: Coords;
}

const PERSPECTIVE_LINES_COLOR = '#CCCCCC';

function PerspectiveLines({ spreadCoords, spreadWidth, spreadHeight }: Props) {
  const { mm2px } = useFabricUtils();
  const fabricCanvas = useFabricCanvas();
  const zoom = fabricCanvas.getZoom();
  const bbox = fabricCanvas.calcViewportBoundaries();

  const tlLineRef = useRef<CustomFabricLine>(null);
  const trLineRef = useRef<CustomFabricLine>(null);
  const blLineRef = useRef<CustomFabricLine>(null);
  const brLineRef = useRef<CustomFabricLine>(null);

  const coords = getLinesCoordinates(bbox, spreadCoords, spreadWidth, spreadHeight, mm2px);
  const strokeWidth = 1 / zoom;

  useEffect(() => {
    const onViewportChange = () => {
      const lineCoords = getLinesCoordinates(
        fabricCanvas.calcViewportBoundaries(),
        spreadCoords,
        spreadWidth,
        spreadHeight,
        mm2px,
      );
      const strokeWidth = 1 / fabricCanvas.getZoom();
      tlLineRef.current?.set({ ...lineCoords.tl, strokeWidth });
      trLineRef.current?.set({ ...lineCoords.tr, strokeWidth });
      blLineRef.current?.set({ ...lineCoords.bl, strokeWidth });
      brLineRef.current?.set({ ...lineCoords.br, strokeWidth });
    };

    fabricCanvas.on(VIEWPORT_CHANGED_EVENT as any, onViewportChange);
    return () => {
      fabricCanvas.off(VIEWPORT_CHANGED_EVENT as any, onViewportChange);
    };
  }, [fabricCanvas, spreadCoords, spreadWidth, spreadHeight, mm2px]);

  return (
    <>
      <FabricLineComponent
        ref={tlLineRef}
        x1={coords.tl.x1}
        y1={coords.tl.y1}
        x2={coords.tl.x2}
        y2={coords.tl.y2}
        evented={false}
        stroke={PERSPECTIVE_LINES_COLOR}
        zIndex={zIndex.PERSPECTIVE_LINE}
        strokeWidth={strokeWidth}
        objectCaching={false}
      />
      <FabricLineComponent
        ref={brLineRef}
        x1={coords.br.x1}
        y1={coords.br.y1}
        x2={coords.br.x2}
        y2={coords.br.y2}
        evented={false}
        stroke={PERSPECTIVE_LINES_COLOR}
        zIndex={zIndex.PERSPECTIVE_LINE}
        strokeWidth={strokeWidth}
        objectCaching={false}
      />
      <FabricLineComponent
        ref={trLineRef}
        x1={coords.tr.x1}
        y1={coords.tr.y1}
        x2={coords.tr.x2}
        y2={coords.tr.y2}
        evented={false}
        stroke={PERSPECTIVE_LINES_COLOR}
        zIndex={zIndex.PERSPECTIVE_LINE}
        strokeWidth={strokeWidth}
        objectCaching={false}
      />
      <FabricLineComponent
        ref={blLineRef}
        x1={coords.bl.x1}
        y1={coords.bl.y1}
        x2={coords.bl.x2}
        y2={coords.bl.y2}
        evented={false}
        stroke={PERSPECTIVE_LINES_COLOR}
        zIndex={zIndex.PERSPECTIVE_LINE}
        strokeWidth={strokeWidth}
        objectCaching={false}
      />
    </>
  );
}

export default React.memo(PerspectiveLines);
