import React, { useLayoutEffect, useState } from 'react';

import { IntroTourType } from 'editor/src/store/editorModules/tour/types';
import getIntroTourConfig from 'editor/src/store/editorModules/tour/utils/introTourConfig';

import styles from './index.module.scss';

interface Props {
  activeIntroTourId: IntroTourType;
}

function EditorTourHighlightedAreas({ activeIntroTourId }: Props) {
  const [areas, setAreas] = useState<DOMRect[]>([]);
  const [viewport, setViewport] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    // Get viewport size
    setViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    // Get highlighted areas
    const introTourConfig = getIntroTourConfig(activeIntroTourId);
    const elementAreas: DOMRect[] = [];
    introTourConfig?.elementSelectors.forEach((selector) => {
      document.querySelectorAll(selector).forEach((element) => {
        elementAreas.push(element.getBoundingClientRect());
      });
    });

    setAreas(elementAreas);
  }, [activeIntroTourId]);

  return (
    <svg className={styles.masks} width="100vw" height="100vh">
      <defs>
        <mask id="mask">
          <rect width={viewport.width} height={viewport.height} fill="white" />

          {areas.map((area) => (
            <rect
              key={`${area.left}-${area.top}-${area.width}-${area.height}`}
              x={area.left}
              y={area.top}
              width={area.width}
              height={area.height}
              fill="black"
            />
          ))}
        </mask>
      </defs>

      <rect width="100%" height="100%" fill="#21212199" mask="url(#mask)" />
    </svg>
  );
}

export default React.memo(EditorTourHighlightedAreas);
