import { Point as FabricPoint } from 'fabric';
import { useRef, useEffect } from 'react';

import { setDragStateAction } from 'editor/src/store/editor/slice';
import { DraggableItem } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import getDistance from 'editor/src/util/2d/getDistance';

import { passMouseMoveEventToCanvas, createClone } from './useDragUtils';

import './styles.scss';

export const DROP_EVENT = 'custom-drop';
export const DRAG_ELMT_CLASSNAME = 'draggable';

function useDrag(getDraggedElement: () => DraggableItem, enable = true) {
  const elementRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const dragging = useSelector((state) => state.editor.dragState === 'local');

  useEffect(() => {
    if (!elementRef.current || !enable) {
      return undefined;
    }
    let clone: HTMLElement | undefined;
    let timeoutRef = -1;
    const startPos = { x: 0, y: 0 };
    const currentPos = { x: 0, y: 0 };
    let dragStarted = false;

    function stopDrag(withDrop: boolean) {
      if (!dragStarted) {
        return;
      }
      dragStarted = false;
      if (clone) {
        document.body.removeChild(clone);
        clone = undefined;
      }

      if (withDrop) {
        const elementUnderPointer = document.elementFromPoint(currentPos.x, currentPos.y);
        elementUnderPointer?.dispatchEvent(
          new CustomEvent(DROP_EVENT, {
            bubbles: true,
            detail: getDraggedElement(),
          }),
        );
      }
      dispatch(setDragStateAction(undefined));
    }

    function startDrag() {
      if (!elementRef.current) {
        return;
      }
      dragStarted = true;
      const draggableElements = getDraggedElement();
      const draggableElementsCount = draggableElements.isMultipleItems ? draggableElements.itemIds.length : 1;
      clone = createClone(elementRef.current, currentPos.x, currentPos.y, draggableElementsCount);
      document.body.appendChild(clone);
      dispatch(setDragStateAction('local'));
    }

    function placeClone() {
      if (!elementRef.current || !clone) {
        return;
      }
      const x = currentPos.x - elementRef.current.clientWidth / 2;
      const y = currentPos.y - elementRef.current.clientHeight / 2;
      clone.style.transform = `translate3d(${x}px,${y}px,0)`;
    }

    function onMouseMove(e: MouseEvent) {
      currentPos.x = e.clientX;
      currentPos.y = e.clientY;
      if (!dragStarted) {
        if (getDistance(new FabricPoint(currentPos), new FabricPoint(startPos)) < 10) {
          // if the mouse moves less than 10 px then it's not a drag
          return;
        }
        startDrag();
      }

      e.preventDefault();
      placeClone();
    }

    function onMouseUp(e: MouseEvent) {
      currentPos.x = e.clientX;
      currentPos.y = e.clientY;
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      if (dragStarted) {
        e.preventDefault();
        stopDrag(true);
      }
    }

    function onMouseDown(e: MouseEvent) {
      startPos.x = e.clientX;
      startPos.y = e.clientY;
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }

    function onTouchMove(e: TouchEvent) {
      currentPos.x = e.touches[0].clientX;
      currentPos.y = e.touches[0].clientY;
      if (!dragStarted) {
        if (getDistance(new FabricPoint(currentPos), new FabricPoint(startPos)) > 10) {
          // if the finger moves more than 10 px before the timeout, its not a drag
          window.clearInterval(timeoutRef);
          timeoutRef = -1;
        }
        return;
      }
      e.preventDefault();
      passMouseMoveEventToCanvas(e);
      placeClone();
    }

    function onTouchEnd(e: TouchEvent) {
      if (timeoutRef !== -1) {
        window.clearInterval(timeoutRef);
        timeoutRef = -1;
      }
      currentPos.x = e.changedTouches[0].clientX;
      currentPos.y = e.changedTouches[0].clientY;
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);

      if (dragStarted) {
        e.preventDefault();
        stopDrag(true);
      }
    }

    function onTouchStart(e: TouchEvent) {
      if (timeoutRef !== -1 || dragStarted) {
        return;
      }
      startPos.x = e.touches[0].clientX;
      startPos.y = e.touches[0].clientY;
      currentPos.x = startPos.x;
      currentPos.y = startPos.y;
      window.addEventListener('touchmove', onTouchMove);
      window.addEventListener('touchend', onTouchEnd);
      timeoutRef = window.setTimeout(() => startDrag(), 500);
    }

    elementRef.current.addEventListener('touchstart', onTouchStart);
    elementRef.current.addEventListener('mousedown', onMouseDown);

    return () => {
      window.clearTimeout(timeoutRef);
      timeoutRef = -1;
      elementRef.current?.removeEventListener('mousedown', onMouseDown);
      elementRef.current?.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);

      stopDrag(false);
    };
  }, [elementRef.current, getDraggedElement, enable]);

  return { elementRef, dragging };
}

export default useDrag;
