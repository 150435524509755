import { FabricObject, Canvas as FabricCanvas, util as fabricNativeUtils } from 'fabric';
import React, { useEffect } from 'react';

function useFrameLoading(isLoading: boolean, frameRef: React.RefObject<FabricObject>, fabricCanvas: FabricCanvas) {
  useEffect(() => {
    if (!isLoading) {
      return undefined;
    }

    let cancel: { abort: () => void } | undefined;
    function animate() {
      cancel?.abort?.();
      if (!frameRef.current) {
        return;
      }
      cancel = fabricNativeUtils.animate({
        startValue: frameRef.current.opacity,
        endValue: frameRef.current.opacity === 0.2 ? 0.4 : 0.2,
        onChange: (value: number) => {
          if (frameRef.current) {
            frameRef.current.opacity = value;
            fabricCanvas.requestRenderAll();
          }
        },
        onComplete: () => animate(),
        duration: 500,
      });
    }

    animate();
    return () => {
      cancel?.abort();
    };
  }, [isLoading]);
}

export default useFrameLoading;
