import cn from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

import setActiveAddLayerDropdownOperation from 'editor/src/store/app/operation/setActiveAddLayerDropdownOperation';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import RemoveImageButton from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/ImageListItem/RemoveImageButton';
import DropDown from 'editor/src/component/DropDown';
import IconPen from 'editor/src/component/Icon/IconPen';
import TextInput from 'editor/src/component/TextInput';

import getImageName from './getImageName';

import styles from './index.module.scss';

interface Props {
  imageId: string;
  noRemove: boolean;
  onRemove: () => void;
}

function MoreInfoDropdown({ imageId, noRemove, onRemove }: Props) {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);
  const [imageName, setImageName] = React.useState<string | undefined>(undefined);
  const imageNameRef = useRef<string>('');

  const image = useSelector((state) => {
    return getImageById(state, imageId);
  });

  useEffect(() => {
    if (image) {
      setImageName(getImageName(image));
    }
  }, [image]);

  const enableImagesRenaming = useSelector((state) => getHostSetting(state, 'enableImagesRenaming'));

  const openEditMode = useCallback(() => {
    setEditMode((mode) => !mode);
    imageNameRef.current = imageName || '';
  }, [imageName]);

  const saveImageName = useCallback(() => {
    if (!image) {
      return;
    }

    const newName = imageNameRef.current;
    // if user remove name we don't save it
    setEditMode(false);

    if (!newName && image) {
      setImageName(getImageName(image));
      return;
    }

    sendPostMessage('uploader.updateAssetData', {
      assetId: image.id,
      filename: newName,
    });
    setImageName(newName);
    imageNameRef.current = '';
  }, [image]);

  const closeDropdown = () => {
    dispatch(setActiveAddLayerDropdownOperation(undefined));
  };

  const onNameChanged = useCallback((value: string) => {
    imageNameRef.current = value.trim();
  }, []);

  if (!image) {
    return null;
  }

  return (
    <DropDown
      isVisible
      className={cn(styles.dropDown, 'cy-image-info-container')}
      onClickOutside={closeDropdown}
      openPosition="right-fromtop"
    >
      {imageName && (
        <div className={styles.imageInfo}>
          {!editMode && (
            <div
              className={cn('cy-image-info', styles.imageInfoText)}
              onClick={() => enableImagesRenaming && openEditMode()}
            >
              {imageName}
            </div>
          )}
          {editMode && (
            <TextInput
              className={cn(styles.editInput, 'cy-image-info-input')}
              value={imageName}
              debounceTime={0}
              focusOnMount
              onChange={onNameChanged}
              onBlur={saveImageName}
              onEnterPressed={saveImageName}
            />
          )}
          {enableImagesRenaming && !editMode && (
            <div className={styles.updateButton} onClick={openEditMode}>
              <IconPen />
            </div>
          )}
        </div>
      )}
      {!noRemove && <RemoveImageButton onRemove={onRemove} />}
    </DropDown>
  );
}

export default React.memo(MoreInfoDropdown);
