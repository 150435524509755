import { ProductControlOptionDetails } from 'editor/src/store/variants/types';

export interface ContentPluginRootState {
  data: DataState;
  pluginName: string;
}

export enum ContentProviderTabNames {
  MAIN = 'MAIN',
  FILTERS = 'FILTERS',
}

export interface DataState {
  activeFilters?: AppliedFilters;
  results?: ResultsData;
  requestVersion: number;
  loadingMore: boolean;
  activeGroup?: Partial<GroupElement>;
  activeTab: ContentProviderTabNames;
}

type ControlUIType = 'button' | 'color';
interface BaseControl {
  type: 'single' | 'multi';
  uiType: ControlUIType;
  key: string;
  title: string;
  collapsible?: boolean;
  tooltipText?: string;
}

export interface ControlOption {
  title: string;
  value: string;
  optionDetails?: ProductControlOptionDetails;
}

export interface ControlButton extends BaseControl {
  uiType: 'button';
  options: ControlOption[];
  allValuesOptionKey?: string;
}

interface ControlColor extends BaseControl {
  uiType: 'color';
}

export type FilterControl = ControlButton | ControlColor;
export type AppliedFilters = { [keyof: string]: string | string[] | undefined };
export interface SortOption {
  label: string;
  value: string;
}

export interface ResultsData {
  groupId?: string;
  more?: boolean;
  search: {
    value: string;
    filters: AppliedFilters | undefined;
    sort: string | undefined;
  };
  filterControls?: FilterControl[];
  sort?: SortOption[];
  resultsVersion: number;
  elements: Element[];
  totalCount?: number;
  error: boolean;
}

export type Element = ImageElement | GroupElement;

export interface BaseElement {
  id: string;
}

export enum ELEMENT_TYPE {
  IMAGE = 'image',
  GROUP = 'group',
}

export interface ImageElement extends BaseElement {
  type: ELEMENT_TYPE.IMAGE;
  id: string;
  thumbnail: string;
  width: number;
  height: number;
}

export interface GroupElement extends BaseElement {
  type: ELEMENT_TYPE.GROUP;
  title: string;
  children: ImageElement[];
}

export interface EditImageElement extends BaseElement {
  title: string;
}
