import { Group as FabricGroup, FabricObject, util as fabricNativeUtils, loadSVGFromString } from 'fabric';
import { GroupProps } from 'fabric/src/shapes/Group';
import React, { useContext, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import { GroupContext } from './FabricGroupComponent';
import { disposeElement } from './fabricUtils';
import useObjectProps from './useObjectProps';

interface Props extends Partial<GroupProps> {
  svg: string;
  getFabricOptionsOnUpdate?: (element: FabricObject) => Partial<FabricObject>;
  zIndex?: number;
}

export type SVGElement = FabricGroup | FabricObject;

const DEFAULT_OBJECT = new FabricObject({ objectCaching: false });

function FabricSVGComponent(props: Props, ref: React.Ref<SVGElement>) {
  const fabricCanvas = useFabricCanvas();
  const groupContext = useContext(GroupContext);

  const [element, setElement] = useState<SVGElement>(DEFAULT_OBJECT);

  const { svg, getFabricOptionsOnUpdate, ...elementProps } = props;
  useObjectProps(element, elementProps as Partial<FabricObject>);

  const getFabricOptionsOnUpdateRef = useRef(getFabricOptionsOnUpdate);
  getFabricOptionsOnUpdateRef.current = getFabricOptionsOnUpdate;

  useImperativeHandle(ref, () => element, [element]);

  useLayoutEffect(() => {
    void loadSVGFromString(svg).then(({ objects, options }) => {
      const notEmptyObjects = objects.filter((o) => !!o);
      const svgElement = fabricNativeUtils.groupSVGElements(notEmptyObjects, options);
      if (getFabricOptionsOnUpdateRef.current) {
        svgElement.set(getFabricOptionsOnUpdateRef.current(svgElement));
      }
      setElement(svgElement);
    });
  }, [svg]);

  useLayoutEffect(() => {
    const containerContext = groupContext ?? fabricCanvas;
    containerContext.add(element);
    return () => {
      if (element instanceof FabricGroup) {
        element._objects.forEach(disposeElement);
      }
      disposeElement(element);
      containerContext.remove(element);
      fabricCanvas.requestRenderAll();
    };
  }, [element]);

  useLayoutEffect(
    () => () => {
      fabricCanvas.requestRenderAll();
    },
    [],
  );

  useLayoutEffect(() => {
    if (getFabricOptionsOnUpdateRef.current) {
      element.set(getFabricOptionsOnUpdateRef.current(element));
    }
    element.setCoords();
    fabricCanvas.requestRenderAll();
  });

  return null;
}

export default React.memo(React.forwardRef(FabricSVGComponent));
