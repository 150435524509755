import React, { useEffect } from 'react';

import { useDispatch } from 'editor/src/store/hooks';
import requestHelpCenterArticlesOperation from 'editor/src/store/hostSettings/operation/requestHelpCenterArticlesOperation';
import { ArticlesHelpCenter } from 'editor/src/store/hostSettings/types';

import IconArrowRight from 'editor/src/component/Icon/IconArrowRight';
import SearchInput from 'editor/src/component/SearchInput';

import styles from './index.module.scss';

interface Props {
  articles: ArticlesHelpCenter[];
  searchString: string;
}

const DEFAULT_SEARCH_STRING = 'editor';

function HelpCenterArticles({ articles, searchString }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    onSearchStringChange(DEFAULT_SEARCH_STRING);
  }, []);

  const onSearchStringChange = (query: string) => {
    if (searchString !== query) {
      dispatch(requestHelpCenterArticlesOperation(query || DEFAULT_SEARCH_STRING));
    }
  };

  return (
    <div className={styles.helpCenterContainer}>
      <SearchInput onChange={onSearchStringChange} />
      {articles.map((article) => (
        <a className={styles.articleBlock} key={article.id} href={article.url} target="_blank" rel="noreferrer">
          {article.highlightedTitle?.map(({ text }) => text).join('')}
          <IconArrowRight />
        </a>
      ))}
    </div>
  );
}

export default React.memo(HelpCenterArticles);
