import { Point as FabricPoint } from 'fabric';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import getDistance from 'editor/src/util/2d/getDistance';
import { Point } from 'editor/src/util/2d/types';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import { roundToDecimals } from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/utils';

import { BBoxSnap, PointSnap, Snap, SnapType } from './snapsDataUtils';
import {
  get2FurthestPoints,
  addOffsetToSegment,
  getSquaredDistance,
  getDistanceToSnap,
  findSingleParallelSnapIntersection,
  findDoubleSnapIntersection,
  findSnapIntersection,
  findSnapsIntersection,
} from './snapsUtils';

export type SnapMatchLine = { from: Point; to: Point; type: SnapType };
export type SnapMatch = { lines: SnapMatchLine[]; snapPoint: Point };

type SnapUpdateHandler = (snapMatch: SnapMatch | undefined) => void;
type TargetSnapPerSlope = { [slopeVal: string]: Array<BBoxSnap | PointSnap> };

const MARGIN = 5;

function snapsToLinesFromSnap(snaps: Array<{ snap: Snap; eltSnap: Snap }>, offset: Point): SnapMatch['lines'] {
  return snaps.map(({ snap, eltSnap }) => {
    if (snap.type === 'canva') {
      return { from: snap.segment[0], to: snap.segment[1], type: snap.type };
    }

    const points = get2FurthestPoints(snap.segment, addOffsetToSegment(offset, eltSnap.segment));
    return { from: points[0], to: points[1], type: snap.type };
  });
}

function snapsToLinesFromPoint(snaps: Snap[], point: Point): SnapMatch['lines'] {
  return snaps.map((snap) => {
    if (snap.type === 'canva') {
      return { from: snap.segment[0], to: snap.segment[1], type: snap.type };
    }

    const from =
      getSquaredDistance(snap.segment[0], point) > getSquaredDistance(snap.segment[1], point)
        ? snap.segment[0]
        : snap.segment[1];
    return { from, to: point, type: snap.type };
  });
}

function findBBoxSnap(snaps: Snap[], snapPoint: Point, slopeVal: string, uuid: number) {
  return snaps.find((s) => {
    if (s.type !== 'bbox' || s.uuid !== uuid || s.slopeVal !== slopeVal) {
      return false;
    }

    if (s.isVertical) {
      return Math.abs(roundToDecimals(snapPoint.x - s.snapPoint.x, 5)) === 0;
    }

    if (s.slope === 0) {
      return Math.abs(roundToDecimals(snapPoint.y - s.snapPoint.y, 5)) === 0;
    }

    // is the point on the line
    return roundToDecimals(s.yIntercept, 2) === roundToDecimals(snapPoint.y - s.slope * snapPoint.x, 2);
  });
}

function shouldUpdateMatch(snapPoint: Point, snapCount: number, match: SnapMatch) {
  return match.snapPoint.x !== snapPoint.x || match.snapPoint.y !== snapPoint.y || snapCount !== match.lines.length;
}

function hasSameLine(snap1: Snap, snap2: Snap): boolean {
  if (snap1.slopeVal !== snap2.slopeVal) {
    return false;
  }

  if (snap1.isVertical) {
    return snap1.segment[0].x === snap2.segment[0].x;
  }

  return snap1.yIntercept === snap2.yIntercept;
}

function useCreateSnapController() {
  const fabricCanvas = useFabricCanvas();
  const snapsRef = useRef<Snap[]>([]);
  const targetSnapsPerSlopeRef = useRef<TargetSnapPerSlope>({});
  const snapMatchRef = useRef<SnapMatch>();
  const snapHandlers = useRef<SnapUpdateHandler[]>([]);

  function setSnapMatch(snapMatch: SnapMatch | undefined) {
    if (snapMatchRef.current === snapMatch) {
      return;
    }
    snapMatchRef.current = snapMatch;
    snapHandlers.current.forEach((handler) => handler(snapMatch));
  }

  const isPressingCtrl = useRef(false);
  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Control' || e.key === 'Meta') {
        isPressingCtrl.current = true;
        setSnapMatch(undefined);
      }
    }

    function onKeyUp(e: KeyboardEvent) {
      if (e.key === 'Control' || e.key === 'Meta') {
        isPressingCtrl.current = false;
      }
    }

    // when pressing command + tab - onKeyUp is not fired
    // because of that onBlur catches that case
    function onBlur() {
      isPressingCtrl.current = false;
    }

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keydown', onKeyUp);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  function checkBBoxSnapping(
    snapOffset: Point,
    uuid: number,
  ): { snapPoint: Point; offsetToTL: Point; matchSide: 'x' | 'y' | undefined } | undefined {
    if (isPressingCtrl.current) {
      setSnapMatch(undefined);
      return undefined;
    }

    const snapped = new Map<
      string,
      {
        distance: number;
        snaps: Array<{ snap: Snap; eltSnap: BBoxSnap | PointSnap }>;
      }
    >();
    const marginWithZoom = MARGIN / fabricCanvas.getZoom();

    // we go through every snap segment, and if the current element has a slope that matches, we check its distance
    snapsRef.current.forEach((snap) => {
      targetSnapsPerSlopeRef.current[snap.slopeVal]?.forEach((eltSnap) => {
        if (eltSnap.uuid !== uuid) {
          return;
        }

        // element should not snap to itself, pointSnap should not snap to its element snap or other pointSnaps and vice versa
        if (
          ((snap.type === 'bbox' || snap.type === 'pointSnap') && uuid === snap.uuid) ||
          (eltSnap.type === 'pointSnap' && snap.type === 'bbox' && eltSnap.elementUuid === snap.uuid) ||
          (eltSnap.type === 'bbox' && snap.type === 'pointSnap') ||
          (eltSnap.type === 'pointSnap' && snap.type === 'pointSnap' && eltSnap.elementUuid !== snap.elementUuid)
        ) {
          return;
        }

        const distance = roundToDecimals(getDistanceToSnap(snap, eltSnap, snapOffset), 5);
        if (distance < marginWithZoom) {
          const sameSlopeSnap = snapped.get(snap.slopeVal);
          if (!sameSlopeSnap || sameSlopeSnap.distance > distance) {
            snapped.set(snap.slopeVal, {
              distance,
              snaps: [{ snap, eltSnap }],
            });
          } else if (sameSlopeSnap.distance === distance) {
            // prio canva over bbox
            const sameSnapLineIndex = sameSlopeSnap.snaps.findIndex((sameSnap) => hasSameLine(sameSnap.snap, snap));
            if (sameSnapLineIndex === -1) {
              sameSlopeSnap.snaps.push({ snap, eltSnap });
            } else if (snap.type === 'canva') {
              sameSlopeSnap.snaps[sameSnapLineIndex] = { snap, eltSnap };
            }
          }
        }
      });
    });

    const matchSnaps = Array.from(snapped.values());
    if (!matchSnaps.length) {
      setSnapMatch(undefined);
      return undefined;
    }

    // snapping to one segment
    if (matchSnaps.length === 1) {
      const { snaps } = matchSnaps[0];
      const { point, offsetToTL, matchSide } = findSingleParallelSnapIntersection(
        snaps[0].snap,
        snaps[0].eltSnap,
        snapOffset,
      );
      if (!snapMatchRef.current || shouldUpdateMatch(point, snaps.length, snapMatchRef.current)) {
        const offset: Point = {
          x: point.x - snaps[0].eltSnap.segment[0].x,
          y: point.y - snaps[0].eltSnap.segment[0].y,
        };
        setSnapMatch({
          lines: snapsToLinesFromSnap(snaps, offset),
          snapPoint: point,
        });
      }
      return { snapPoint: point, offsetToTL, matchSide };
    }

    // snapping to 2 segments
    const { snaps: snaps1 } = matchSnaps[0];
    const { snaps: snaps2 } = matchSnaps[1];
    const { point, offset, offsetToTL } = findDoubleSnapIntersection(
      snaps1[0].snap,
      snaps2[0].snap,
      snaps1[0].eltSnap,
      snaps2[0].eltSnap,
    );
    if (!snapMatchRef.current || shouldUpdateMatch(point, snaps1.length + snaps2.length, snapMatchRef.current)) {
      setSnapMatch({
        lines: [...snapsToLinesFromSnap(snaps1, offset), ...snapsToLinesFromSnap(snaps2, offset)],
        snapPoint: point,
      });
    }
    return { snapPoint: point, offsetToTL, matchSide: undefined };
  }

  function checkSegmentSnapping(
    eltSnap: BBoxSnap,
    snapOffset: Point,
  ): { snapPoint: Point; snappedPoint: Point; offsetToTL: Point } | undefined {
    if (isPressingCtrl.current) {
      setSnapMatch(undefined);
      return undefined;
    }

    let currentSnapVal:
      | {
          snap: Snap;
          eltSnap: BBoxSnap;
          snappedPoint: Point;
          intersection: { point: Point; offsetToTL: Point };
        }
      | undefined;

    const eltSegmentWithOffset = addOffsetToSegment(snapOffset, eltSnap.segment);
    const marginWithZoom = MARGIN / fabricCanvas.getZoom();
    let currentDistance: number = marginWithZoom;
    snapsRef.current.forEach((snap) => {
      if (snap.type === 'bbox' && eltSnap.uuid === snap.uuid) {
        return;
      }

      const i1 = findSnapIntersection(snap, eltSnap, snapOffset, eltSnap.segment[0]);
      const i2 = findSnapIntersection(snap, eltSnap, snapOffset, eltSnap.segment[1]);
      const d1 = i1 ? getDistance(new FabricPoint(i1.point), new FabricPoint(eltSegmentWithOffset[0])) : marginWithZoom;
      const d2 = i2 ? getDistance(new FabricPoint(i2.point), new FabricPoint(eltSegmentWithOffset[1])) : marginWithZoom;
      const intersection = d1 < d2 ? i1 : i2;

      const distance = Math.min(d1, d2);
      if (distance < marginWithZoom && distance < currentDistance && intersection) {
        const snappedPoint = d1 < d2 ? eltSnap.segment[0] : eltSnap.segment[1];
        currentDistance = distance;
        currentSnapVal = {
          snap,
          eltSnap,
          snappedPoint,
          intersection,
        };
      }
    });

    const snapMatch = snapMatchRef.current;
    if (currentSnapVal) {
      const { snappedPoint, intersection, snap } = currentSnapVal;
      const { point, offsetToTL } = intersection;
      if (!snapMatch || shouldUpdateMatch(point, 1, snapMatch)) {
        const matchingBbox = findBBoxSnap(snapsRef.current, snappedPoint, snap.slopeVal, eltSnap.uuid);
        if (matchingBbox) {
          const offset: Point = {
            x: point.x - snappedPoint.x,
            y: point.y - snappedPoint.y,
          };
          setSnapMatch({
            lines: snapsToLinesFromSnap([{ snap, eltSnap: matchingBbox }], offset),
            snapPoint: point,
          });
        } else {
          setSnapMatch({
            lines: snapsToLinesFromPoint([snap], point),
            snapPoint: point,
          });
        }
      }
      return { snapPoint: point, offsetToTL, snappedPoint };
    }

    setSnapMatch(undefined);
    return undefined;
  }

  function checkCornerSnapping(
    cornerSnap: BBoxSnap,
    snapPoint: Point,
    snapOffset: Point,
  ): { snapPoint: Point; offsetToTL: Point } | undefined {
    if (isPressingCtrl.current) {
      setSnapMatch(undefined);
      return undefined;
    }

    const snapPointWithOffset: Point = {
      x: snapPoint.x + snapOffset.x,
      y: snapPoint.y + snapOffset.y,
    };

    let currentSnapVal: { snap: Snap; intersection: Point } | undefined;
    const marginWithZoom = MARGIN / fabricCanvas.getZoom();
    let currentDistance: number = marginWithZoom;
    snapsRef.current.forEach((snap) => {
      if (snap.type === 'bbox' && cornerSnap.uuid === snap.uuid) {
        return;
      }

      const intersection = findSnapsIntersection(snap, cornerSnap);
      const distance = getDistance(new FabricPoint(intersection), new FabricPoint(snapPointWithOffset));
      if (distance < marginWithZoom && distance < currentDistance) {
        currentDistance = distance;
        currentSnapVal = { snap, intersection };
      }
    });

    const snapMatch = snapMatchRef.current;
    if (currentSnapVal) {
      const { intersection, snap } = currentSnapVal;
      if (!snapMatch || shouldUpdateMatch(intersection, 1, snapMatch)) {
        const matchingBbox = findBBoxSnap(snapsRef.current, snapPoint, snap.slopeVal, cornerSnap.uuid);
        if (matchingBbox) {
          const offset: Point = {
            x: intersection.x - snapPoint.x,
            y: intersection.y - snapPoint.y,
          };
          setSnapMatch({
            lines: snapsToLinesFromSnap([{ snap, eltSnap: matchingBbox }], offset),
            snapPoint: intersection,
          });
        } else {
          setSnapMatch({
            lines: snapsToLinesFromPoint([snap], intersection),
            snapPoint: intersection,
          });
        }
      }

      const offsetToTL = {
        x: cornerSnap.tlElement.x - snapPoint.x,
        y: cornerSnap.tlElement.y - snapPoint.y,
      };
      return { snapPoint: intersection, offsetToTL };
    }

    setSnapMatch(undefined);
    return undefined;
  }

  const onSnapUpdate = (handler: SnapUpdateHandler) => {
    snapHandlers.current.push(handler);
  };

  const offSnapUpdate = (handler: SnapUpdateHandler) => {
    const index = snapHandlers.current.indexOf(handler);
    snapHandlers.current.splice(index, 1);
  };

  const isSnappingEnable = useCallback(() => !isPressingCtrl.current, []);

  const stopSnapping = () => setSnapMatch(undefined);
  const isSnapping = () => !!snapMatchRef.current;

  const controller = useMemo(
    () => ({
      checkForSnap: checkBBoxSnapping,
      checkOneSideSnapping: checkSegmentSnapping,
      checkCornerSnapping,
      onSnapUpdate,
      offSnapUpdate,
      stopSnapping,
      isSnapping,
      isSnappingEnable,
      setSnaps: (snaps: Snap[]) => {
        snapsRef.current = snaps;

        targetSnapsPerSlopeRef.current = {};
        snaps.forEach((snap) => {
          if (snap.type === 'bbox' || snap.type === 'pointSnap') {
            if (targetSnapsPerSlopeRef.current[snap.slopeVal]) {
              targetSnapsPerSlopeRef.current[snap.slopeVal].push(snap);
            } else {
              targetSnapsPerSlopeRef.current[snap.slopeVal] = [snap];
            }
          }
        });
      },
    }),
    [],
  );

  return controller;
}

export type SnapController = ReturnType<typeof useCreateSnapController>;

export default useCreateSnapController;
