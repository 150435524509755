import React from 'react';
import { useTranslation } from 'react-i18next';

import { TutorialsHelpCenter } from 'editor/src/store/hostSettings/types';

import styles from './index.module.scss';

function HelpCenterTutorials({ tutorials }: { tutorials: TutorialsHelpCenter[] }) {
  const { t } = useTranslation();

  return (
    <div className={styles.helpCenterContainer}>
      {tutorials?.map(({ label, url }) => (
        <div key={label} className={styles.tutorialBlock}>
          <div className={styles.tutorialTitle}>{t(label)}</div>
          <div className={styles.tutorialFrame}>
            <iframe
              src={url}
              title={label}
              referrerPolicy="strict-origin-when-cross-origin"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default React.memo(HelpCenterTutorials);
