import { CanvasEvents, FabricObject } from 'fabric';
import { Polygon } from 'polygon-clipping';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import removeMediaElementOperation from 'editor/src/store/design/operation/removeMediaElementOperation';
import { Coords, ElementAddress, MediaText } from 'editor/src/store/design/types';
import { setTextEditModeAction as setTextEditModeOperation } from 'editor/src/store/editor/slice';
import { useDispatch } from 'editor/src/store/hooks';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';
import FabricPathText from 'editor/src/fabric/FabricPathText';
import useBrowserColor from 'editor/src/util/useBrowserColor';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import { currentOperationManager } from 'editor/src/component/EditorArea/ElementOperationOverlay';
import FabricImageComponent from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';
import FabricTextInputComponent from 'editor/src/component/EditorArea/fabricComponents/FabricTextInputComponent';
import { setClipPath } from 'editor/src/component/EditorArea/fabricComponents/fabricUtils';
import useSnapMatch from 'editor/src/component/EditorArea/snapping/useSnapMatch';
import ElementLoader from 'editor/src/component/EditorArea/Spread/Page/MediaElement/ElementLoader';
import fabricElementToObjectRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/fabricElementToObjectRect';
import getElementRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getElementRect';
import useHoverBox from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useHoverBox';
import useIsInteractable from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useIsInteractable';
import useStoreSelection from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useStoreSelection';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import fabricPropsToTextElementData from './fabricPropsToTextElementData';
import getDigitizedTextPosition from './getDigitizedTextPosition';
import getTextClipPath from './getTextClipPath';
import getTextShadow from './getTextShadow';
import textElementDataToFabricProps from './textElementDataToFabricProps';
import { getFlexbileTextMaxWidth } from './updateTextElementWithoutRender';
import useDigitizedText from './useDigitizedText';
import useFontLoader from './useFontLoader';
import useTextEditMode from './useTextEditMode';
import useTextUpdates from './useTextUpdates';

interface Props {
  elementData: MediaText;
  elementAddress: ElementAddress;
  pageCoords: Coords;
  areaWidth: number;
  canvasRotation: CanvasRotation;
  contentClipPolygons: Polygon[];
  selected: boolean;
  isMobile: boolean;
  ignorePersonalizationLock: boolean;
  showGuides: boolean;
  contentClipPath: FabricObject | undefined;
}

function Text({
  elementData,
  elementAddress,
  pageCoords,
  areaWidth,
  canvasRotation,
  contentClipPolygons,
  selected,
  isMobile,
  ignorePersonalizationLock,
  showGuides,
  contentClipPath,
}: Props) {
  const { mm2px, px2mm } = useFabricUtils();
  const fabricCanvas = useFabricCanvas();
  const fabricElementRef = useRef<FabricPathText>(null);
  const digitizedImageRef = useRef<CustomFabricImage>(null);
  const dispatch = useDispatch();
  const [digiImageLoaded, setDigiImageLoaded] = useState(false);

  const { liveElement: element, liveUpdate } = useMediaElementLiveUpdates(elementData);

  const isInteractable = useIsInteractable(element, ignorePersonalizationLock);

  const { loadedFontFamily, isFontLoaded } = useFontLoader(element.extra.fontFamily);
  const textEditMode = useTextEditMode(fabricElementRef, selected);
  const { digitizedAsset, showDigitizedAsset, getFabricOptionsOnImageUpdate } = useDigitizedText(
    element,
    textEditMode.enabled,
    px2mm,
  );

  const textUpdates = useTextUpdates(pageCoords, element, elementAddress, fabricElementRef, canvasRotation);
  useStoreSelection(fabricElementRef, element.uuid, element.type, selected);

  const fabricProps = textElementDataToFabricProps(
    element,
    elementAddress.elementIndex,
    mm2px,
    isInteractable,
    isMobile,
    loadedFontFamily,
  );

  fabricProps.fill = useBrowserColor(fabricProps.fill);
  fabricProps.stroke = useBrowserColor(fabricProps.stroke);

  const elementRect = useMemo(
    () => getElementRect(element, pageCoords, canvasRotation, mm2px),
    [element.x, element.y, element.r, element.width, element.height, pageCoords, canvasRotation, mm2px],
  );

  const shadow = useMemo(
    () => getTextShadow(element.extra.shadow, element.extra.fontSize, mm2px),
    [element.extra.shadow, element.extra.fontSize, mm2px],
  );

  const hoverBox = useHoverBox(elementRect, isMobile, selected || !isInteractable, canvasRotation);
  const isMouseDown = useRef(false);

  const customEvents = useMemo(
    () => ({
      'object:enter': () => {
        fabricElementRef.current?.enterEditing();
        fabricElementRef.current?.selectAll();
      },
      'object:escape': () => {
        if (!fabricElementRef.current?.isEditing) {
          // fabric already exits editing on escape
          fabricCanvas.discardActiveObject();
        }
      },
      fontFallbackLoaded: () => {
        fabricCanvas.requestRenderAll();
        textUpdates.onObjectModified();
      },
    }),
    [textUpdates.onObjectModified],
  );

  const onObjectManipulation = useCallback(() => {
    if (textEditMode.enabled) {
      dispatch(setTextEditModeOperation(false));
    }

    if (fabricElementRef.current) {
      if (textEditMode.enabled) {
        setClipPath(fabricElementRef.current, undefined);
      } else {
        const rect = fabricElementToObjectRect(fabricElementRef.current, undefined, false);
        setClipPath(
          fabricElementRef.current,
          getTextClipPath(rect, fabricElementRef.current.fontSize ?? 0, contentClipPolygons, contentClipPath),
        );
      }
    }
  }, [contentClipPolygons, textEditMode.enabled, contentClipPath, element.extra.curve]);

  const onExitEditing = useCallback(() => {
    const text = fabricElementRef.current?.text;

    // Check if element is empty on focus out and if it is delete it
    // !isMouseDown.current is used to not trigger remove element if user is currently dragging the element
    const textEligibleForRemove = !isMouseDown.current && (!text || !text.trim().length);
    if (textEligibleForRemove) {
      dispatch(removeMediaElementOperation(elementAddress));
    }

    textEditMode.onExitEditing();
  }, [textEditMode.onExitEditing, elementAddress]);

  const clipPath = useMemo(
    () => getTextClipPath(elementRect, element.extra.fontSize, contentClipPolygons, contentClipPath),
    [element.extra.fontSize, elementRect, contentClipPolygons, contentClipPath, element.extra.curve],
  );

  const snapMatch = useSnapMatch(element.uuid, showGuides && !element.locked, pageCoords);

  const onMouseDown = useCallback(
    (e: CanvasEvents['mouse:down']) => {
      if (isMobile && element.sample) {
        textEditMode.onEnterEditing();
      }
      isMouseDown.current = true;
      snapMatch.onMouseDown(e);
    },
    [snapMatch.onMouseDown, isMobile, element.sample],
  );

  const onMouseMove = useCallback(
    (e: CanvasEvents['mouse:move']) => {
      if (
        !isMouseDown.current ||
        fabricCanvas.getActiveObject() !== fabricElementRef.current ||
        !fabricElementRef?.current?.oCoords
      ) {
        return;
      }
      snapMatch.onMouseMove(e);
      onObjectManipulation();

      if (fabricElementRef.current) {
        const newElement = {
          ...element,
          ...fabricPropsToTextElementData(px2mm, pageCoords, fabricElementRef.current, canvasRotation),
        };
        liveUpdate(newElement);
        let isElementMove = false;
        if (fabricElementRef.current.oCoords && e.transform) {
          fabricElementRef.current.setCoords();
          isElementMove = e.transform.action === 'drag';
          currentOperationManager.emit(
            'objectUpdating',
            newElement,
            fabricElementRef.current.oCoords,
            isElementMove ? 'move' : 'resize',
          );
        }

        if (digitizedImageRef.current) {
          const { left, top, angle } = fabricElementRef.current;
          const { scaleX, scaleY } = digitizedImageRef.current;
          if (!Number.isNaN(left) && !Number.isNaN(top) && scaleX && scaleY) {
            digitizedImageRef.current.set(
              getDigitizedTextPosition({
                originalLeft: left as number,
                originalTop: top as number,
                scaleX,
                scaleY,
                angle: angle || 0,
                margin: digitizedAsset?.margin || 0,
              }),
            );
          }

          digitizedImageRef.current.set({
            clipPath: fabricElementRef.current.clipPath,
            opacity: digitizedImageRef.current.opacity && isElementMove ? 1 : 0,
          });

          if (fabricElementRef.current.opacity === 0 && !isElementMove) {
            fabricElementRef.current.set('opacity', 1);
          }
        }
      }
    },
    [snapMatch.onMouseMove, px2mm, pageCoords, canvasRotation, onObjectManipulation, digitizedAsset?.margin],
  );

  const onMouseUp = useCallback(() => {
    isMouseDown.current = false;
    snapMatch.onMouseUp();
    currentOperationManager.emit('objectUpdateStop');
  }, [snapMatch.onMouseUp]);

  useEffect(() => {
    // correctly set the clippath after live updates of the element because the height may have changed
    if (fabricElementRef.current) {
      if (textEditMode.enabled) {
        setClipPath(fabricElementRef.current, undefined);
      } else {
        const rect = fabricElementToObjectRect(fabricElementRef.current, undefined, false);
        setClipPath(
          fabricElementRef.current,
          getTextClipPath(rect, fabricElementRef.current.fontSize ?? 0, contentClipPolygons, contentClipPath),
        );
      }
    }
  }, [elementRect]);

  const maxWidth = getFlexbileTextMaxWidth(
    elementRect.width,
    elementRect.left,
    element.extra.textAlign,
    pageCoords.left,
    areaWidth,
  );

  useLayoutEffect(() => {
    setDigiImageLoaded(false);
  }, [element.digitizing_hash]);

  const onDigiImageLoaded = useCallback(() => {
    setDigiImageLoaded(true);
  }, []);

  return (
    <>
      <FabricTextInputComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...fabricProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...elementRect}
        isFontLoaded={isFontLoaded}
        clipPath={textEditMode.enabled ? undefined : clipPath}
        customEvents={customEvents}
        hoverCursor={selected ? 'move' : 'pointer'}
        borderOpacityWhenMoving={0.4}
        uuid={element.uuid}
        ref={fabricElementRef}
        onMouseDown={textEditMode.enabled ? undefined : onMouseDown}
        onMouseMove={textEditMode.enabled ? undefined : onMouseMove}
        onMouseUp={onMouseUp}
        lockMovementX={textEditMode.enabled || fabricProps.lockMovementX}
        lockMovementY={textEditMode.enabled || fabricProps.lockMovementY}
        onMouseOver={hoverBox.onMouseOver}
        onMouseOut={hoverBox.onMouseOut}
        onModified={textUpdates.onObjectModified}
        onChanged={textUpdates.onTextChanged}
        onEditingEntered={textEditMode.onEnterEditing}
        onEditingExited={onExitEditing}
        opacity={element.hidden || (showDigitizedAsset && digitizedAsset?.url && digiImageLoaded) ? 0 : 1}
        shadow={shadow}
        maxCharLimit={element.extra?.maxCharCount}
        paintFirst="stroke"
        maxWidth={maxWidth}
      />
      {digitizedAsset?.url && !textEditMode.enabled && showDigitizedAsset && (
        <FabricImageComponent
          ref={digitizedImageRef}
          source={digitizedAsset.url}
          left={elementRect.left}
          top={elementRect.top}
          evented={false}
          zIndex={fabricProps.zIndex}
          clipPath={textEditMode.enabled ? undefined : clipPath}
          opacity={element.hidden ? 0 : 1}
          uuid={element.uuid}
          getFabricOptionsOnUpdate={getFabricOptionsOnImageUpdate}
          angle={element.r}
          crossOrigin="anonymous"
          onImageLoaded={onDigiImageLoaded}
        />
      )}
      {(element.digitizing || (showDigitizedAsset && !digitizedAsset?.url && elementData.stitch_count !== -1)) && (
        <ElementLoader frameRect={elementRect} element={element} />
      )}
      {hoverBox.render()}
    </>
  );
}

export default React.memo(Text);
