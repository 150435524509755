import getActiveIntroTourStep from 'editor/src/store/editorModules/tour/selector/getActiveIntroTourStep';
import getIntroTourSteps from 'editor/src/store/editorModules/tour/selector/getIntroTourSteps';
import { setActiveIntroStepAction } from 'editor/src/store/editorModules/tour/slice';
import { getToursStepsKey, hideTourElement } from 'editor/src/store/editorModules/tour/utils/tourStorage';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const closeIntroTourOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const tourSteps = getIntroTourSteps(state);
  const activeIntroTourStep = getActiveIntroTourStep(state);
  const metadata = {
    activeIntroTourStep,
    totalTourSteps: tourSteps.length,
  };
  hideTourElement('introTour', getToursStepsKey(tourSteps), metadata);
  dispatch(setActiveIntroStepAction(undefined));
};

export default closeIntroTourOperation;
