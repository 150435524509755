import { Point as FabricPoint } from 'fabric';

import { MediaAddon, MediaImage } from 'editor/src/store/design/types';

import rotatePointOnPoint from 'editor/src/util/rotatePointOnPoint';

const getImageUpdateOnSizeChange = (
  imageElement: MediaImage | MediaAddon,
  update: Partial<MediaImage | MediaAddon>,
) => {
  const newUpdate = { ...update };
  const imageFrameRect = {
    left: imageElement.x,
    top: imageElement.y,
    width: imageElement.width,
    height: imageElement.height,
    angle: imageElement.r,
  };
  const imageRect = {
    left: imageElement.px + imageElement.x,
    top: imageElement.py + imageElement.y,
    width: imageElement.pw,
    height: imageElement.ph,
    angle: imageElement.pr,
  };

  const imagePosition = new FabricPoint(imageRect.left, imageRect.top);
  const oldClipPathPosition = new FabricPoint(imageFrameRect.left, imageFrameRect.top);
  const notRotatedImagePosition = rotatePointOnPoint(
    imagePosition,
    oldClipPathPosition,
    -imageRect.angle - imageFrameRect.angle,
  );
  const scaleX = (update.width ?? imageElement.width) / imageElement.pw;
  const scaleY = (update.height ?? imageElement.height) / imageElement.ph;
  const minScale = Math.max(scaleX, scaleY);
  newUpdate.pw = imageElement.pw * minScale;
  newUpdate.ph = imageElement.ph * minScale;

  const newNotRotatedImagePosition = new FabricPoint(
    notRotatedImagePosition.x + (imageFrameRect.left - notRotatedImagePosition.x) * (1 - minScale),
    notRotatedImagePosition.y + (imageFrameRect.top - notRotatedImagePosition.y) * (1 - minScale),
  );

  const clipPathPosition = new FabricPoint(imageFrameRect.left, imageFrameRect.top);
  const newImagePosition = rotatePointOnPoint(
    newNotRotatedImagePosition,
    clipPathPosition,
    imageFrameRect.angle + imageRect.angle,
  );
  newUpdate.px = newImagePosition.x - imageFrameRect.left;
  newUpdate.py = newImagePosition.y - imageFrameRect.top;
  return newUpdate;
};

export default getImageUpdateOnSizeChange;
