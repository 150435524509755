import { FabricObject, Transform as FabricTransform, util as fabricNativeUtils, Point as FabricPoint } from 'fabric';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';

const getImageUpdateOnRotate = (
  _: FabricTransform,
  mask: FabricObject,
  maskOrigin: FabricObject,
  imageOrigin: FabricObject,
): Partial<CustomFabricImage> => {
  const angleDiff = (mask.angle || 0) - (maskOrigin.angle || 0);
  const rotatedImageCoords = fabricNativeUtils.rotatePoint(
    new FabricPoint(imageOrigin.left || 0, imageOrigin.top || 0),
    maskOrigin.getCenterPoint(),
    fabricNativeUtils.degreesToRadians(angleDiff),
  );

  return {
    left: rotatedImageCoords.x,
    top: rotatedImageCoords.y,
    angle: (imageOrigin.angle || 0) + angleDiff,
  };
};

export default getImageUpdateOnRotate;
