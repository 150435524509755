import { FabricObject } from 'fabric';
import { TOriginY } from 'fabric/src/typedefs';
import { Polygon } from 'polygon-clipping';
import React, { useMemo } from 'react';

import { LineEdge } from 'editor/src/store/design/types';

import CustomFabricObject from 'editor/src/fabric/CustomFabricObject';

import FabricPathComponent from 'editor/src/component/EditorArea/fabricComponents/FabricPathComponent';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';

import { getArrowBoundingRect, getLineEdgePath } from './utils';

interface Props {
  strokeWidth: number;
  left: number;
  top: number;
  stroke: string;
  zIndex: number;
  angle: number;
  edge: LineEdge;
  contentClipPolygons: Polygon[];
  contentClipPath: FabricObject | undefined;
}

function LineEdgeComponent(
  { stroke, strokeWidth, left, top, zIndex, angle, edge, contentClipPolygons, contentClipPath }: Props,
  ref: React.Ref<CustomFabricObject>,
) {
  const clipPath = useMemo(
    () => getClipPath(getArrowBoundingRect(strokeWidth, angle, left, top), contentClipPolygons, false, contentClipPath),
    [strokeWidth, angle, left, top, contentClipPolygons, contentClipPath],
  );
  const arrowProps = {
    left,
    top,
    stroke,
    strokeWidth,
    zIndex,
    clipPath,
    angle,
    evented: false,
    strokeLineCap: 'round' as CanvasLineCap,
    strokeLineJoin: 'round' as CanvasLineJoin,
    originY: 'center' as TOriginY,
    ref,
    objectCaching: false,
  };
  const edgePath = getLineEdgePath(strokeWidth, left, top);
  switch (edge) {
    case 'arrow-fill':
      return (
        <FabricPathComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...arrowProps}
          fill={stroke}
          elementPath={`${edgePath} Z`}
        />
      );
    case 'arrow':
      return (
        <FabricPathComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...arrowProps}
          fill="transparent"
          elementPath={edgePath}
        />
      );
    default:
      return null;
  }
}

export default React.forwardRef(LineEdgeComponent);
