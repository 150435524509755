import { Polygon } from 'polygon-clipping';
import React, { useMemo } from 'react';

import { Bleed, Coords } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricPathComponent from 'editor/src/component/EditorArea/fabricComponents/FabricPathComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import useCanvasRotation from 'editor/src/component/EditorArea/useCanvasRotation';
import { polygonToFabricPolygon } from 'editor/src/component/EditorArea/utils/polygonsUtils';

interface Props {
  bleed: Bleed;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  polygonClip: Polygon | undefined;
}

const STOKE_DASH_ARRAY = [3, 3];

function BleedElement({ bleed, pageCoords, canvasRotation, polygonClip }: Props) {
  const { mm2px } = useFabricUtils();

  const coords = useCanvasRotation(
    canvasRotation,
    pageCoords.left + mm2px(bleed.x) - 0.5,
    pageCoords.top + mm2px(bleed.y) - 0.5,
  );

  const scale = mm2px(1);
  const clipPath = useMemo(
    () => polygonClip && polygonToFabricPolygon(polygonClip, { absolutePositioned: true }),
    [polygonClip],
  );

  return (
    <FabricPathComponent
      elementPath={bleed.path || ''}
      left={coords.x}
      top={coords.y}
      selectable={false}
      evented={false}
      fillRule="evenodd"
      strokeUniform
      strokeLineCap="round"
      strokeWidth={mm2px(0.5)}
      strokeDashArray={STOKE_DASH_ARRAY}
      fill="rgba(200, 200, 200, 0.2)"
      stroke="rgba(33, 33, 33, 0.2)"
      scaleX={scale}
      scaleY={scale}
      zIndex={zIndex.BLEED}
      angle={canvasRotation.angleDeg}
      clipPath={clipPath}
      objectCaching={false}
    />
  );
}

export default React.memo(BleedElement);
