import { useEffect } from 'react';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useForceUpdate from 'editor/src/component/useForceUpdate';

import { VIEWPORT_CHANGED_EVENT } from './types';

// be careful using it with fabric components. It may be too resources consuming
function useForceUpdateOnViewportChange() {
  const forceUpdate = useForceUpdate();
  const fabricCanvas = useFabricCanvas();

  useEffect(() => {
    fabricCanvas.on(VIEWPORT_CHANGED_EVENT as any, forceUpdate);

    return () => {
      fabricCanvas.off(VIEWPORT_CHANGED_EVENT as any, forceUpdate);
    };
  }, []);
}

export default useForceUpdateOnViewportChange;
