import { CanvasEvents } from 'fabric';
import React, { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';

import { TextWrapping } from 'editor/src/store/design/types';

import FabricPathText, { IPathTextOption } from 'editor/src/fabric/FabricPathText';
import limitPrecision from 'editor/src/util/limitPrecision';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import setCursors from './setCursors';
import useEvent, { EventHandler } from './useEvent';
import useEvents from './useEvents';
import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

interface Props extends IPathTextOption {
  text: string;
  maxCharLimit?: number;
  customEvents?: { [eventType: string]: EventHandler };
  onMouseOver?: EventHandler;
  onMouseOut?: EventHandler;
  onMouseDown?: EventHandler;
  onMouseUp?: EventHandler;
  onMouseMove?: EventHandler;
  onModified?: EventHandler;
  onChanged?: EventHandler;
  onEditingEntered?: EventHandler;
  onEditingExited?: EventHandler;
}

function FabricTextInputComponent(props: Props, ref: React.Ref<FabricPathText>) {
  const fabricCanvas = useFabricCanvas();
  const first = useRef(true);
  const {
    maxCharLimit,
    customEvents,
    onMouseOver,
    onMouseOut,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onModified,
    onChanged,
    onEditingEntered,
    onEditingExited,
    wrapping,
    ...fabricProps
  } = props;

  // force objectCaching=false or we get a memory leak on safari when the cached text canvas is not garbage collected
  const [element] = useState(
    () => new FabricPathText(fabricProps.text, { ...fabricProps, objectCaching: false, wrapping }),
  );
  useObjectProps(element, fabricProps);
  useImperativeHandle(ref, () => element);

  const currentTextEltData = useRef({
    x: element.left ?? 0,
    width: element.width ?? 1,
  });
  const isMouseDown = useRef(false);
  const onMouseDownScale = useCallback(
    (e: CanvasEvents['mouse:down']) => {
      isMouseDown.current = true;
      currentTextEltData.current.x = e.absolutePointer?.x ?? 0;
      currentTextEltData.current.width = element.getScaledWidth() ?? 1;
    },
    [element],
  );
  useEvent(element, 'mousedown', onMouseDownScale);

  useEffect(() => {
    if (!element.width || !element.height || !props.width || !props.height) {
      return;
    }

    // it's possible that initial element height is smaller then actual text height
    if (
      limitPrecision(element.width) !== limitPrecision(props.width) ||
      limitPrecision(element.height) !== limitPrecision(props.height)
    ) {
      onModified?.();
    }
  }, [element]);

  const onMouseMoveScale = useCallback(
    (e: CanvasEvents['mouse:move']) => {
      if (!isMouseDown.current || fabricCanvas.getActiveObject() !== element) {
        return;
      }

      if (e.transform?.action === 'scale') {
        element.editable = false;
        const x = e.absolutePointer?.x ?? 0;
        const deltaX = x - currentTextEltData.current.x;
        const newWidth = currentTextEltData.current.width + deltaX;
        const scale = newWidth / currentTextEltData.current.width;
        element.set({ scaleX: scale, scaleY: scale });
        fabricCanvas.requestRenderAll();
      }
    },
    [element],
  );
  useEvent(element, 'mousemove', onMouseMoveScale);

  const onMouseUpScale = useCallback(
    (e: CanvasEvents['mouse:up']) => {
      if (e.transform?.action === 'scale') {
        element.editable = true;
        const scale = element.scaleX ?? 1;
        element.set({
          scaleX: 1,
          scaleY: 1,
          width: (element.width ?? 0) * scale,
          height: (element.height ?? 0) * scale,
          fontSize: (element.fontSize ?? 0) * scale,
        });
        onModified?.();
      }
    },
    [element],
  );

  const onElEditingEntered = useCallback(() => {
    if (maxCharLimit !== undefined) {
      element.hiddenTextarea?.setAttribute('maxlength', maxCharLimit.toString());
    }
    onEditingEntered?.();
  }, [maxCharLimit, element, onEditingEntered]);
  useEvent(element, 'mouseup', onMouseUpScale);

  useEvents(element, customEvents);
  useEvent(element, 'mouseover', onMouseOver);
  useEvent(element, 'mouseout', onMouseOut);
  useEvent(element, 'mousedown', onMouseDown);
  useEvent(element, 'mousemove', onMouseMove);
  useEvent(element, 'mouseup', onMouseUp);
  useEvent(element, 'modified', onModified);
  useEvent(element, 'changed', onChanged);
  useEvent(element, 'editing:entered', onElEditingEntered);
  useEvent(element, 'editing:exited', onEditingExited);

  // Manually fire event to update text path
  useLayoutEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }
    element.fire('alignChanged' as any);
  }, [fabricProps.textAlign]);

  // to be done only once per element
  useLayoutEffect(() => {
    element.setWrappingMode(wrapping && !element.curve ? wrapping : TextWrapping.Wrap);
    element.setCurveControlsVisibility(!!element.curve);
  }, [element, wrapping, !!element.curve]);

  // to be done only once per element
  useLayoutEffect(() => {
    setCursors(element);
  }, [element]);

  useObjectUpdate(fabricCanvas, element);

  return null;
}

export default React.memo(React.forwardRef(FabricTextInputComponent));
