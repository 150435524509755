import { FabricObject } from 'fabric';
import { TOriginY } from 'fabric/src/typedefs';
import { Polygon } from 'polygon-clipping';

import { LineEdge } from 'editor/src/store/design/types';

import CustomFabricPath from 'editor/src/fabric/CustomFabricPath';

import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import {
  getArrowBoundingRect,
  getLineEdgePath,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line/utils';

const createLineEdge = (
  strokeWidth: number,
  stroke: string,
  left: number,
  top: number,
  angle: number,
  zIndex: number,
  edge: LineEdge,
  contentClipPolygons: Polygon[],
  contentClipPath: FabricObject | undefined,
) => {
  const edgePath = getLineEdgePath(strokeWidth, left, top);
  const clipPath = getClipPath(
    getArrowBoundingRect(strokeWidth, angle, left, top),
    contentClipPolygons,
    false,
    contentClipPath,
  );
  const arrowProps = {
    left,
    top,
    stroke,
    strokeWidth,
    clipPath,
    angle,
    zIndex,
    evented: false,
    strokeLineCap: 'round' as CanvasLineCap,
    strokeLineJoin: 'round' as CanvasLineJoin,
    originY: 'center' as TOriginY,
    objectCaching: false,
  };

  switch (edge) {
    case 'arrow':
      return new CustomFabricPath(edgePath, { ...arrowProps, fill: 'transparent' });
    case 'arrow-fill':
      return new CustomFabricPath(`${edgePath} Z`, { ...arrowProps, fill: stroke });
    default:
      return null;
  }
};

export default createLineEdge;
