import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import updateAllGridElementsOperation from 'editor/src/store/design/operation/calendarGrid/updateAllGridElementsOperation';
import { MediaGrid } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import getLocaleList from 'editor/src/util/getLocaleList';
import { browserLocaleToEditorLocale } from 'editor/src/util/locales';

import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';
import Select, { SelectOption } from 'editor/src/component/Select';

import controlStyles from '../ProductControls/ProductControl.module.scss';

interface Props {
  gridElement: MediaGrid;
}

function LocaleControl({ gridElement }: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { locale } = gridElement.grid;

  const updateLocale = (locale: string) => {
    void downloadLokalizeLanguage(locale).then(() => dispatch(updateAllGridElementsOperation({ locale })));
  };

  useEffect(() => {
    locale !== i18n.language && updateLocale(locale);
  }, []);

  const downloadLokalizeLanguage = (language: string = 'en') => {
    const i18nInstance = i18n.cloneInstance();
    return i18nInstance.changeLanguage(browserLocaleToEditorLocale[language]);
  };

  const onSelect = useCallback((option: SelectOption) => {
    const selectedOption = options.find(({ value }) => value === option.value);
    if (selectedOption) {
      updateLocale(selectedOption.value);
    }
  }, []);

  const options = useMemo(() => getLocaleList(i18n), [i18n.language]);

  const selectedOption = useMemo(() => options.find(({ value }) => value === locale), [options, locale]);

  return (
    <>
      <PropertySeparator bigMargin />
      <div className={controlStyles.controlTitleContainer}>
        <div className={controlStyles.controlTitle}>{t('language')}</div>
      </div>
      <Select
        options={options}
        onSelect={onSelect}
        selected={[selectedOption?.value || 0]}
        className="cy-grid-locale"
      />
    </>
  );
}

export default React.memo(LocaleControl);
