import cn from 'classnames';
import React from 'react';

import H1 from 'editor/src/component/Header/H1';
import ButtonClose from 'editor/src/component/SidebarMobile/ButtonClose';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  title: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  closeBtnClassName?: string;
  showCloseBtn?: boolean;
  closeBtnShowTabIndex?: number;
}

function TabContentHeader({
  icon,
  title,
  children,
  className,
  closeBtnClassName,
  showCloseBtn,
  closeBtnShowTabIndex,
}: Props) {
  const isMobile = useIsMobile();
  return (
    <div className={cn(styles.TabContentHeader, className)}>
      <div className={styles.headerWrapper}>
        {icon}
        <H1 noMargin noPadding className={styles.header}>
          {title}
        </H1>
      </div>
      {children}
      {(isMobile || showCloseBtn) && (
        <div className={cn(styles.close, closeBtnClassName)}>
          <ButtonClose closeBtnShowTabIndex={closeBtnShowTabIndex} />
        </div>
      )}
    </div>
  );
}

export default React.memo(TabContentHeader);
