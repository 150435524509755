import { util as fabricNativeUtils, Point as FabricPoint } from 'fabric';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { checkIfRectangleMostlyNotVisibleInSpread } from 'editor/src/store/editorModules/warnings/utils/getNotVisibleWarning';

import CustomFabricRect from 'editor/src/fabric/CustomFabricRect';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation, VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';
import useSpreadDisplay from 'editor/src/component/EditorArea/useSpreadDisplay';

import { ObjectRect } from './Image/types';

const BLUE = '#27e9e8';
const SIGNAL_ORANGE = '#D48C06';

function useHoverBox(
  object: ObjectRect,
  isMobile: boolean,
  disable: boolean,
  canvasRotation: CanvasRotation,
  color = BLUE,
) {
  const rectRef = useRef<CustomFabricRect>(null);
  const fabricCanvas = useFabricCanvas();
  const spreadInfo = useSpreadDisplay();

  const unrotatedObject = useMemo(() => {
    const coords = fabricNativeUtils.rotatePoint(
      new FabricPoint(object.left, object.top),
      canvasRotation.canvasCenter,
      -canvasRotation.angleRad,
    );
    return {
      ...object,
      left: coords.x,
      top: coords.y,
      angle: object.angle - canvasRotation.angleDeg,
    };
  }, [object, canvasRotation]);

  const isHidden = useMemo(
    () =>
      !checkIfRectangleMostlyNotVisibleInSpread(
        unrotatedObject,
        { x: spreadInfo.spreadCoords.left, y: spreadInfo.spreadCoords.top },
        spreadInfo.spreadWidth,
        spreadInfo.spreadHeight,
      ),
    [spreadInfo, unrotatedObject],
  );

  const stroke = isHidden ? SIGNAL_ORANGE : color;

  useEffect(() => {
    if (disable) {
      return undefined;
    }
    function onViewPortTransform() {
      rectRef.current?.set('strokeWidth', 1 / fabricCanvas.getZoom());
    }
    fabricCanvas.on(VIEWPORT_CHANGED_EVENT as any, onViewPortTransform);
    return () => {
      fabricCanvas.off(VIEWPORT_CHANGED_EVENT as any, onViewPortTransform);
    };
  }, [fabricCanvas, disable]);

  const onMouseOver = useCallback(() => {
    if (!isMobile) {
      rectRef.current?.set({
        visible: true,
        strokeWidth: 1 / fabricCanvas.getZoom(),
        stroke: color,
      });
      fabricCanvas.requestRenderAll();
    }
  }, [isMobile, color]);

  const onMouseOut = useCallback(() => {
    if (!isMobile) {
      rectRef.current?.set({ visible: isHidden, stroke });
      fabricCanvas.requestRenderAll();
    }
  }, [isMobile, isHidden, stroke]);

  const render = () =>
    !disable && (
      <FabricRectComponent
        angle={object.angle}
        left={object.left}
        top={object.top}
        height={object.height}
        width={object.width}
        strokeWidth={1 / fabricCanvas.getZoom()}
        stroke={stroke}
        evented={false}
        zIndex={zIndex.HOVER_BOX}
        fill=""
        visible={isHidden}
        ref={rectRef}
        objectCaching={false}
      />
    );

  return { onMouseOver, onMouseOut, render };
}

export default useHoverBox;
