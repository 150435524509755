import { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import doesSpreadHasMissingImages from 'editor/src/store/design/selector/doesSpreadHasMissingImages';
import { Spread } from 'editor/src/store/design/types';
import loadFontOperation from 'editor/src/store/fonts/operation/loadFontOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { createSpreadPreview, RequestRenderFn, SpreadPreviewDataURL } from 'editor/src/component/SpreadPreview';
import { PreviewOutput, SpreadPreviewOptions } from 'editor/src/component/SpreadPreview/createSpreadPreview';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

function useSpreadTemplatePreview<Type extends PreviewOutput = 'dataURL'>(
  requestRender: RequestRenderFn,
  spread: Spread | undefined,
  designKey: string,
  spreadIndex: number,
  size: { dimension: 'height' | 'width' | 'both'; value: number },
  options: SpreadPreviewOptions<Type>,
  cachedPreviewUrl: string | undefined,
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { images, addons } = useSelector((state) => {
    return {
      images: state.gallery.images,
      addons: state.editorModules.addons.inUse,
    };
  }, shallowEqual);
  const [preview, setPreview] = useState<SpreadPreviewDataURL>();

  const hasMissingImages = useMemo(() => {
    // don't make calculations if cachedPreviewUrl already exists
    if (cachedPreviewUrl) {
      return false;
    }

    return doesSpreadHasMissingImages(spread, images);
  }, [spread, images, cachedPreviewUrl]);

  function loadFont(fontFamily: string) {
    return dispatch(loadFontOperation(fontFamily));
  }

  const version = useRef(0);
  const currentVersion = version.current;
  useMemo(() => {
    if (cachedPreviewUrl) {
      setPreview({
        type: 'dataURL',
        dataURL: cachedPreviewUrl,
        // calculate dimensions based on template if needed
        width: 0,
        height: 0,
      });
      return;
    }

    if (!spread) {
      return;
    }

    version.current += 1;

    void createSpreadPreview(
      designKey,
      spread,
      {
        backgroundImage: undefined,
        foregroundImage: undefined,
        gridDesigns: [],
        images,
        addons,
      },
      spreadIndex,
      undefined,
      size,
      requestRender,
      {},
      loadFont,
      isMobile,
      t,
      options,
    ).then((renderedPreview) => {
      if (renderedPreview && currentVersion + 1 === version.current) {
        if (renderedPreview.type === 'dataURL') {
          setPreview(renderedPreview);
        }
      }
    });
  }, [spread, hasMissingImages, addons, designKey, cachedPreviewUrl]);

  return { preview, isRendering: currentVersion !== version.current };
}

export default useSpreadTemplatePreview;
