import cn from 'classnames';
import React from 'react';

import IconCheck from 'editor/src/component/Icon/IconCheck';

import styles from './index.module.scss';

interface Props {
  label: string | React.ReactNode;
  on: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  type?: string;
  className?: string;
  previewImg?: string;
  description?: string;
}

function RadioButton({ label, on, onChange, disabled, type, className, previewImg, description }: Props) {
  const isSmallPreview = previewImg?.includes('svg');
  const handleClick = () => {
    if (!disabled) {
      onChange(!on);
    }
  };

  const labelBlock = (
    <div className={styles.labelWrapper}>
      {label}
      {description && <div className={cn(styles.description)}>{description}</div>}
    </div>
  );

  return (
    <div
      className={cn(styles.radio, className, {
        [styles.disabled]: disabled,
        [styles.radioButtonElement]: type === 'button',
        'cy-disabled': disabled,
        [styles.active]: on,
        [styles.withPreview]: previewImg,
        [styles.isSmallPreview]: isSmallPreview,
      })}
      onClick={handleClick}
    >
      <div className={styles.labelWithPreview}>
        {isSmallPreview && <img src={previewImg} alt="option-preview" />}
        {labelBlock}
      </div>
      {on && (!previewImg || isSmallPreview) && <IconCheck className={styles.check} />}
      {previewImg && !isSmallPreview && <img className={styles.langePreview} src={previewImg} alt="option-preview" />}
    </div>
  );
}

export default RadioButton;
