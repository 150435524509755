import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'editor/src/component/Checkbox';
import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';

import styles from './index.module.scss';

function CheckboxElement({
  option,
  selected,
  disabled,
  toggle,
  control,
  unavailable,
  selectedCount,
}: ControlOptionProps) {
  const toggleCb = useCallback(() => toggle(control, option), [control, option]);

  const { previewImage, subtitle } = option.optionDetails || {};

  // we should not allow deselect single selected option. It breaks the editor as we can't find design structure
  const isSingleSelectedOption = useMemo(() => {
    return selectedCount !== undefined && selectedCount === 1 && selected;
  }, [selectedCount, selected]);

  const canToggle = !isSingleSelectedOption && (!disabled || (!!unavailable && selected));
  const { t } = useTranslation();

  const className = cn(styles.CheckboxElement, `cy-checkbox-${option.value}`, {
    [styles.active]: selected,
    [styles.enabled]: !disabled,
    'cy-active': selected,
  });

  const labelBlock = (
    <div className={styles.labelWrapper}>
      <div className={styles.labelBlock}>
        {option.title}
        {subtitle && <div className={cn(styles.description)}>{subtitle}</div>}
      </div>
      {previewImage && <img src={previewImage} alt="option-preview" />}
    </div>
  );

  const checkbox = (alone: boolean) => {
    return (
      <Checkbox
        className={cn('cy-product-option-checkbox', { [className]: alone, [styles.withPreview]: previewImage })}
        label={labelBlock}
        on={selected}
        disabled={!canToggle}
        onChange={toggleCb}
      />
    );
  };

  if (unavailable && selected) {
    return (
      <div className={className}>
        {checkbox(false)}
        {unavailable.map((unavailableMessage, index) => (
          <div key={index} className={styles.unavailable}>
            {t(unavailableMessage)}
          </div>
        ))}
      </div>
    );
  }

  return checkbox(true);
}

export default React.memo(CheckboxElement);
