import React from 'react';
import { useTranslation } from 'react-i18next';

import hasSidebarTab from 'editor/src/store/editorModules/sidebar/selector/hasSidebarTab';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useSelector } from 'editor/src/store/hooks';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconHelp from 'editor/src/component/Icon/IconHelp';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
}

function HelpCenterTab({ pluginName, customTabInfo }: Props) {
  const { t } = useTranslation();
  const active = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.HELP_CENTER));
  const canShowHelpCenter = useSelector(
    (state) =>
      hasSidebarTab(state, TAB_NAMES.HELP_CENTER) &&
      (state.hostSettings.enableHelpCenter?.articles || state.hostSettings.enableHelpCenter?.tutorials),
  );

  return canShowHelpCenter ? (
    <Tab
      key={TAB_NAMES.HELP_CENTER}
      name={TAB_NAMES.HELP_CENTER}
      title={t('Help')}
      icon={<IconHelp active={active} />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
    />
  ) : null;
}

export default React.memo(HelpCenterTab);
