/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconArrowRight({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 7L7.75735 11.2426M12 7L7.75735 2.75736M12 7L1.99994 7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default IconArrowRight;
