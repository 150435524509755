import cloneDeep from 'lodash/cloneDeep';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setDesignDataAction } from 'editor/src/store/design/slice';
import { DesignData, MediaElement, ReflectDesignDataConfig } from 'editor/src/store/design/types';
import updateResizableLayoutAreaOperation from 'editor/src/store/editor/operation/resizableElement/updateResizableLayoutAreaOperation';
import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import { GalleryImage } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { Plugin, PluginName } from 'editor/src/store/plugins/types';
import { UNSELECTED_PAGE_COUNT } from 'editor/src/store/variants/reducer/setPageCountReducer';
import getResizableProductForGivenVariant from 'editor/src/store/variants/selector/getResizableProductForGivenVariant';
import { getMediaPlugins } from 'editor/src/store/watchers/checkPluginUse/getAppliedPlugins';

import { InboundPostMessage } from 'editor/src/util/postMessages/messages';
import reflectDesignData from 'editor/src/util/reflectDesignData';
import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';

import autoApplyLayoutSchemasToDesignDataOperation from './autoApplyLayoutSchemasToDesignDataOperation';

const applyDesignDataToOperation =
  ({ design: designData, reflectPreviousDesignConfig, force }: InboundPostMessage['design.applyDesignDataTo']) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    let state = getState();

    // backend sends wrong data ? let's not set it..
    if (!designData.spreads) {
      throw new Error('no spreads in the design data?');
    }

    if (designData.spreads.some((spread) => spread.pages.length === 0)) {
      throw new Error('no pages in the spreads?');
    }

    const { selectedPageCount, selectedProductUid } = state.variants;
    const pageCount = designData.page_count;
    if (pageCount && selectedPageCount !== UNSELECTED_PAGE_COUNT && designData.page_count !== selectedPageCount) {
      // eslint-disable-next-line no-console
      console.error(`incorrect design for page count: ${designData.page_count}`);
      return;
    }

    if (!force && selectedProductUid && designData.product_uid !== selectedProductUid) {
      // eslint-disable-next-line no-console
      console.error(`design product uid ${designData.product_uid} does not match selected one: ${selectedProductUid}`);
      return;
    }

    const originalDesign = state.design.designData;
    if (!originalDesign) {
      throw new Error('no current design loaded');
    }

    batch(() => {
      const productResized =
        !!originalDesign.dimensions && !!designData.dimensions && originalDesign.product_uid === designData.product_uid;
      const reflectMode = productResized ? 'reset' : 'adapt';
      const plugins = state.plugins.list;
      const sourceDesign = reflectPreviousDesignConfig
        ? getOriginalDesignWithFilteredMedia(originalDesign, reflectPreviousDesignConfig, plugins, state.gallery.images)
        : originalDesign;

      const reflectedDesignData = reflectDesignData(sourceDesign, designData, getReflectContext(state), reflectMode);
      if (state.editor.currentSpreadIndex > reflectedDesignData.spreads.length - 1) {
        dispatch(setCurrentSpreadIndexOperation(0));
      }
      dispatch(setDesignDataAction(reflectedDesignData));

      dispatch(autoApplyLayoutSchemasToDesignDataOperation());

      state = getState();
      const resizableElement = getResizableProductForGivenVariant(state);
      if (resizableElement && designData?.related_dimensions) {
        dispatch(updateResizableLayoutAreaOperation(resizableElement, designData?.related_dimensions));
      }
    });
  };

const getOriginalDesignWithFilteredMedia = (
  sourceDesign: DesignData,
  reflectConfig: ReflectDesignDataConfig,
  plugins?: Plugin[],
  images?: GalleryImage[],
) => {
  const newDesign = cloneDeep(sourceDesign);

  newDesign.spreads.forEach((sourceSpread) => {
    const sourceFirstPage = sourceSpread.pages[0];
    if (!sourceFirstPage.groups.media) {
      return;
    }

    const media = reflectConfig.resetMedia
      ? []
      : getFilteredMedia(sourceFirstPage.groups.media, reflectConfig, plugins, images);

    sourceSpread.pages.forEach((sourcePage) => {
      sourcePage.groups.media = media;
    });
  });

  return newDesign;
};

const getFilteredMedia = (
  originalMedia: MediaElement[],
  reflectConfig: ReflectDesignDataConfig,
  plugins: Plugin[] | undefined,
  images: GalleryImage[] | undefined,
) => {
  if (!reflectConfig?.ignore) {
    return originalMedia;
  }

  let sourceMedia = originalMedia;
  if (reflectConfig.ignore.elementTypes.length) {
    sourceMedia = originalMedia.filter(
      (sourceElement) => !reflectConfig?.ignore?.elementTypes.includes(sourceElement.type),
    );
  }

  if (reflectConfig.ignore.plugins.length && plugins?.length && images) {
    const pluginsMap = plugins.reduce((map, plugin) => {
      if (reflectConfig?.ignore?.plugins.includes(plugin.name)) {
        map.set(plugin.name, plugin);
      }
      return map;
    }, new Map<PluginName | string, Plugin>());

    sourceMedia = sourceMedia.filter((sourceElement) => getMediaPlugins(pluginsMap, images, sourceElement).size === 0);
  }

  return sourceMedia;
};

export default applyDesignDataToOperation;
