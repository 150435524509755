import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setShowRealisticPreviewAction } from 'editor/src/store/app/slice';
import { WhiteUnderbaseWarning } from 'editor/src/store/editorModules/warnings/types';
import { useSelector, useDispatch } from 'editor/src/store/hooks';

import AbstractWarningItem from 'editor/src/component/DesktopSidebar/TabContents/WarningsTabContent/WarningItem/AbstractWarningItem';
import SwitchButton from 'editor/src/component/SwitchButton';

import styles from './index.module.scss';

interface Props {
  warning: WhiteUnderbaseWarning;
}

function WhiteUnderbaseWarningItem({ warning }: Props) {
  const { t } = useTranslation();
  const showRealisticPreview = useSelector((state) => state.app.showRealisticPreview);
  const [expanded, setExpanded] = useState<boolean>(true);
  const dispatch = useDispatch();

  const toggleWhiteUnderbaseMode = () => {
    dispatch(setShowRealisticPreviewAction(!showRealisticPreview));
  };

  const handleExpandedState = () => {
    setExpanded(!expanded);
  };

  return (
    <div onClick={handleExpandedState}>
      <AbstractWarningItem warning={warning} selected={expanded} title={t('Your print may look different')}>
        <div>{t('white-underbase-warning-description')}</div>
        <div className="my-1">
          <a
            href="https://support.gelato.com/en/articles/8996612-the-colors-on-my-printed-garment-don-t-match-my-file"
            className={styles.linkButton}
            target="_blank"
            rel="noreferrer"
          >
            {t('Learn more')}
          </a>
        </div>
        <div className={styles.switchButtonContainer}>
          <SwitchButton
            className={cn(styles.switchButton, 'cy-white-underbase-toggle')}
            onSwitch={toggleWhiteUnderbaseMode}
            on={showRealisticPreview}
            stopPropagation
          />
          <span>{t('Show realistic preview')}</span>
        </div>
      </AbstractWarningItem>
    </div>
  );
}

export default React.memo(WhiteUnderbaseWarningItem);
