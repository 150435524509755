import React from 'react';

import { ContentAddress, Coords, ElementSize, Page, Spread } from 'editor/src/store/design/types';

import hasLineIntersection from 'editor/src/util/hasLineIntersection';
import hasRectIntersection from 'editor/src/util/hasRectIntersection';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import EmptyContentPlaceholder from './EmptyContentPlaceholder';

interface Props {
  page: Page;
  spread: Spread;
  coords: Coords;
  canvasRotation: CanvasRotation;
  contentAddress?: ContentAddress;
}

const CONTENT_NAME_TO_IGNORE = new Set(['Content Sides', 'Content Side']); // for canvas and phonecase

function EmptyPagePlaceholder({ page, spread, coords, contentAddress, canvasRotation }: Props) {
  const isPageEmpty = (page: Page, spread: Spread) => {
    let { media } = page.groups;
    // if the media is taken from any other page we should take them from the first one
    if (page !== spread.pages[0]) {
      media = spread.pages[0].groups.media;
    }

    const isEmpty = !media?.some((element) => {
      if (element.type !== 'line') {
        return hasRectIntersection(
          {
            left: element.x,
            top: element.y,
            width: element.width,
            height: element.height,
            angle: element.r,
          },
          { x: page.x, y: page.y },
          page.width,
          page.height,
        );
      }
      return hasLineIntersection(
        { x: element.x1, y: element.y1 },
        { x: element.x2, y: element.y2 },
        { x: page.x, y: page.y },
        page.width,
        page.height,
      );
    });

    return isEmpty;
  };

  const content = page?.groups.content;
  let focusedContent: ElementSize = page as ElementSize;
  if (contentAddress && contentAddress.pageIndex === page.page_nr) {
    focusedContent = content?.[contentAddress.contentIndex] as ElementSize;
  } else if (spread.pages.length > 1) {
    focusedContent = content?.find(
      (content) => content.type !== 'sample' && !CONTENT_NAME_TO_IGNORE.has(content.name),
    ) as ElementSize;
  }

  if (!isPageEmpty(page, spread) || !focusedContent) {
    return null;
  }

  return (
    <EmptyContentPlaceholder
      key={page.page_nr}
      content={focusedContent}
      page={page}
      spreadCoords={coords}
      canvasRotation={canvasRotation}
    />
  );
}

export default React.memo(EmptyPagePlaceholder);
