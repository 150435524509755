import { setTourPrintAreasAction } from 'editor/src/store/editorModules/tour/slice';
import { HighlightDesignAreaData } from 'editor/src/store/editorModules/tour/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const setElementsToHighlightOperation =
  (elementsToHighlight: { printAreas: HighlightDesignAreaData }) => (dispatch: ThunkDispatch) => {
    dispatch(setTourPrintAreasAction(elementsToHighlight.printAreas));
  };

export default setElementsToHighlightOperation;
