import { Transform as FabricTransform, FabricObject, FabricImage, CanvasEvents } from 'fabric';
import { Polygon } from 'polygon-clipping';
import { RefObject, useCallback, useRef, useState } from 'react';

import { ImagePattern } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { DpiLevels } from 'editor/src/store/hostSettings/types';

import CustomFabricRect from 'editor/src/fabric/CustomFabricRect';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import { setClipPath } from 'editor/src/component/EditorArea/fabricComponents/fabricUtils';
import fabricElementToObjectRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/fabricElementToObjectRect';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import { ImageTransformFn } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/transformUtils';
import { WarningIconInterface } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/WarningIcon';
import getWarningIconDimensions from 'editor/src/component/EditorArea/Spread/Page/MediaElement/WarningIcon/getWarningIconDimensions';

import getEmptyImageDimensions from './getEmptyImageDimensions';
import getImageClipPath from './getImageClipPath';
import { Ref as ShadowRef } from './ImageShadow';
import isTouchEvent from './isTouchEvent';
import { ObjectRect } from './types';
import updateBoundingBoxColors from './updateBoundingBoxColors';
import updatePatternFilter from './updatePatternFilter';
import getImageUpdateOnDrag from './updateUtils/getImageUpdateOnDrag';
import getImageUpdateOnResize from './updateUtils/getImageUpdateOnResize';
import getImageUpdateOnRotate from './updateUtils/getImageUpdateOnRotate';
import getImageUpdateOnScale from './updateUtils/getImageUpdateOnScale';
import updateOnImageGhostChange from './updateUtils/updateOnImageGhostChange';

const imageTransformAction: {
  [A in Exclude<FabricTransform['action'], undefined>]: ImageTransformFn;
} = {
  drag: getImageUpdateOnDrag,
  rotate: getImageUpdateOnRotate,
  scaleX: getImageUpdateOnScale,
  scaleY: getImageUpdateOnScale,
  scale: getImageUpdateOnResize,
};

const EMPTY_OBJECT = new FabricObject({ objectCaching: false });

function getEventPosition(e: Event) {
  if (isTouchEvent(e)) {
    return { clientX: e.touches[0].clientX, clientY: e.touches[0].clientY };
  }

  if (e instanceof MouseEvent) {
    return { clientX: e.clientX, clientY: e.clientY };
  }

  return { clientX: 0, clientY: 0 };
}

function useFrameManipulation(
  contentClipPolygons: Polygon[],
  currentRect: ObjectRect,
  isLocked: boolean,
  frameRef: RefObject<CustomFabricRect>,
  imageRef: RefObject<FabricImage>,
  emptyImageRef: RefObject<FabricObject>,
  ghostRef: RefObject<FabricImage>,
  warningRef: RefObject<WarningIconInterface>,
  galleryImage: GalleryImage | undefined,
  limits: DpiLevels | undefined,
  pattern: ImagePattern | undefined,
  onUpdate: (action: 'move' | 'resize') => void,
  shadowRef: RefObject<ShadowRef>,
  isSample: boolean,
  contentClipPath: FabricObject | undefined,
) {
  const fabricCanvas = useFabricCanvas();
  const frameOriginalRef = useRef(EMPTY_OBJECT);
  const imageOriginalRef = useRef(EMPTY_OBJECT);
  const [isMovingGhost, setIsMovingGhost] = useState(false);
  const lastPosition = useRef({ clientX: 0, clientY: 0 });
  const { px2mm } = useFabricUtils();

  const onMouseMove = useCallback(
    (event: CanvasEvents['mouse:move']) => {
      if (!event.transform || !frameRef.current) {
        return;
      }
      frameRef.current.setCoords();
      const frameRect = fabricElementToObjectRect(frameRef.current, currentRect);

      const transform = event.transform as any as FabricTransform;
      if (isLocked) {
        if (pattern) {
          return;
        }

        if (!isMovingGhost) {
          lastPosition.current = getEventPosition(event.e);
          setIsMovingGhost(true);
        }

        if (transform.action === 'drag' && ghostRef.current) {
          const pos = getEventPosition(event.e);
          ghostRef.current.set({
            left: (ghostRef.current.left ?? 0) + (pos.clientX - lastPosition.current.clientX),
            top: (ghostRef.current.top ?? 0) + (pos.clientY - lastPosition.current.clientY),
          });
          lastPosition.current = pos;

          updateOnImageGhostChange(
            frameRef,
            ghostRef,
            imageRef,
            event,
            false,
            px2mm,
            galleryImage,
            limits,
            pattern,
            onUpdate,
          );
          fabricCanvas.requestRenderAll();
        }
        return;
      }

      if (imageRef.current && transform.action) {
        const actionFn = imageTransformAction[transform.action];
        if (actionFn) {
          imageRef.current.set(
            actionFn(transform, frameRef.current, frameOriginalRef.current, imageOriginalRef.current),
          );
        }

        const imageRect = fabricElementToObjectRect(imageRef.current, currentRect);
        const imageClipPath = getImageClipPath(frameRect, imageRect, contentClipPolygons, false, contentClipPath);
        setClipPath(imageRef.current, imageClipPath);
        (imageRef.current as any).frameRect = frameRect;

        updateBoundingBoxColors(imageRef.current, frameRef.current, ghostRef, px2mm, galleryImage, limits, pattern);
        updatePatternFilter(imageRef.current, frameRef.current, pattern, px2mm);
      }

      if (emptyImageRef.current) {
        emptyImageRef.current.set(getEmptyImageDimensions(emptyImageRef.current, frameRect, 0.4));
        const clippath = getClipPath(frameRect, contentClipPolygons, false, contentClipPath);
        setClipPath(emptyImageRef.current, clippath);
        setClipPath(frameRef.current, clippath);
      }

      if (shadowRef.current) {
        shadowRef.current.udpate(frameRect);
      }

      if (warningRef.current) {
        warningRef.current.set(getWarningIconDimensions(frameRect, fabricCanvas.getZoom()));
      }
      onUpdate(transform.action === 'drag' ? 'move' : 'resize');
    },
    [contentClipPolygons, isLocked, isMovingGhost, currentRect, px2mm, galleryImage, limits, pattern],
  );

  const onMouseDown = useCallback(() => {
    if (frameRef.current) {
      frameOriginalRef.current = new FabricObject({
        ...frameRef.current,
        clipPath: undefined,
        objectCaching: false,
      });
    }

    if (imageRef.current) {
      imageOriginalRef.current = new FabricObject({
        ...imageRef.current,
        clipPath: undefined,
        objectCaching: false,
      });
    }
  }, []);

  const onMouseUp = useCallback(() => {
    frameOriginalRef.current = EMPTY_OBJECT;
    imageOriginalRef.current = EMPTY_OBJECT;
    if (isMovingGhost) {
      setIsMovingGhost(false);
    }
  }, [isMovingGhost]);

  return {
    isMovingGhost,
    onMouseDown,
    onMouseMove,
    onMouseUp,
  };
}

export default useFrameManipulation;
