import { Point } from './2d/types';

export function hasLineIntersection(
  { x: x1, y: y1 }: Point,
  { x: x2, y: y2 }: Point,
  spreadCoords: Point,
  spreadWidth: number,
  spreadHeight: number,
): boolean {
  const spreadRight = spreadCoords.x + spreadWidth;
  const spreadBottom = spreadCoords.y + spreadHeight;

  // Fast AABB Check (bounding box overlap)
  if (
    Math.max(x1, x2) < spreadCoords.x || // Line is completely left of spread
    Math.min(x1, x2) > spreadRight || // Line is completely right of spread
    Math.max(y1, y2) < spreadCoords.y || // Line is completely above spread
    Math.min(y1, y2) > spreadBottom // Line is completely below spread
  ) {
    return false;
  }

  // **Early Exit if Either Endpoint is Inside Spread**
  if (
    (x1 >= spreadCoords.x && x1 <= spreadRight && y1 >= spreadCoords.y && y1 <= spreadBottom) ||
    (x2 >= spreadCoords.x && x2 <= spreadRight && y2 >= spreadCoords.y && y2 <= spreadBottom)
  ) {
    return true;
  }

  // **Check Line-Segment Intersection with Spread Edges**
  const spreadEdges: [Point, Point][] = [
    [
      { x: spreadCoords.x, y: spreadCoords.y },
      { x: spreadRight, y: spreadCoords.y },
    ], // Top
    [
      { x: spreadRight, y: spreadCoords.y },
      { x: spreadRight, y: spreadBottom },
    ], // Right
    [
      { x: spreadRight, y: spreadBottom },
      { x: spreadCoords.x, y: spreadBottom },
    ], // Bottom
    [
      { x: spreadCoords.x, y: spreadBottom },
      { x: spreadCoords.x, y: spreadCoords.y },
    ], // Left
  ];

  return spreadEdges.some(([p1, p2]) => lineSegmentIntersection(x1, y1, x2, y2, p1.x, p1.y, p2.x, p2.y));
}

/**
 * Checks if two line segments (p1-p2 and p3-p4) intersect.
 */
function lineSegmentIntersection(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number,
): boolean {
  function crossProduct(ax: number, ay: number, bx: number, by: number) {
    return ax * by - ay * bx;
  }

  function isBetween(a: number, b: number, c: number) {
    return Math.min(a, b) <= c && c <= Math.max(a, b);
  }

  const d1 = crossProduct(x4 - x3, y4 - y3, x1 - x3, y1 - y3);
  const d2 = crossProduct(x4 - x3, y4 - y3, x2 - x3, y2 - y3);
  const d3 = crossProduct(x2 - x1, y2 - y1, x3 - x1, y3 - y1);
  const d4 = crossProduct(x2 - x1, y2 - y1, x4 - x1, y4 - y1);

  if (d1 * d2 < 0 && d3 * d4 < 0) {
    return true; // Proper intersection
  }

  // Check for collinearity & overlapping segments
  if (d1 === 0 && isBetween(x3, x4, x1) && isBetween(y3, y4, y1)) {
    return true;
  }
  if (d2 === 0 && isBetween(x3, x4, x2) && isBetween(y3, y4, y2)) {
    return true;
  }
  if (d3 === 0 && isBetween(x1, x2, x3) && isBetween(y1, y2, y3)) {
    return true;
  }
  if (d4 === 0 && isBetween(x1, x2, x4) && isBetween(y1, y2, y4)) {
    return true;
  }

  return false;
}

export default hasLineIntersection;
