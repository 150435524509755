import { util as fabricNativeUtils, Point as FabricPoint } from 'fabric';

import applyToSelectedMediaOperation from 'editor/src/store/design/operation/applyToSelectedMediaOperation';
import { MediaLine } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { getLineAngle } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line/utils';

const applyLineRoundedToSelectionOperation = (rounded: MediaLine['rounded']) => (dispatch: ThunkDispatch) => {
  dispatch(
    applyToSelectedMediaOperation((element) => {
      if (element.type !== 'line') {
        return undefined;
      }

      const elementUpdate: Partial<MediaLine> = { rounded };
      const { x1, x2, y1, y2, strokeWidth } = element;
      const angle = getLineAngle(x1, x2, y1, y2);
      const lineCapOffset = fabricNativeUtils.rotatePoint(
        new FabricPoint(strokeWidth / 2, 0),
        new FabricPoint(0, 0),
        fabricNativeUtils.degreesToRadians(angle),
      );

      if (rounded) {
        // if rounded corners subtract lineCapOffset from the coordinates
        elementUpdate.x1 = x1 + lineCapOffset.x;
        elementUpdate.y1 = y1 + lineCapOffset.y;
        elementUpdate.x2 = x2 - lineCapOffset.x;
        elementUpdate.y2 = y2 - lineCapOffset.y;
      } else {
        // if flat corners add lineCapOffset to the coordinates
        elementUpdate.x1 = x1 - lineCapOffset.x;
        elementUpdate.y1 = y1 - lineCapOffset.y;
        elementUpdate.x2 = x2 + lineCapOffset.x;
        elementUpdate.y2 = y2 + lineCapOffset.y;
      }

      return elementUpdate;
    }),
  );
};

export default applyLineRoundedToSelectionOperation;
