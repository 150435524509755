import { util as fabricNativeUtils, Point as FabricPoint } from 'fabric';
import cloneDeep from 'lodash/cloneDeep';

import { Coords, MediaElement, MediaLine, Page, Rect } from 'editor/src/store/design/types';

import { Point, Segment } from 'editor/src/util/2d/types';

import { roundToDecimals } from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/utils';
import {
  CONTROL_POINT_RADIUS,
  END_POINT_INCREMENT,
  START_POINT_INCREMENT,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line';
import getLineWidth, {
  getLineAngle,
  getLineCapOffset,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line/utils';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

export type SnapType = 'bbox' | 'canva' | 'bleed' | 'pointSnap';

export type Snap = BBoxSnap | CanvaSnap | BleedSnap | PointSnap;

interface SnapCommon {
  slope: number;
  slopeVal: string;
  isVertical: boolean;
  yIntercept: number;
  segment: Segment;
  tlElement: Point;
  distDenom: number;
  type: SnapType;
}

export interface BBoxSnap extends SnapCommon {
  type: 'bbox';
  uuid: number;
  snapPoint: Point;
}

export interface PointSnap extends SnapCommon {
  type: 'pointSnap';
  uuid: number;
  elementUuid: number;
  snapPoint: Point;
}
export interface CanvaSnap extends SnapCommon {
  type: 'canva';
}

export interface BleedSnap extends SnapCommon {
  type: 'bleed';
}

function createSnap(segment: [Point, Point], tlElement: Point, type: SnapType): SnapCommon {
  const dX = segment[0].x - segment[1].x;
  const isVertical = dX === 0;
  const slope = isVertical ? 0 : roundToDecimals((segment[0].y - segment[1].y) / dX, 5);

  return {
    isVertical,
    slopeVal: isVertical ? 'ver' : `${slope}`,
    slope,
    yIntercept: segment[0].y - slope * segment[0].x,
    segment: [segment[0], segment[1]],
    tlElement,
    distDenom: Math.sqrt(1 + slope * slope),
    type,
  };
}

export function createBBoxSnap(segment: [Point, Point], tlElement: Point, uuid: number, snapPoint: Point): BBoxSnap {
  const snap = createSnap(segment, tlElement, 'bbox') as BBoxSnap;
  snap.uuid = uuid;
  snap.snapPoint = snapPoint;
  return snap;
}

export function createPointSnap(
  segment: [Point, Point],
  tlElement: Point,
  uuid: number,
  elementUuid: number,
  snapPoint: Point,
): PointSnap {
  const snap = createSnap(segment, tlElement, 'pointSnap') as PointSnap;
  snap.uuid = uuid;
  snap.elementUuid = elementUuid;
  snap.snapPoint = snapPoint;
  return snap;
}

function createCanvasSnap(segment: [Point, Point], tlElement: Point): CanvaSnap {
  return createSnap(segment, tlElement, 'canva') as CanvaSnap;
}

function createBleedSnap(segment: [Point, Point], tlElement: Point): BleedSnap {
  return createSnap(segment, tlElement, 'bleed') as BleedSnap;
}

const createFunctions: {
  [key: string]: (segment: [Point, Point], tlElement: Point) => Snap;
} = {
  canva: createCanvasSnap,
  bleed: createBleedSnap,
};

function addSnapFromRect(
  snaps: Snap[],
  spreadCoords: Coords,
  mm2px: (size: number) => number,
  page: Page,
  canvasRotation: CanvasRotation,
  element: Rect,
  type: SnapType,
  addMiddleSnaps = true,
) {
  const contentWidth = mm2px(element.width);
  const contentHeight = mm2px(element.height);

  const contentLeft = spreadCoords.left + mm2px(page.x + element.x);
  const contentTop = spreadCoords.top + mm2px(page.y + element.y);
  const tl = fabricNativeUtils.rotatePoint(
    new FabricPoint(contentLeft, contentTop),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );
  const tr = fabricNativeUtils.rotatePoint(
    new FabricPoint(contentLeft + contentWidth, contentTop),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );
  const br = fabricNativeUtils.rotatePoint(
    new FabricPoint(contentLeft + contentWidth, contentTop + contentHeight),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );
  const bl = fabricNativeUtils.rotatePoint(
    new FabricPoint(contentLeft, contentTop + contentHeight),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );

  const mt = fabricNativeUtils.rotatePoint(
    new FabricPoint(contentLeft + contentWidth / 2, contentTop),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );
  const mb = fabricNativeUtils.rotatePoint(
    new FabricPoint(contentLeft + contentWidth / 2, contentTop + contentHeight),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );
  const ml = fabricNativeUtils.rotatePoint(
    new FabricPoint(contentLeft, contentTop + contentHeight / 2),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );
  const mr = fabricNativeUtils.rotatePoint(
    new FabricPoint(contentLeft + contentWidth, contentTop + contentHeight / 2),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );

  // canvas snaps
  snaps.push(createFunctions[type]([tl, tr], tl)); // top
  snaps.push(createFunctions[type]([tr, br], tl)); // right
  snaps.push(createFunctions[type]([bl, br], tl)); // bottom
  snaps.push(createFunctions[type]([tl, bl], tl)); // left

  if (addMiddleSnaps) {
    snaps.push(createFunctions[type]([mt, mb], tl)); // middle-ver
    snaps.push(createFunctions[type]([ml, mr], tl)); // middle-hor
  }
}

const mergeSnap = (leftSnap: Snap, rightSnap: Snap, axisKey: 'x' | 'y', snaps: Snap[], right: number) => {
  // deep clone the element before assignment so the nested objects get assign correctly
  const leftSnapClone = cloneDeep(leftSnap);
  // assign the minimum value to first segment and maximum value to second segment of the left snap.
  leftSnapClone.segment[0][axisKey] = Math.min(leftSnapClone.segment[0][axisKey], rightSnap.segment[0][axisKey]);
  leftSnapClone.segment[1][axisKey] = Math.max(leftSnapClone.segment[1][axisKey], rightSnap.segment[1][axisKey]);
  snaps.splice(right, 1);
  return leftSnapClone;
};

export const mergeSameLineSnaps = (snaps: Snap[]): void => {
  for (let left = 0; left < snaps.length; left += 1) {
    const leftSnap = snaps[left];
    for (let right = left + 1; right < snaps.length; right += 1) {
      const rightSnap = snaps[right];
      if (leftSnap && rightSnap) {
        if (leftSnap.slopeVal !== rightSnap.slopeVal) {
          // lines can't be merged, continue to check next snap line.
          continue;
        }
        if (rightSnap.isVertical) {
          // lines are vertical
          if (rightSnap.segment[0].x === leftSnap.segment[0].x) {
            snaps[left] = mergeSnap(leftSnap, rightSnap, 'y', snaps, right);
            break;
          }
        } else {
          // lines are not vertical
          // eslint-disable-next-line
          if (leftSnap.yIntercept === rightSnap.yIntercept) {
            snaps[left] = mergeSnap(leftSnap, rightSnap, 'x', snaps, right);
            break;
          }
        }
      }
    }
  }
};

export function getContentSnaps(
  spreadCoords: Coords,
  mm2px: (size: number) => number,
  pages: Page[] | undefined,
  canvasRotation: CanvasRotation,
) {
  const snaps: Snap[] = [];

  pages?.forEach((page) => {
    page.groups.content?.forEach((content) => {
      if (content.type !== 'area') {
        return;
      }
      addSnapFromRect(snaps, spreadCoords, mm2px, page, canvasRotation, content, 'canva');
    });
  });

  mergeSameLineSnaps(snaps);

  return snaps;
}

export function getBleedSnaps(
  spreadCoords: Coords,
  mm2px: (size: number) => number,
  pages: Page[] | undefined,
  canvasRotation: CanvasRotation,
) {
  const snaps: Snap[] = [];

  pages?.forEach((page) => {
    page.groups.bleed?.forEach((bleed) => {
      if (bleed.type !== 'border') {
        return;
      }
      addSnapFromRect(snaps, spreadCoords, mm2px, page, canvasRotation, bleed, 'bleed', false);
    });
  });
  return snaps;
}

export function getMediaboxSnaps(
  spreadCoords: Coords,
  mm2px: (size: number) => number,
  pages: Page[] | undefined,
  canvasRotation: CanvasRotation,
) {
  const snaps: Snap[] = [];

  pages?.forEach((page) => {
    page.groups.mediabox?.forEach((box) => {
      addSnapFromRect(snaps, spreadCoords, mm2px, page, canvasRotation, box, 'bleed', false);
    });
  });
  return snaps;
}

const addPointSnaps = (
  spreadCoords: Coords,
  mm2px: (size: number) => number,
  element: MediaLine,
  canvasRotation: CanvasRotation,
  snaps: Snap[],
) => {
  const { x1, y1, x2, y2 } = element;
  const angle = getLineAngle(x1, x2, y1, y2);
  const strokeWidthOffset = fabricNativeUtils.rotatePoint(
    new FabricPoint(mm2px(element.strokeWidth) / 2, 0),
    new FabricPoint(0, 0),
    fabricNativeUtils.degreesToRadians(angle + 90),
  );
  const lineCapOffset = getLineCapOffset(element.rounded, mm2px(element.strokeWidth), angle);

  const points = [
    {
      x: spreadCoords.left + mm2px(x1) + strokeWidthOffset.x - lineCapOffset.x,
      y: spreadCoords.top + mm2px(y1) + strokeWidthOffset.y - lineCapOffset.y,
      uuid: element.uuid + START_POINT_INCREMENT,
    },
    {
      x: spreadCoords.left + mm2px(x2) + strokeWidthOffset.x + lineCapOffset.x,
      y: spreadCoords.top + mm2px(y2) + strokeWidthOffset.y + lineCapOffset.y,
      uuid: element.uuid + END_POINT_INCREMENT,
    },
  ];

  points.forEach((point) => {
    const { x, y, uuid } = point;
    const tl = new FabricPoint(x, y);
    const tlElement = fabricNativeUtils.rotatePoint(tl, canvasRotation.canvasCenter, canvasRotation.angleRad);
    snaps.push(
      createPointSnap(
        [
          { x, y },
          { x, y },
        ],
        tlElement,
        uuid,
        element.uuid,
        tl, // ver
      ),
    );
    snaps.push(
      createPointSnap(
        [
          { x, y },
          { x: x + CONTROL_POINT_RADIUS, y },
        ],
        tlElement,
        uuid,
        element.uuid,
        tl, // hor
      ),
    );
  });
};

export function getElementSnaps(
  spreadCoords: Coords,
  mm2px: (size: number) => number,
  elements: MediaElement[],
  canvasRotation: CanvasRotation,
) {
  const snaps: Snap[] = [];

  elements.forEach((element) => {
    if (element.hidden) {
      return;
    }

    let elementX: number;
    let elementY: number;
    let elementWidth: number;
    let elementHeight: number;
    let elementAngle: number;

    if (element.type === 'line') {
      const { x1, y1, x2, y2, rounded } = element;
      addPointSnaps(spreadCoords, mm2px, element, canvasRotation, snaps);
      elementAngle = getLineAngle(x1, x2, y1, y2);
      elementX = x1;
      elementY = y1;
      elementWidth = getLineWidth(x1, x2, y1, y2);
      elementHeight = element.strokeWidth;
      elementAngle = getLineAngle(x1, x2, y1, y2);

      const lineCapOffset = getLineCapOffset(rounded, elementHeight, elementAngle);

      elementX -= lineCapOffset.x;
      elementY -= lineCapOffset.y;
      elementWidth += rounded ? elementHeight : 0;
    } else {
      elementX = element.x;
      elementY = element.y;
      elementWidth = element.width || 0;
      elementHeight = element.height || 0;
      elementAngle = element.r;
    }

    if (element.type === 'text') {
      elementWidth += (element.extra.stroke?.width ?? 0) * element.extra.fontSize;
      elementHeight += (element.extra.stroke?.width ?? 0) * element.extra.fontSize;
    }

    const x = spreadCoords.left + mm2px(elementX);
    const y = spreadCoords.top + mm2px(elementY);
    const width = mm2px(elementWidth || 0);
    const height = mm2px(elementHeight || 0);

    const angle = fabricNativeUtils.degreesToRadians(elementAngle || 0);
    const tl = new FabricPoint(x, y);
    const tlElement = fabricNativeUtils.rotatePoint(tl, canvasRotation.canvasCenter, canvasRotation.angleRad);

    // bounding box
    const tr = fabricNativeUtils.rotatePoint(new FabricPoint(x + width, y), tl, angle);
    const br = fabricNativeUtils.rotatePoint(new FabricPoint(x + width, y + height), tl, angle);
    const bl = fabricNativeUtils.rotatePoint(new FabricPoint(x, y + height), tl, angle);

    let maxX = tl;
    let minX = tl;
    let maxY = tl;
    let minY = tl;
    [tl, tr, br, bl].forEach((corner) => {
      if (maxX.x < corner.x) {
        maxX = corner;
      }
      if (maxY.y < corner.y) {
        maxY = corner;
      }
      if (minX.x > corner.x) {
        minX = corner;
      }
      if (minY.y > corner.y) {
        minY = corner;
      }
    });

    const tlBBox = fabricNativeUtils.rotatePoint(
      new FabricPoint(minX.x, minY.y),
      canvasRotation.canvasCenter,
      canvasRotation.angleRad,
    );
    const trBBox = fabricNativeUtils.rotatePoint(
      new FabricPoint(maxX.x, minY.y),
      canvasRotation.canvasCenter,
      canvasRotation.angleRad,
    );
    const brBBox = fabricNativeUtils.rotatePoint(
      new FabricPoint(maxX.x, maxY.y),
      canvasRotation.canvasCenter,
      canvasRotation.angleRad,
    );
    const blBBox = fabricNativeUtils.rotatePoint(
      new FabricPoint(minX.x, maxY.y),
      canvasRotation.canvasCenter,
      canvasRotation.angleRad,
    );
    const minXBBox = fabricNativeUtils.rotatePoint(minX, canvasRotation.canvasCenter, canvasRotation.angleRad);
    const minYBBox = fabricNativeUtils.rotatePoint(minY, canvasRotation.canvasCenter, canvasRotation.angleRad);
    const maxXBBox = fabricNativeUtils.rotatePoint(maxX, canvasRotation.canvasCenter, canvasRotation.angleRad);
    const maxYBBox = fabricNativeUtils.rotatePoint(maxY, canvasRotation.canvasCenter, canvasRotation.angleRad);

    snaps.push(createBBoxSnap([tlBBox, trBBox], tlElement, element.uuid, minYBBox)); // top
    snaps.push(createBBoxSnap([trBBox, brBBox], tlElement, element.uuid, maxXBBox)); // right
    snaps.push(createBBoxSnap([blBBox, brBBox], tlElement, element.uuid, maxYBBox)); // bottom
    snaps.push(createBBoxSnap([tlBBox, blBBox], tlElement, element.uuid, minXBBox)); // left

    // bbox center
    snaps.push(
      createBBoxSnap(
        [
          { x: (tlBBox.x + trBBox.x) / 2, y: (tlBBox.y + trBBox.y) / 2 },
          { x: (blBBox.x + brBBox.x) / 2, y: (blBBox.y + brBBox.y) / 2 },
        ],
        tlElement,
        element.uuid,
        tlBBox, // middle-ver
      ),
    );
    snaps.push(
      createBBoxSnap(
        [
          { x: (tlBBox.x + blBBox.x) / 2, y: (tlBBox.y + blBBox.y) / 2 },
          { x: (trBBox.x + brBBox.x) / 2, y: (trBBox.y + brBBox.y) / 2 },
        ],
        tlElement,
        element.uuid,
        tlBBox, // middle-hor
      ),
    );
  });

  return snaps;
}
