import React from 'react';

import getSpread from 'editor/src/store/design/selector/getSpread';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import hasSidebarTab from 'editor/src/store/editorModules/sidebar/selector/hasSidebarTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import { LAYOUT_TAG_GRID } from 'editor/src/util/layouts/layoutSchemas/defaultSchemas';

import { CanShow } from 'editor/src/component/Menu/type';

import SaveTemplateButton from './SaveTemplateButton';

import styles from './index.module.scss';

export const canShow: CanShow = (state, context) => {
  const spread = getSpread(state, getCurrentSpreadIndex(state));

  const containsGrid = spread?.tags?.includes(LAYOUT_TAG_GRID);
  const hasSaveDesignTab = hasSidebarTab(state, TAB_NAMES.DESIGN_TEMPLATES);
  return !context.hasSelection && !!state.design?.designData && hasSaveDesignTab && !containsGrid;
};

function ButtonSaveTemplate() {
  return (
    <div className={styles.saveDesignTemplateButtonWrapper}>
      <SaveTemplateButton className={styles.button} />
    </div>
  );
}

export default React.memo(ButtonSaveTemplate);
