import cn from 'classnames';
import React, { useCallback, useRef } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableLocation } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import addSpreadOperation from 'editor/src/store/design/operation/spreadManipulation/addSpreadOperation';
import removeSpreadOperation from 'editor/src/store/design/operation/spreadManipulation/removeSpreadOperation';
import reOrderSpreadsOperation from 'editor/src/store/design/operation/spreadManipulation/reoderSpreadsOperation';
import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import ConfirmationMenu from '../ConfirmationMenu';
import { isDisplayPageActive } from '../EditorArea/utils/displayPageUtils';
import useGetSpreadList from '../MobileLayout/useGetSpreadList';
import SpreadMobileMenu from '../SpreadMobileMenu';
import { useCanvasRendering } from '../SpreadPreview';
import { canShowAddRemovePages } from '../TopMenuDesktop/buttons/ButtonAddPages';
import usePageCountLimit from '../TopMenuDesktop/usePageCountLimit';
import useMobileMenu from '../useMobileMenu';

import Spread from './Spread';

import styles from './index.module.scss';

interface Props {
  onSpreadClick: () => void;
}

function SpreadListView({ onSpreadClick }: Props) {
  const focusedContentAddress = useSelector((state) => state.editor.focusedContentAddress);
  const canAddOrRemoveSpreads = useSelector((state) => canShowAddRemovePages(state));

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const actionsMenu = useMobileMenu();
  const confirmationMenu = useMobileMenu();

  const handleSpreadRemoval = useCallback(() => {
    actionsMenu.close();
    confirmationMenu.open();
  }, [actionsMenu.close]);

  const handleSpreadRemovalConfirmation = useCallback(() => {
    dispatch(removeSpreadOperation(currentSpreadIndexRef.current));
    confirmationMenu.close();
  }, [confirmationMenu.close]);

  const handleAddSpread = useCallback(() => {
    dispatch(addSpreadOperation(currentSpreadIndexRef.current));
    actionsMenu.close();
  }, [actionsMenu.close]);

  const handleDuplicateSpread = useCallback(() => {
    dispatch(addSpreadOperation(currentSpreadIndexRef.current, true));
    actionsMenu.close();
  }, [actionsMenu.close]);

  const handleActionMenuClose = useCallback(() => {
    actionsMenu.close();
  }, [actionsMenu.close]);

  const handleConfirmationMenuClose = useCallback(() => {
    confirmationMenu.close();
  }, [confirmationMenu.close]);

  const handleSpreadEdit = useCallback(() => {
    dispatch(setCurrentSpreadIndexOperation(currentSpreadIndexRef.current));
    actionsMenu.close();
    onSpreadClick();
  }, [onSpreadClick, actionsMenu.close]);

  const currentSpreadIndex = useSelector((state) => state.editor.currentSpreadIndex);
  const designData = useSelector((state) => state.design.designData);
  const spreads = designData?.spreads || [];
  const { spreadsList, designKey } = useGetSpreadList();
  const currentSpreadIndexRef = useRef(-1);

  const { requestRender } = useCanvasRendering(spreadsList.length > 1);
  const store = useStore();

  const handleSpreadClick = useCallback(
    (spreadIndex: number) => {
      dispatch(setCurrentSpreadIndexOperation(spreadIndex));
      onSpreadClick();
    },
    [onSpreadClick],
  );

  const handleLongPress = useCallback(
    (spreadIndex: number) => {
      currentSpreadIndexRef.current = spreadIndex;
      actionsMenu.open();
    },
    [actionsMenu.open],
  );

  function handleOrderChange(source: DraggableLocation, destination?: DraggableLocation | null) {
    // dropped outside the list
    if (!destination) {
      return;
    }

    const spreads = store.getState().design.designData?.spreads ?? [];

    const destinationSpreadIndex = spreadsList[destination.index - 1].spreadIndex;
    const isDestinationStart = destination.index === 0;
    const isDestinationEnd = destination.index === spreadsList.length - 1;

    const canAddAfter = destinationSpreadIndex && spreads[destinationSpreadIndex].canAddAfter;

    if (isDestinationStart || isDestinationEnd || !canAddAfter) {
      return;
    }
    dispatch(reOrderSpreadsOperation(source.index, destination.index, spreadsList));
  }

  const pageCount = designData?.page_count ?? 0;
  const pageCountLimit = usePageCountLimit();

  const canRemoveSpread = useCallback(
    (spreadIndex) => {
      return (!!pageCountLimit && spreads[spreadIndex]?.canRemove && pageCount > pageCountLimit.min) || false;
    },
    [spreads],
  );

  const canAddAfterSpread = useCallback(
    (spreadIndex) => {
      return !!pageCountLimit && !!designData?.spreads[spreadIndex]?.canAddAfter && pageCount <= pageCountLimit.max;
    },
    [spreads],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;
      handleOrderChange(source, destination);
    },
    [handleOrderChange],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <>
            <div
              className={cn(styles.SpreadListView, 'cy-spreads-list-wrapper')}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {spreadsList.map((spread, index) => {
                return (
                  <Draggable
                    key={index}
                    draggableId={spread.spreadIndex.toString()}
                    index={index}
                    isDragDisabled={!spreads[spread.spreadIndex]?.canRemove}
                    shouldRespectForcePress
                  >
                    {(provided) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <div
                        key={spread.spreadIndex}
                        ref={provided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.draggableProps}
                      >
                        <Spread
                          canAddAfter={canAddAfterSpread(spread.spreadIndex)}
                          displayPage={spread}
                          requestRender={requestRender}
                          designKey={designKey}
                          active={isDisplayPageActive(spread, currentSpreadIndex, focusedContentAddress)}
                          onSpreadClick={handleSpreadClick}
                          onSpreadLongPress={handleLongPress}
                          onMoreClick={handleLongPress}
                          canAddOrRemoveSpreads={canAddOrRemoveSpreads}
                          dragHandleProps={provided.dragHandleProps}
                          isDragDisabled={!spreads[spread.spreadIndex]?.canRemove}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
            {actionsMenu.render(
              <SpreadMobileMenu
                canAddAfter={canAddAfterSpread(currentSpreadIndexRef.current)}
                canRemove={canRemoveSpread(currentSpreadIndexRef.current)}
                onClose={handleActionMenuClose}
                onAdd={handleAddSpread}
                onEdit={handleSpreadEdit}
                onDuplicate={handleDuplicateSpread}
                onRemove={handleSpreadRemoval}
              />,
            )}
            {confirmationMenu.render(
              <ConfirmationMenu
                headerText={t('Do you want to delete spread')}
                confirmButtonText={t('Delete 2 pages')}
                cancellButtonText={t('No')}
                onCancel={handleConfirmationMenuClose}
                onConfirm={handleSpreadRemovalConfirmation}
                onCrossClick={handleConfirmationMenuClose}
              />,
            )}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default React.memo(SpreadListView);
