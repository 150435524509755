import { FabricObject, CanvasEvents, util as fabricNativeUtils, Point as FabricPoint } from 'fabric';
import { TOriginX, TOriginY } from 'fabric/src/typedefs';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Coords, ElementAddress, MediaLine } from 'editor/src/store/design/types';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import { useDispatch } from 'editor/src/store/hooks';

import CustomFabricCircle from 'editor/src/fabric/CustomFabricCircle';
import CustomFabricLine from 'editor/src/fabric/CustomFabricLine';
import CustomFabricObject from 'editor/src/fabric/CustomFabricObject';
import CustomFabricRect from 'editor/src/fabric/CustomFabricRect';
import useBrowserColor from 'editor/src/util/useBrowserColor';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import FabricCircleComponent, {
  Props as FabricCircleProps,
} from 'editor/src/component/EditorArea/fabricComponents/FabricCircleComponent';
import FabricLineComponent from 'editor/src/component/EditorArea/fabricComponents/FabricLineComponent';
import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import useSnapMatch from 'editor/src/component/EditorArea/snapping/useSnapMatch';
import { ELEMENT_FRAME_COLOR } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/config';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import useHoverBox from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useHoverBox';
import useIsInteractable from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useIsInteractable';
import useStoreSelection from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useStoreSelection';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation, VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';

import getLineRect from './getLineRect';
import LineEdgeComponent from './lineEdgeComponent';
import lineElementDataToFabricProps from './lineElementDataToFabricProps';
import setControlPoints from './setControlPoints';
import setLineCoordsFromControlPoint from './setLineCoordsFromControlPoint';
import useLineUpdates from './useLineUpdates';
import getLineWidth, { getLineCapOffset, getLineAngle, getEdgeOffset } from './utils';

import type { Polygon } from 'polygon-clipping';

export const CONTROL_POINT_RADIUS = 7;
export const START_POINT_INCREMENT = 0.1;
export const END_POINT_INCREMENT = 0.2;

interface Props {
  elementData: MediaLine;
  pageCoords: Coords;
  ignorePersonalizationLock: boolean;
  selected: boolean;
  elementAddress: ElementAddress;
  contentClipPolygons: Polygon[];
  showGuides: boolean;
  isMobile: boolean;
  canvasRotation: CanvasRotation;
  contentClipPath: FabricObject | undefined;
}

function Line({
  elementData,
  pageCoords,
  contentClipPath,
  ignorePersonalizationLock,
  selected,
  elementAddress,
  contentClipPolygons,
  showGuides,
  isMobile,
  canvasRotation,
}: Props) {
  const { mm2px } = useFabricUtils();
  const fabricElementRef = useRef<CustomFabricRect>(null);
  const fabricLineRef = useRef<CustomFabricLine>(null);
  const startPointRef = useRef<CustomFabricCircle>(null);
  const endPointRef = useRef<CustomFabricCircle>(null);
  const edge1Ref = useRef<CustomFabricObject>(null);
  const edge2Ref = useRef<CustomFabricObject>(null);
  const isMouseDown = useRef(false);
  const dispatch = useDispatch();
  const fabricCanvas = useFabricCanvas();

  useEffect(() => {
    function onViewPortTransform() {
      const update = {
        radius: CONTROL_POINT_RADIUS / fabricCanvas.getZoom(),
        strokeWidth: 1 / fabricCanvas.getZoom(),
      };
      startPointRef.current?.set(update);
      endPointRef.current?.set(update);
      if (selected) {
        fabricElementRef.current?.set('strokeWidth', update.strokeWidth);
      }
    }
    fabricCanvas.on(VIEWPORT_CHANGED_EVENT as any, onViewPortTransform);
    return () => {
      fabricCanvas.off(VIEWPORT_CHANGED_EVENT as any, onViewPortTransform);
    };
  }, [fabricCanvas, selected]);

  const { liveElement: lineData } = useMediaElementLiveUpdates(elementData);
  const isInteractable = useIsInteractable(lineData, ignorePersonalizationLock);
  const snapMatch = useSnapMatch(lineData.uuid, showGuides && !lineData.locked, pageCoords);
  const startPointSnapMatch = useSnapMatch(
    lineData.uuid + START_POINT_INCREMENT,
    showGuides && !lineData.locked,
    pageCoords,
  );
  const endPointSnapMatch = useSnapMatch(
    lineData.uuid + END_POINT_INCREMENT,
    showGuides && !lineData.locked,
    pageCoords,
  );

  const fabricProps = lineElementDataToFabricProps(lineData, elementAddress.elementIndex, pageCoords, mm2px);
  fabricProps.stroke = useBrowserColor(fabricProps.stroke);

  const lineRect = useMemo(() => getLineRect(lineData, pageCoords, mm2px), [mm2px, pageCoords, lineData]);

  const hoverBox = useHoverBox(lineRect, false, selected || !isInteractable, canvasRotation);

  const clipPath = useMemo(
    () => getClipPath(lineRect, contentClipPolygons, false, contentClipPath),
    [contentClipPolygons, lineRect, contentClipPath],
  );

  useStoreSelection(fabricElementRef, lineData.uuid, lineData.type, selected);
  const onPointSelected = () => {
    dispatch(addSelectedMediaElementOperation(lineData.uuid));
  };

  const onMouseDown = useCallback(
    (e: CanvasEvents['mouse:down']) => {
      isMouseDown.current = true;
      snapMatch.onMouseDown(e);
    },
    [snapMatch.onMouseDown],
  );

  const onMouseMove = useCallback(
    (e: CanvasEvents['mouse:move']) => {
      if (!isMouseDown.current || fabricCanvas.getActiveObject() !== fabricElementRef.current) {
        return;
      }
      snapMatch.onMouseMove(e);
      setControlPoints(
        fabricElementRef,
        fabricLineRef,
        startPointRef,
        endPointRef,
        edge1Ref,
        edge2Ref,
        lineData.edge1,
        lineData.edge2,
        contentClipPolygons,
        contentClipPath,
      );
    },
    [snapMatch.onMouseMove, lineData.edge1, lineData.edge2, contentClipPolygons, contentClipPath],
  );

  const onStartPointMouseDown = useCallback(
    (e: CanvasEvents['mouse:down']) => {
      isMouseDown.current = true;
      startPointSnapMatch.onMouseDown(e);
    },
    [startPointSnapMatch.onMouseDown],
  );

  const onEndPointMouseDown = useCallback(
    (e: CanvasEvents['mouse:down']) => {
      isMouseDown.current = true;
      endPointSnapMatch.onMouseDown(e);
    },
    [endPointSnapMatch.onMouseDown],
  );

  const onStartPointMove = useCallback(
    (e: CanvasEvents['mouse:move']) => {
      if (isMouseDown.current) {
        startPointSnapMatch.onMouseMove(e);
        setLineCoordsFromControlPoint(
          startPointRef,
          endPointRef,
          fabricElementRef,
          edge1Ref,
          edge2Ref,
          fabricLineRef,
          fabricProps.strokeWidth,
          contentClipPolygons,
          contentClipPath,
          lineData.edge1,
          lineData.edge2,
        );
      }
    },
    [startPointSnapMatch.onMouseMove, contentClipPolygons, contentClipPath, lineData.edge1, lineData.edge2],
  );

  const onEndPointMove = useCallback(
    (e: CanvasEvents['mouse:move']) => {
      if (isMouseDown.current) {
        endPointSnapMatch.onMouseMove(e);
        setLineCoordsFromControlPoint(
          startPointRef,
          endPointRef,
          fabricElementRef,
          edge1Ref,
          edge2Ref,
          fabricLineRef,
          fabricProps.strokeWidth,
          contentClipPolygons,
          contentClipPath,
          lineData.edge1,
          lineData.edge2,
        );
      }
    },
    [endPointSnapMatch.onMouseMove, contentClipPolygons, contentClipPath, lineData.edge1, lineData.edge2],
  );

  const onStartPointMoveUp = useCallback(() => {
    isMouseDown.current = false;
    startPointSnapMatch.onMouseUp();
  }, [startPointSnapMatch.onMouseUp]);

  const onEndPointMoveUp = useCallback(() => {
    isMouseDown.current = false;
    endPointSnapMatch.onMouseUp();
  }, [endPointSnapMatch.onMouseUp]);

  const onMouseUp = useCallback(() => {
    isMouseDown.current = false;
    snapMatch.onMouseUp();
  }, [snapMatch.onMouseUp]);

  const lineUpdates = useLineUpdates(pageCoords, lineData, elementAddress, fabricElementRef);

  const controlPointProps: FabricCircleProps = {
    fill: 'transparent',
    hasControls: false,
    hasBorders: false,
    zIndex: zIndex.HOVER_BOX,
    stroke: ELEMENT_FRAME_COLOR,
    hoverCursor: 'pointer',
    strokeWidth: 1 / fabricCanvas.getZoom(),
    radius: CONTROL_POINT_RADIUS / fabricCanvas.getZoom(),
    onSelected: onPointSelected,
    onModified: lineUpdates.onObjectModified,
    originX: 'center' as TOriginX,
    originY: 'center' as TOriginY,
    objectCaching: false,
  };

  const { x1 = 0, y1 = 0, x2 = 0, y2 = 0 } = fabricProps;
  const lineWidth = getLineWidth(x1, x2, y1, y2);
  const angle = getLineAngle(x1, x2, y1, y2);
  const strokeWidthOffset = fabricNativeUtils.rotatePoint(
    new FabricPoint(fabricProps.strokeWidth / 2, 0),
    new FabricPoint(0, 0),
    fabricNativeUtils.degreesToRadians(angle + 90),
  );

  const edge1Offset = getEdgeOffset(lineData.edge1, fabricProps.strokeWidth, angle);
  const edge2Offset = getEdgeOffset(lineData.edge2, fabricProps.strokeWidth, angle);
  const lineCapOffset = getLineCapOffset(lineData.rounded, fabricProps.strokeWidth, angle);

  return (
    <>
      <LineEdgeComponent
        strokeWidth={fabricProps.strokeWidth}
        left={x1 + strokeWidthOffset.x - lineCapOffset.x}
        top={y1 + strokeWidthOffset.y - lineCapOffset.y}
        stroke={fabricProps.stroke}
        zIndex={fabricProps.zIndex}
        angle={angle}
        edge={lineData.edge1}
        ref={edge1Ref}
        contentClipPolygons={contentClipPolygons}
        contentClipPath={contentClipPath}
      />
      <LineEdgeComponent
        strokeWidth={fabricProps.strokeWidth}
        left={x2 + strokeWidthOffset.x + lineCapOffset.x}
        top={y2 + strokeWidthOffset.y + lineCapOffset.y}
        stroke={fabricProps.stroke}
        zIndex={fabricProps.zIndex}
        angle={-180 + angle}
        edge={lineData.edge2}
        ref={edge2Ref}
        contentClipPolygons={contentClipPolygons}
        contentClipPath={contentClipPath}
      />
      <FabricRectComponent // selection & manipulation rect
        angle={angle}
        top={y1 - lineCapOffset.y}
        left={x1 - lineCapOffset.x}
        width={lineWidth + (lineData.rounded ? fabricProps.strokeWidth : 0)}
        height={fabricProps.strokeWidth}
        ref={fabricElementRef}
        onModified={lineUpdates.onObjectModified}
        onSelected={lineUpdates.onSelected}
        onMouseOver={hoverBox.onMouseOver}
        onMouseOut={hoverBox.onMouseOut}
        onMouseDownBefore={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        hasControls={false}
        hasBorders={false}
        stroke={controlPointProps.stroke}
        strokeWidth={selected ? controlPointProps.strokeWidth : 0}
        hoverCursor={selected ? 'move' : 'pointer'}
        padding={5}
        fill="transparent"
        zIndex={selected ? zIndex.HOVER_BOX : fabricProps.zIndex + 0.1}
        lockMovementY={elementData.locked}
        lockMovementX={elementData.locked}
        lockRotation={elementData.locked}
        objectCaching={false}
        evented={isInteractable}
        selectable={!isMobile}
      />
      <FabricLineComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...fabricProps}
        x1={x1 + strokeWidthOffset.x + edge1Offset.x}
        y1={y1 + strokeWidthOffset.y + edge1Offset.y}
        x2={x2 + strokeWidthOffset.x - edge2Offset.x}
        y2={y2 + strokeWidthOffset.y - edge2Offset.y}
        evented={false}
        ref={fabricLineRef}
        clipPath={clipPath}
        zIndex={fabricProps.zIndex}
        objectCaching={false}
      />
      {selected && !elementData.locked && (
        <>
          <FabricCircleComponent
            ref={startPointRef}
            left={x1 + strokeWidthOffset.x - lineCapOffset.x}
            top={y1 + strokeWidthOffset.y - lineCapOffset.y}
            onMouseDownBefore={onStartPointMouseDown}
            onMouseMove={onStartPointMove}
            onMouseUp={onStartPointMoveUp}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...controlPointProps}
          />
          <FabricCircleComponent
            left={x2 + strokeWidthOffset.x + lineCapOffset.x}
            top={y2 + strokeWidthOffset.y + lineCapOffset.y}
            ref={endPointRef}
            onMouseDownBefore={onEndPointMouseDown}
            onMouseMove={onEndPointMove}
            onMouseUp={onEndPointMoveUp}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...controlPointProps}
          />
        </>
      )}
      {hoverBox.render()}
    </>
  );
}

export default React.memo(Line);
