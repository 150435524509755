import { updateImageAction } from 'editor/src/store/gallery/slice';
import { AssetUpdatePayload } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const updateImageOnUpdatedMetadataOperation =
  (imageData: AssetUpdatePayload & { id: string }) => (dispatch: ThunkDispatch) => {
    dispatch(
      updateImageAction({
        id: imageData.id,
        name: imageData.filename,
      }),
    );
  };

export default updateImageOnUpdatedMetadataOperation;
