import React from 'react';

import CustomFabricLine from 'editor/src/fabric/CustomFabricLine';

const getLineRectFromRef = (lineRef: React.RefObject<CustomFabricLine>, strokeWidth: number) => ({
  top: Math.min(lineRef?.current?.y1 || 0, lineRef?.current?.y2 || 0) - strokeWidth / 2,
  left: Math.min(lineRef?.current?.x1 || 0, lineRef?.current?.x2 || 0) - strokeWidth / 2,
  height: (lineRef?.current?.height || 0) + strokeWidth,
  width: (lineRef?.current?.width || 0) + strokeWidth,
  angle: lineRef?.current?.angle || 0,
});

export default getLineRectFromRef;
