import { FabricObject } from 'fabric';
import { useEffect } from 'react';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';
import { VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';

import getWarningIconDimensions from './getWarningIconDimensions';

function useWarningIconViewport(frameRect: ObjectRect, warningRef: React.RefObject<FabricObject>) {
  const fabricCanvas = useFabricCanvas();

  useEffect(() => {
    const onViewportChange = () => {
      warningRef.current?.set(getWarningIconDimensions(frameRect, fabricCanvas.getZoom()));
    };

    fabricCanvas.on(VIEWPORT_CHANGED_EVENT as any, onViewportChange);
    return () => {
      fabricCanvas.off(VIEWPORT_CHANGED_EVENT as any, onViewportChange);
    };
  }, [frameRect]);
}

export default useWarningIconViewport;
