import { Group as FabricGroup, StaticCanvas, FabricImage } from 'fabric';
import React, { useImperativeHandle, useLayoutEffect, useMemo } from 'react';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';
import useFabricCanvas, { FabricCanvasContext } from 'editor/src/util/useFabricCanvas';

import orderObjects from 'editor/src/component/EditorArea/orderObjects';

import useControlVisibility, { ControlVisibility } from './useControlVisibility';
import useEvent, { EventHandler } from './useEvent';
import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

interface Props extends Partial<FabricImage> {
  uuid?: number;
  zIndex?: number;
  width: number;
  height: number;
  children: React.ReactNode | React.ReactNodeArray;
  controlVisibility?: ControlVisibility;
  canvasMinSize?: number;
  onMouseDownBefore?: EventHandler;
  onMouseMove?: EventHandler;
  onMouseUp?: EventHandler;
  onMouseOver?: EventHandler;
  onMouseOut?: EventHandler;
  onModified?: EventHandler;
  onReady?: EventHandler;
}

export interface FabricGroupImage extends CustomFabricImage {
  updateSize(width: number, height: number): void;
}

export const GroupContext = React.createContext<FabricGroup | undefined>(undefined);

function getCanvasSize(width: number, height: number, canvasMinSize: number | undefined) {
  const minSize = canvasMinSize ?? 500;
  let canvasWidth = width;
  let canvasHeight = width;
  if (width > height) {
    canvasWidth = minSize;
    canvasHeight = minSize * (height / width);
  } else {
    canvasHeight = minSize;
    canvasWidth = minSize * (width / height);
  }
  return { canvasWidth, canvasHeight };
}

function FabricGroupAsImageComponent(props: Props, ref: React.Ref<FabricGroupImage>) {
  const fabricCanvas = useFabricCanvas();

  const {
    children,
    onMouseDownBefore,
    onMouseMove,
    onMouseUp,
    onMouseOver,
    onMouseOut,
    onModified,
    onReady,
    controlVisibility,
    width,
    height,
    scaleX,
    scaleY,
    canvasMinSize,
    filters,
    ...fabricProps
  } = props;

  const { canvasWidth, canvasHeight } = getCanvasSize(width, height, canvasMinSize);

  const localCanvas = useMemo(() => {
    const domCanvas = document.createElement('canvas');
    domCanvas.id = 'group';
    const localCanvas = new StaticCanvas(domCanvas, {
      renderOnAddRemove: false,
      width: canvasWidth,
      height: canvasHeight,
    });
    localCanvas.on('before:render', () => orderObjects(localCanvas));

    localCanvas.on('after:render', () => {
      if (element.filters) {
        element.setElement(localCanvas.getElement() as any);
        element.applyFilters(element.filters);
      }
      fabricCanvas.requestRenderAll();

      const rendered = () => {
        onReady?.(element.getElement());
        fabricCanvas.off('after:render', rendered);
      };
      fabricCanvas.on('after:render', rendered);
    });

    return localCanvas;
  }, []);

  const element = useMemo(
    () =>
      new CustomFabricImage(localCanvas.getElement(), {
        type: 'FabricGroupComponent2',
      }) as FabricGroupImage,
    [localCanvas],
  );
  (element as any).localCanvas = localCanvas;
  element.updateSize = (width: number, height: number) => {
    const { canvasWidth, canvasHeight } = getCanvasSize(width, height, canvasMinSize);
    element.set({
      scaleX: (scaleX ?? 1) * (1 / window.devicePixelRatio) * (width / canvasWidth),
      scaleY: (scaleY ?? 1) * (1 / window.devicePixelRatio) * (height / canvasHeight),
    });
  };

  useLayoutEffect(() => {
    localCanvas.setDimensions({ width: canvasWidth, height: canvasHeight });
    element.setElement(localCanvas.getElement() as any);
  }, [canvasWidth, canvasWidth, localCanvas]);

  useLayoutEffect(() => {
    localCanvas.setZoom(canvasWidth / width);
    element.set({
      scaleX: (scaleX ?? 1) * (1 / window.devicePixelRatio) * (width / canvasWidth),
      scaleY: (scaleY ?? 1) * (1 / window.devicePixelRatio) * (height / canvasHeight),
    });
  }, [width, height, canvasWidth, canvasHeight, scaleX, scaleY]);

  useLayoutEffect(() => {
    if (filters) {
      element.filters = filters;
    }

    element.setElement(localCanvas.getElement() as any);
    element.applyFilters();
    onReady?.(element.getElement());
  }, [filters]);

  useObjectProps(element, fabricProps);

  useImperativeHandle(ref, () => element, [element]);
  useObjectUpdate(fabricCanvas, element);
  useControlVisibility(element, controlVisibility);

  useEvent(element, 'mouseover', onMouseOver);
  useEvent(element, 'mouseout', onMouseOut);
  useEvent(element, 'mousedown:before', onMouseDownBefore);
  useEvent(element, 'mousemove', onMouseMove);
  useEvent(element, 'mouseup', onMouseUp);
  useEvent(element, 'modified', onModified);

  return <FabricCanvasContext.Provider value={localCanvas as any}>{children}</FabricCanvasContext.Provider>;
}

export default React.memo(React.forwardRef(FabricGroupAsImageComponent));
