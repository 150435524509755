import { captureException } from '@sentry/react';
import cn from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IntroTourConfigItem, IntroTourType } from 'editor/src/store/editorModules/tour/types';
import getIntroTourConfig from 'editor/src/store/editorModules/tour/utils/introTourConfig';

import Button from 'editor/src/component/Button';
import IconCross from 'editor/src/component/Icon/IconCross';

import styles from './index.module.scss';

interface Props {
  activeIntroTourId: IntroTourType;
  totalStepsCount: number;
  stepIndex: number;
  onCloseTour: () => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
}

const POINTER_OFFSET = 9;

function EditorTourInfoDialog({
  activeIntroTourId,
  totalStepsCount,
  stepIndex,
  onCloseTour,
  onPreviousStep,
  onNextStep,
}: Props) {
  const [pointerElementBox, setPointerElementBox] = useState<DOMRect | null>(null);
  const [tourConfig, setTourConfig] = useState<IntroTourConfigItem | null>(null);
  const { t } = useTranslation();
  const dialogRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const introTourConfig = getIntroTourConfig(activeIntroTourId);
    if (!introTourConfig.pointerSelector) {
      return;
    }

    const box = document.querySelector(introTourConfig.pointerSelector)?.getBoundingClientRect();
    if (box) {
      setPointerElementBox(box);
      setTourConfig(introTourConfig);
    } else {
      captureException(`Can not find tour element by selector ${introTourConfig.pointerSelector}`);
      // move to the next step if can't find element
      onNextStep();
    }
  }, [activeIntroTourId]);

  const getWrapperTransformStyles = () => {
    const elementStyles: { [key: string]: string } = {};
    let x = 0;
    let y = 0;
    if (tourConfig && pointerElementBox) {
      if (tourConfig.position === 'left-center') {
        x = pointerElementBox.left + pointerElementBox.width;
        y = pointerElementBox.top + pointerElementBox.height / 2;
      } else if (tourConfig.position === 'top-right') {
        const { width: dialogWidth = 0 } = dialogRef.current?.getBoundingClientRect() || {};
        x = pointerElementBox.left + pointerElementBox.width - dialogWidth;
        y = pointerElementBox.top + pointerElementBox.height;
      } else if (tourConfig.position === 'right-top') {
        const { width: dialogWidth = 0 } = dialogRef.current?.getBoundingClientRect() || {};
        x = pointerElementBox.left - dialogWidth;
        y = pointerElementBox.top;
      }
    }

    elementStyles.transform = `translate3d(${x}px,${y}px,0)`;

    if (tourConfig?.xOffset) {
      elementStyles.left = `${tourConfig.xOffset}px`;
    }

    if (tourConfig?.yOffset) {
      elementStyles.top = `${tourConfig.yOffset}px`;
    }

    return elementStyles;
  };

  const getContentWrapperTransformStyles = () => {
    const elementStyles: { [key: string]: string } = {};
    let x = 0;
    let y = 0;
    if (tourConfig && pointerElementBox) {
      if (tourConfig.position === 'left-center') {
        x = 0;
        y = -50;
        elementStyles.transform = `translate3d(${x}%,${y}%,0)`;
      }
    }

    elementStyles.transform = `translate3d(${x}%,${y}%,0)`;

    return elementStyles;
  };

  const getPointerStyles = () => {
    const elementStyles: { [key: string]: string } = {};
    let x = 0;
    let y = 0;
    if (tourConfig && pointerElementBox) {
      if (tourConfig.position === 'left-center') {
        x = 0;
        y = -50;
        elementStyles.left = `${-POINTER_OFFSET}px`;
        elementStyles.transform = `translate3d(${x}%,${y}%,0) rotate(-45deg)`;
      } else if (tourConfig.position === 'top-right') {
        const { width: dialogWidth = 0 } = dialogRef.current?.getBoundingClientRect() || {};
        if (dialogWidth > pointerElementBox.width) {
          x = dialogWidth - pointerElementBox.width / 2;
        } else {
          x = dialogWidth / 2;
        }
        y = 0;
        elementStyles.transform = `translate3d(${x}px,${y}px,0) rotate(-45deg)`;
        elementStyles.top = `${-POINTER_OFFSET}px`;
      } else if (tourConfig.position === 'right-top') {
        const { height: dialogHeight = 0 } = dialogRef.current?.getBoundingClientRect() || {};
        if (dialogHeight > pointerElementBox.height) {
          y = pointerElementBox.height / 2;
        } else {
          y = dialogHeight / 2;
        }
        elementStyles.transform = `translate3d(100%,${y}px,0) rotate(-45deg)`;
        elementStyles.right = '11px';
      }
    }

    return elementStyles;
  };

  if (!pointerElementBox || !tourConfig) {
    return null;
  }

  return (
    <div
      className={cn(styles.wrapper, `cy-editor-tour-step-${stepIndex}`)}
      style={getWrapperTransformStyles()}
      ref={dialogRef}
    >
      <div className={cn(styles.pointer)} style={getPointerStyles()} />
      <div className={styles.contentWrapper} style={getContentWrapperTransformStyles()}>
        <div className={cn(styles.header)}>
          <div className={styles.headerTitle}>{t(tourConfig.title)}</div>
          <div className="cy-editor-tour-header-close" onClick={onCloseTour}>
            <IconCross className={styles.headerCloseIcon} />
          </div>
        </div>
        <div className={styles.content}>{t(tourConfig.description)}</div>
        <div className={styles.footer}>
          {stepIndex === 1 ? (
            <Button secondary onClick={onCloseTour} className={cn(styles.button, 'cy-editor-tour-close')}>
              {t('Close')}
            </Button>
          ) : (
            <Button secondary onClick={onPreviousStep} className={cn(styles.button, 'cy-editor-tour-previous')}>
              {t('Previous')}
            </Button>
          )}
          <div className={styles.counter}>
            {stepIndex} of {totalStepsCount}
          </div>
          {stepIndex === totalStepsCount ? (
            <Button primary onClick={onCloseTour} className={cn(styles.button, 'cy-editor-tour-close')}>
              {t('Done')}
            </Button>
          ) : (
            <Button primary onClick={onNextStep} className={cn(styles.button, 'cy-editor-tour-next')}>
              {t('Next')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(EditorTourInfoDialog);
