import cn from 'classnames';
import { util as fabricNativeUtils } from 'fabric';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { MediaElement } from 'editor/src/store/design/types';

import { Point } from 'editor/src/util/2d/types';
import degrees2Radians from 'editor/src/util/degrees2Radians';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import { currentOperationManager, ObjectCoordinates } from 'editor/src/component/EditorArea/ElementOperationOverlay';
import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';
import { VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';

import styles from './index.module.scss';

const OFFSET_FROM_ELEMENT = 10;

interface Props {
  frameRect: ObjectRect;
  element: MediaElement;
}

function ElementLoader({ frameRect, element }: Props) {
  const fabricCanvas = useFabricCanvas();
  const loaderRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const updatePosition = useCallback(
    ({ x, y }: Point, angle = frameRect.angle) => {
      if (!loaderRef.current) {
        return;
      }

      loaderRef.current.style.transform = `translate3d(${Math.max(x, 0)}px,${Math.max(y, 0)}px,0) rotate(${angle}deg)`;
    },
    [frameRect],
  );

  const getCoordinates = useCallback(() => {
    const offsetX = fabricCanvas.viewportTransform ? fabricCanvas?.viewportTransform[4] : 0;
    const offsetY = fabricCanvas.viewportTransform ? fabricCanvas?.viewportTransform[5] : 0;
    const zoom = fabricCanvas.getZoom();

    const width = (frameRect.width / 2) * zoom;
    const height = frameRect.height * zoom + OFFSET_FROM_ELEMENT;

    const vectorLength = Math.sqrt(width ** 2 + height ** 2);
    const angle = degrees2Radians(
      90 - fabricNativeUtils.radiansToDegrees(Math.asin(width / vectorLength)) + frameRect.angle,
    );

    return {
      x: offsetX + frameRect.left * zoom + vectorLength * Math.cos(angle),
      y: offsetY + frameRect.top * zoom + vectorLength * Math.sin(angle),
    };
  }, [frameRect]);

  useEffect(() => {
    const onViewPortChanged = () => {
      updatePosition(getCoordinates());
    };

    const onMoving = (movingElement: MediaElement, { mb }: ObjectCoordinates) => {
      if (
        element.uuid !== movingElement.uuid ||
        (movingElement.type !== 'image' && movingElement.type !== 'addon' && movingElement.type !== 'text')
      ) {
        return;
      }

      const angle = degrees2Radians(90 + movingElement.r);
      const x = mb.x + OFFSET_FROM_ELEMENT * Math.cos(angle);
      const y = mb.y + OFFSET_FROM_ELEMENT * Math.sin(angle);
      updatePosition({ x, y }, movingElement.r);
    };

    fabricCanvas?.on(VIEWPORT_CHANGED_EVENT as any, onViewPortChanged);
    currentOperationManager.on('objectUpdating', onMoving);

    return () => {
      fabricCanvas?.off(VIEWPORT_CHANGED_EVENT as any, onViewPortChanged);
      currentOperationManager.off('objectUpdating', onMoving);
    };
  }, [updatePosition, getCoordinates, element.uuid]);

  const coordinates = getCoordinates();

  return (
    <div
      ref={loaderRef}
      className={cn(styles.loaderContainer, 'cy-element-loader')}
      style={{
        transform: `translate3d(${Math.max(coordinates.x, 0)}px,${Math.max(coordinates.y, 0)}px,0) rotate(${frameRect.angle}deg)`,
      }}
    >
      <div className={styles.loader}>
        <div className={cn(styles.spinner, 'mr-1')} />
        {t('generating-preview')}
      </div>
    </div>
  );
}

export default React.memo(ElementLoader);
