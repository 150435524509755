import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import {
  setThe3dPreviewAction,
  setFlatPreviewsAction,
  setPreviewStatusAction,
} from 'editor/src/store/editorModules/preview/slice';
import { PreviewStatus } from 'editor/src/store/editorModules/preview/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import { RootState } from 'editor/src/store/index';

import { InboundPostMessage } from 'editor/src/util/postMessages/messages';

import setAdvancedFlatPreviewsOperation from './setAdvancedFlatPreviewsOperation';

const setRendersOperation =
  (data: InboundPostMessage['preview.setRenders']) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const previewType = getHostSetting(state, 'previewType');

    batch(() => {
      if (previewType === 'PREVIEW_FLAT_ADVANCED') {
        dispatch(setAdvancedFlatPreviewsOperation(data.flat, data.designStructure));
      } else {
        dispatch(setFlatPreviewsAction(data.flat));
      }
      dispatch(setThe3dPreviewAction(data.the3d));
      dispatch(setPreviewStatusAction(PreviewStatus.LOADED));
    });
  };

export default setRendersOperation;
