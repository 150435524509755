import React, { useCallback } from 'react';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { Coords, ElementAddress, MediaLine } from 'editor/src/store/design/types';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import { useDispatch } from 'editor/src/store/hooks';

import CustomFabricRect from 'editor/src/fabric/CustomFabricRect';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import fabricPropsToLineElementData from './fabricPropsToLineElementData';

function useLineUpdates(
  pageCoords: Coords,
  lineData: MediaLine,
  lineAddress: ElementAddress,
  lineRef: React.RefObject<CustomFabricRect>,
) {
  const dispatch = useDispatch();
  const { px2mm } = useFabricUtils();

  const onObjectModified = useCallback(() => {
    if (lineRef.current) {
      const lineUpdate = fabricPropsToLineElementData(px2mm, pageCoords, lineRef.current, lineData.rounded);
      dispatch(updateMediaElementOperation(lineAddress, lineUpdate, MediaUpdateActionName.LINE_POSITION_UPDATED));
    }
  }, [px2mm, pageCoords, lineAddress, lineData.rounded]);

  const onSelected = useCallback(() => {
    if (lineRef.current) {
      dispatch(addSelectedMediaElementOperation(lineData.uuid));
    }
  }, [lineData.uuid]);

  return { onObjectModified, onSelected };
}

export default useLineUpdates;
