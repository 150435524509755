import cn from 'classnames';
import React from 'react';

import requestPreviewsOperation from 'editor/src/store/editorModules/preview/operation/requestPreviewsOperation';
import getFlatPreviewAvailable from 'editor/src/store/editorModules/preview/selector/getFlatPreviewAvailable';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import IconEye from 'editor/src/component/Icon/IconEye';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection }) => !hasSelection && getFlatPreviewAvailable(state);

type Props = Pick<MenuItemProps, 'mode'>;

function ButtonFlatPreview({ mode }: Props) {
  const dispatch = useDispatch();
  const previewType = useSelector((state) => getHostSetting(state, 'previewType'));

  const handleClick = () => {
    dispatch(requestPreviewsOperation(previewType));
  };

  return (
    <MenuButton
      Icon={IconEye}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-flat-preview"
      className={cn('intro-tour-top-bar-preview-button', 'cy-button-flat-preview')}
    />
  );
}

export default React.memo(ButtonFlatPreview);
