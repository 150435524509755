import { DesignData } from 'editor/src/store/design/types';
import { IntroTourConfigItem, IntroTourType } from 'editor/src/store/editorModules/tour/types';

const DEFAULT_DIALOG_OFFSET = 30;
const getProductTabConfigItem = (title: string, description: string): IntroTourConfigItem => {
  return {
    elementSelectors: ['.intro-tour-tab-product', '.intro-tour-tab-content-product'],
    title,
    description,
    pointerSelector: '.intro-tour-product-tab-title',
    position: 'left-center',
    xOffset: 35,
  };
};

const getProductTabContinueItem = (title: string, description: string): IntroTourConfigItem => {
  return {
    elementSelectors: [],
    title,
    description,
    pointerSelector: '.intro-tour-dummy-top-element',
    position: 'top-right',
    yOffset: DEFAULT_DIALOG_OFFSET,
    xOffset: -DEFAULT_DIALOG_OFFSET,
  };
};

const introTourConfig: { [tourId in IntroTourType]: IntroTourConfigItem } = {
  'product-tab': getProductTabConfigItem('tour-product-tab-title', 'tour-product-tab-description'),
  'ecommerce-product-tab': getProductTabConfigItem('tour-product-tab-title', 'tour-ecommerce-product-tab-description'),
  'sidebar-tours': {
    elementSelectors: [
      '.intro-tour-tab-gallery',
      '.intro-tour-tab-texts',
      '.intro-tour-tab-design_templates',
      '.intro-tour-tab-addons',
    ],
    title: 'tour-sidebar-tours-title',
    description: 'tour-sidebar-tours-description',
    pointerSelector: '.intro-tour-tab-texts',
    position: 'left-center',
    xOffset: DEFAULT_DIALOG_OFFSET,
  },
  'design-templates-button': {
    elementSelectors: ['.intro-tour-top-bar-add-template-button'],
    title: 'tour-design-templates-button-title',
    description: 'tour-design-templates-button-description',
    pointerSelector: '.intro-tour-top-bar-add-template-button',
    position: 'top-right',
    yOffset: DEFAULT_DIALOG_OFFSET,
  },
  'preview-top-button': {
    elementSelectors: ['.intro-tour-top-bar-preview-button'],
    title: 'tour-preview-top-button-title',
    description: 'tour-preview-top-button-description',
    pointerSelector: '.intro-tour-top-bar-preview-button',
    position: 'top-right',
    yOffset: DEFAULT_DIALOG_OFFSET,
  },
  'order-continue': getProductTabContinueItem('tour-order-continue-title', 'tour-order-continue-description'),
  'ecommerce-continue': getProductTabContinueItem(
    'tour-ecommerce-continue-title',
    'tour-ecommerce-continue-description',
  ),
  'variants-list': {
    elementSelectors: ['.intro-tour-variants-list'],
    title: 'tour-variants-list-title',
    description: 'tour-variants-list-description',
    pointerSelector: '.intro-tour-variants-list .intro-tour-variants-entry',
    position: 'right-top',
    yOffset: -8,
    xOffset: -DEFAULT_DIALOG_OFFSET,
  },
};

export const getIntroTourConfig = (type: IntroTourType): IntroTourConfigItem => {
  return introTourConfig[type] as IntroTourConfigItem;
};

export const isIntroTourAllowed = (designData?: DesignData) => {
  if (!designData) {
    return false;
  }

  // don't show tour for photo books
  return !designData?.page_count_limit;
};

export default getIntroTourConfig;
