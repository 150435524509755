import { Canvas as FabricCanvas } from 'fabric';
import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadBackgroundImage from 'editor/src/store/design/selector/getSpreadBackgroundImage';
import getSpreadForegroundImage from 'editor/src/store/design/selector/getSpreadForegroundImage';
import { Coords, Dimensions } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import { convFn } from 'editor/src/util/convFn';

import getContentCenter from 'editor/src/component/EditorArea/utils/getContentCenter';

import { useBottomBarHeight } from '../EditorAreaControls/BottomControls/BottomBarHeightProvider';

// zoom margin when we transform viewport but needs to be focused on spread
const PAN_MARGIN = 20;

function useGetCenter(
  fabricCanvas: FabricCanvas | undefined,
  canvasDim: Dimensions,
  spreadIndex: number,
  spreadCoords: Coords,
  spreadWidth: number,
  spreadHeight: number,
  minZoom: number,
  mm2px: convFn,
) {
  const { defaultZoom, focusedPage, focusedContent, background, foreground } = useSelector((state) => {
    const spread = getSpread(state, spreadIndex);
    const { focusedContentAddress } = state.editor;
    const focusedPage =
      focusedContentAddress?.spreadIndex === spreadIndex ? spread?.pages[focusedContentAddress.pageIndex] : undefined;
    const focusedContent =
      focusedPage && focusedContentAddress
        ? focusedPage.groups.content?.[focusedContentAddress.contentIndex]
        : undefined;

    return {
      defaultZoom: state.editorModules.panAndZoom.defaultZoom,
      focusedPage,
      focusedContent,
      hasMultipleSpreads: (state.design.designData?.spreads.length || 0) > 1,
      background: getSpreadBackgroundImage(state, spread?.name || ''),
      foreground: getSpreadForegroundImage(state, spread?.name || ''),
    };
  }, shallowEqual);

  const { bottomBarHeight } = useBottomBarHeight();

  const getCenter = useCallback(
    (zoom: number, dim?: Dimensions) =>
      getContentCenter(
        dim ?? canvasDim,
        spreadWidth,
        spreadHeight,
        background,
        foreground,
        spreadCoords,
        zoom,
        minZoom,
        mm2px,
        focusedPage && focusedContent ? { content: focusedContent, page: focusedPage } : undefined,
        defaultZoom,
        bottomBarHeight,
      ),
    [
      canvasDim,
      spreadWidth,
      spreadHeight,
      background,
      foreground,
      spreadCoords,
      minZoom,
      mm2px,
      focusedContent,
      focusedPage,
      defaultZoom,
      bottomBarHeight,
    ],
  );

  const spreadWidthPx = mm2px(spreadWidth);

  const getVtpXBoundaries = useCallback(
    (zoom = fabricCanvas?.getZoom(), dim = canvasDim) => {
      const center = getCenter(zoom, dim);
      const availableW = Math.max(0, ((spreadWidthPx + PAN_MARGIN) * zoom - dim.width) / 2);

      return {
        min: -center.x + availableW,
        max: -center.x - availableW,
      };
    },
    [getCenter, spreadWidthPx, canvasDim],
  );

  const getVtpX = useCallback(
    (x: number, zoom = fabricCanvas?.getZoom(), dim?: Dimensions) => {
      const { min: minX, max: maxX } = getVtpXBoundaries(zoom, dim);
      return Math.max(maxX, Math.min(minX, x));
    },
    [getVtpXBoundaries],
  );

  const spreadHeightPx = mm2px(spreadHeight);

  const getVtpYBoundaries = useCallback(
    (zoom = fabricCanvas?.getZoom(), dim = canvasDim) => {
      const center = getCenter(zoom, dim);
      const availableH = Math.max(0, ((spreadHeightPx + PAN_MARGIN) * zoom - (dim.height - bottomBarHeight)) / 2);

      return {
        min: -center.y + availableH,
        max: -center.y - availableH,
      };
    },
    [getCenter, spreadHeightPx, bottomBarHeight, canvasDim, bottomBarHeight],
  );

  const getVtpY = useCallback(
    (y: number, zoom = fabricCanvas?.getZoom(), dim?: Dimensions) => {
      const { min: minY, max: maxY } = getVtpYBoundaries(zoom, dim);
      return Math.max(maxY, Math.min(minY, y));
    },
    [getVtpYBoundaries],
  );

  return {
    getCenter,
    getVtpX,
    getVtpY,
    getVtpXBoundaries,
    getVtpYBoundaries,
  };
}

export default useGetCenter;
