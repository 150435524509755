import Canvg from 'canvg';
import React, { useImperativeHandle, useMemo, useRef } from 'react';

import { LeaningShadow, PerspectiveTransform, ShadowSide } from 'editor/src/store/design/types';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';
import { convFn } from 'editor/src/util/convFn';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricImageCanvasComponent from 'editor/src/component/EditorArea/fabricComponents/FabricImageCanvasComponent';
import GaussianBlurFilter from 'editor/src/component/EditorArea/fabricFilters/GaussianBlurFilter';
import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

interface Props {
  zIndex: number;
  shadow: LeaningShadow;
  rect: ObjectRect;
  perspectiveTransform: PerspectiveTransform;
}

export interface LeaningShadowRef {
  update: (rect: ObjectRect) => void;
}

const RATIO = 4;
const MARGIN = 0.1;

function getSvg(
  rect: ObjectRect,
  color: string,
  side: ShadowSide | undefined,
  perspectiveTransform: PerspectiveTransform,
  mm2px: convFn,
) {
  const shadowHorizontalSpread = mm2px(200) * RATIO;
  const width = rect.width * RATIO + shadowHorizontalSpread * 2;
  const height = rect.height * RATIO;
  const offsetX = MARGIN * width * 0.5;
  const offsetY = MARGIN * height * 0.5;
  const shadowOffset_x = -mm2px(10) * RATIO;
  const shadowOffset_y = mm2px(7.5) * RATIO;

  let tl_x = offsetX + shadowHorizontalSpread + perspectiveTransform.tl_x * rect.width * RATIO;
  const tl_y = offsetY + shadowOffset_y;
  let tr_x = offsetX + shadowHorizontalSpread + perspectiveTransform.tr_x * rect.width * RATIO;
  const tr_y = offsetY + shadowOffset_y;
  let br_x = offsetX + shadowHorizontalSpread + perspectiveTransform.br_x * rect.width * RATIO;
  const br_y = offsetY + height - shadowOffset_y;
  let bl_x = offsetX + shadowHorizontalSpread + perspectiveTransform.bl_x * rect.width * RATIO;
  const bl_y = offsetY + height - shadowOffset_y;
  const edge_y = offsetY + height - mm2px(60) * RATIO;
  let path: string;

  if (side === 'right') {
    const edge_x = offsetX + width;
    tl_x -= shadowOffset_x;
    tr_x -= shadowOffset_x;
    br_x -= shadowOffset_x;
    bl_x -= shadowOffset_x;

    path = `<path d="M ${tl_x} ${tl_y} L ${tr_x} ${tr_y} L ${edge_x} ${edge_y} L ${br_x} ${br_y} L ${bl_x} ${bl_y} Z" fill="${color}"/>`;
  } else {
    const edge_x = offsetX;
    tl_x += shadowOffset_x;
    tr_x += shadowOffset_x;
    br_x += shadowOffset_x;
    bl_x += shadowOffset_x;

    path = `<path d="M ${tl_x} ${tl_y} L ${tr_x} ${tr_y} L ${br_x} ${br_y} L ${bl_x} ${bl_y} L ${edge_x} ${edge_y} Z" fill="${color}"/>`;
  }

  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width + offsetX * 2}" height="${height + offsetY * 2}">${path}</svg>`;

  return { svg, width: width + offsetX * 2, height: height + offsetY * 2 };
}

const LeaningShadowSvg = React.forwardRef(
  ({ zIndex, shadow, rect, perspectiveTransform }: Props, ref: React.Ref<LeaningShadowRef>) => {
    const fabricCanvas = useFabricCanvas();
    const imageRef = useRef<CustomFabricImage>(null);
    const { mm2px } = useFabricUtils();

    const filters = useMemo(() => [new GaussianBlurFilter({ blur: shadow.blur })], [shadow.blur]);

    const canvas = useMemo(() => {
      const domCanvas = document.createElement('canvas');
      domCanvas.id = 'svg-shadow-renderer';
      return domCanvas;
    }, []);

    const dimensions = useMemo(() => {
      const { svg, width, height } = getSvg(rect, shadow.color, shadow.side, perspectiveTransform, mm2px);
      const context = canvas.getContext('2d');
      if (context) {
        const svgRenderer = Canvg.fromString(context, svg);
        void svgRenderer.render().then(() => {
          imageRef.current?.setElement(canvas as any);
          imageRef.current?.applyFilters(imageRef.current?.filters);
          fabricCanvas.requestRenderAll();
        });
      }
      return { width, height };
    }, [rect, shadow.color, shadow.side, perspectiveTransform, mm2px]);

    useImperativeHandle(
      ref,
      () => ({
        update: (rect) => {
          const context = canvas.getContext('2d');
          const { svg, width, height } = getSvg(rect, shadow.color, shadow.side, perspectiveTransform, mm2px);
          imageRef.current?.set({
            left: rect.left - (width / RATIO - rect.width) / 2,
            top: rect.top - (height / RATIO - rect.height) / 2,
          });
          if (context) {
            const svgRenderer = Canvg.fromString(context, svg);
            void svgRenderer.render().then(() => {
              imageRef.current?.setElement(canvas as any);
              imageRef.current?.applyFilters(imageRef.current?.filters);
            });
          }
        },
      }),
      [shadow.color, shadow.side, perspectiveTransform, mm2px],
    );

    return (
      <FabricImageCanvasComponent
        ref={imageRef}
        sourceCanvas={canvas}
        left={rect.left - (dimensions.width / RATIO - rect.width) / 2}
        top={rect.top - (dimensions.height / RATIO - rect.height) / 2}
        scaleX={1 / RATIO}
        scaleY={1 / RATIO}
        evented={false}
        zIndex={zIndex}
        filters={filters}
      />
    );
  },
);

export default React.memo(LeaningShadowSvg);
