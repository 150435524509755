import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { SpreadDesignTemplate } from 'editor/src/store/editorModules/templates/types';

import useSpreadTemplatePreview from 'editor/src/util/design/useSpreadTemplatePreview';

import IconBin from 'editor/src/component/Icon/IconBin';
import { RequestRenderFn } from 'editor/src/component/SpreadPreview';
import { SpreadPreviewOptions } from 'editor/src/component/SpreadPreview/createSpreadPreview';
import TextInput from 'editor/src/component/TextInput';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  template: SpreadDesignTemplate;
  requestRender: RequestRenderFn;
  onDelete: (id: string, name: string) => void;
  onApply: (template: SpreadDesignTemplate) => void;
  onEdit: (template: SpreadDesignTemplate) => void;
}

const PREVIEW_DIMENSION = 89;
const MAX_TEMPLATE_NAME_LENGTH = 25;
const ALLOWED_CHAR_REGEX = /[^/\\*?"<>|]/;
export const IS_ALLOWED_CHAR = (char: string) => ALLOWED_CHAR_REGEX.test(char);

function TemplateItem({ template, requestRender, onDelete, onApply, onEdit }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editString, setEditString] = useState<string>(template.name);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (isEditMode && inputRef.current && !inputRef.current.contains(event.target as Node)) {
        applyChanges(editString);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const previewOptions: SpreadPreviewOptions = {
    showBlanks: true,
    showEmptyImages: true,
  };
  const { id, structure, previewUrl } = template;

  const { preview } = useSpreadTemplatePreview(
    requestRender,
    structure?.spreads[0],
    id,
    0,
    { dimension: 'both', value: PREVIEW_DIMENSION },
    previewOptions,
    previewUrl,
  );

  const onDeleteClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onDelete(id, template.name);
  };

  const applyChanges = (newValue: string) => {
    const normalizedName = newValue.trim();
    if (!normalizedName) {
      setEditString(template.name);
    } else if (template.name !== normalizedName) {
      onEdit({ ...template, name: normalizedName });
    }
    setIsEditMode(false);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
        applyChanges(editString);
        break;
      case 'Escape':
        setEditString(template.name);
        setIsEditMode(false);
        break;
      case 'z':
        if (event.ctrlKey) {
          event.preventDefault();
          setEditString(template.name);
          setIsEditMode(false);
          break;
        }
    }
  };

  return (
    <div className={cn(styles.wrapper, 'cy-design-template-item')}>
      <WithTooltip className={styles.tooltipWrapper} overlay={editString} placement="top">
        <div
          className={styles.preview}
          onClick={() => onApply(template)}
          style={{
            backgroundImage: preview ? `url(${preview.dataURL})` : undefined,
          }}
        />
        <div className={cn(styles.removeButton, 'cy-template-remove-btn')} onClick={onDeleteClicked}>
          <IconBin className={styles.removeIcon} />
        </div>
      </WithTooltip>
      <div
        className={cn(styles.title, { [styles.hide]: isEditMode }, 'cy-template-title')}
        onClick={() => setIsEditMode(true)}
      >
        {editString}
      </div>
      {isEditMode && (
        <TextInput
          className={cn(styles.ediTitle, { [styles.show]: isEditMode }, 'cy-template-title-input')}
          onChange={setEditString}
          onKeyDown={onKeyDown}
          onBlur={() => applyChanges(editString)}
          value={editString}
          isAllowedChar={IS_ALLOWED_CHAR}
          maxCharCount={MAX_TEMPLATE_NAME_LENGTH}
          focusOnMount
        />
      )}
    </div>
  );
}

export default React.memo(TemplateItem);
