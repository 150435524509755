import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { BrickPattern, Direction, ElementAddress, ImagePattern, MediaImage } from 'editor/src/store/design/types';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';
import { ControlUIType, ProductControlOption, VariationProductControl } from 'editor/src/store/variants/types';

import ButtonElement from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ButtonElement';
import SingleValueProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleValueProductControl';
import SwitchControl from 'editor/src/component/SwitchControl';

import RangeControl from './RangeControl';
import SelectControl from './SelectControl';

import styles from './index.module.scss';

interface Props {
  address: ElementAddress;
  pattern: BrickPattern;
}

function BrickPatternControls({ address, pattern }: Props) {
  const dispatch = useDispatch();
  const store = useStore();
  const { t } = useTranslation();
  const imagePatternScaleBases = useSelector((state) => state.editor.imagePatternScaleBases);

  const updateScale = useCallback(
    (value: number) => {
      dispatch(
        updateMediaElementOperation(
          address,
          { pattern: { ...pattern, scale: value } },
          MediaUpdateActionName.PATTERN_APPLIED,
        ),
      );
    },
    [address, pattern],
  );

  const getElementWithScaleUpdate = useCallback(
    (value: number) => {
      const state = store.getState();
      const element = getMediaElement<MediaImage>(state, address);
      return element ? { ...element, pattern: { ...pattern, scale: value } } : undefined;
    },
    [address, pattern],
  );

  const updateSpacing = useCallback(
    (value: number) => {
      dispatch(
        updateMediaElementOperation(
          address,
          { pattern: { ...pattern, spacing: value / 100 } },
          MediaUpdateActionName.PATTERN_APPLIED,
        ),
      );
    },
    [address, pattern],
  );

  const getElementWithSpacingUpdate = useCallback(
    (value: number) => {
      const state = store.getState();
      const element = getMediaElement<MediaImage>(state, address);
      return element ? { ...element, pattern: { ...pattern, spacing: value / 100 } } : undefined;
    },
    [address, pattern],
  );

  const updateOffset = useCallback(
    (value: number) => {
      dispatch(
        updateMediaElementOperation(
          address,
          { pattern: { ...pattern, offset: value / 100 } },
          MediaUpdateActionName.PATTERN_APPLIED,
        ),
      );
    },
    [address, pattern],
  );

  const getElementWithOffsetUpdate = useCallback(
    (value: number) => {
      const state = store.getState();
      const element = getMediaElement<MediaImage>(state, address);
      return element ? { ...element, pattern: { ...pattern, offset: value / 100 } } : undefined;
    },
    [address, pattern],
  );

  const onDirectionChange = useCallback(
    (control: VariationProductControl, option: ProductControlOption) => {
      dispatch(
        updateMediaElementOperation(
          address,
          {
            pattern: {
              ...pattern,
              direction: option.value as ImagePattern['direction'],
            },
          },
          MediaUpdateActionName.PATTERN_APPLIED,
        ),
      );
    },
    [address, pattern],
  );

  const onMirrorSwitch = useCallback(() => {
    dispatch(
      updateMediaElementOperation(
        address,
        { pattern: { ...pattern, mirror: !pattern.mirror || undefined } },
        MediaUpdateActionName.PATTERN_APPLIED,
      ),
    );
  }, [address, pattern]);

  const options = useMemo(() => {
    const options: Array<{ value: BrickPattern['scaleBase']; label: string }> = [];

    imagePatternScaleBases.forEach((scaleBase) =>
      options.push({
        value: { type: scaleBase.dimension, value: scaleBase.value },
        label: scaleBase.label,
      }),
    );

    options.push({
      value: { type: 'element', value: 'width' },
      label: t('Fixed to image width'),
    });
    options.push({
      value: { type: 'element', value: 'height' },
      label: t('Fixed to image height'),
    });

    return options;
  }, [imagePatternScaleBases]);

  const onFixedSwitch = useCallback(() => {
    const firstOption = options[0];

    dispatch(
      updateMediaElementOperation(
        address,
        {
          pattern: {
            ...pattern,
            scaleBase: pattern.scaleBase === undefined ? firstOption.value : undefined,
          },
        },
        MediaUpdateActionName.PATTERN_APPLIED,
      ),
    );
  }, [address, pattern, options]);

  const control: VariationProductControl = useMemo(
    () => ({
      type: 'single',
      uiType: ControlUIType.Button,
      key: 'direction',
      title: t('direction'),
      options: [
        { title: t('Horizontal'), value: Direction.Horizontal },
        { title: t('Vertical'), value: Direction.Vertical },
      ],
    }),
    [],
  );

  const onScaleBaseChange = useCallback(
    (scaleBase: ImagePattern['scaleBase']) => {
      dispatch(
        updateMediaElementOperation(
          address,
          { pattern: { ...pattern, scaleBase } },
          MediaUpdateActionName.PATTERN_APPLIED,
        ),
      );
    },
    [address, pattern],
  );

  return (
    <div className={styles.scaleSliderContainer}>
      <RangeControl
        title={t('scale')}
        value={pattern.scale}
        min={1}
        max={10}
        step={0.1}
        onChange={updateScale}
        getUpdatedElement={getElementWithScaleUpdate}
        className="cy-pattern-scale"
      />
      <RangeControl
        title={t('offset')}
        value={(pattern.offset ?? 0) * 100}
        min={0}
        max={100}
        step={1}
        onChange={updateOffset}
        getUpdatedElement={getElementWithOffsetUpdate}
        className="cy-pattern-offset"
      />
      <SingleValueProductControl
        noTopBar
        className={cn(styles.direction, 'cy-pattern-offset-direction')}
        title={t('offset direction')}
        control={control}
        options={control.options}
        toggleOption={onDirectionChange}
        selectedValue={pattern.direction}
        Element={ButtonElement}
      />
      <RangeControl
        title={t('spacing')}
        value={(pattern.spacing ?? 0) * 100}
        min={0}
        max={100}
        step={1}
        onChange={updateSpacing}
        getUpdatedElement={getElementWithSpacingUpdate}
        className={cn(styles.spacing, 'cy-pattern-spacing')}
      />
      <SwitchControl
        title={t('editor-mirroring-mirror')}
        on={!!pattern.mirror}
        onSwitch={onMirrorSwitch}
        className={cn(styles.mirror, 'cy-pattern-mirror-switch')}
      />
      <SwitchControl
        title={t('fixed scaling')}
        on={pattern.scaleBase !== undefined}
        onSwitch={onFixedSwitch}
        className={styles.scaling}
      />
      {pattern.scaleBase !== undefined && (
        <SelectControl<ImagePattern['scaleBase']>
          title=""
          options={options}
          selectedOption={options.find(
            (option) =>
              pattern.scaleBase?.type === option.value?.type && pattern.scaleBase?.value === option.value?.value,
          )}
          onChange={onScaleBaseChange}
        />
      )}
    </div>
  );
}

export default React.memo(BrickPatternControls);
