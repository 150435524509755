import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorSpace } from 'editor/src/store/design/types';

import CMYK from 'editor/src/util/color/cmykUtils';
import { parseColorToCMYK, parseColorToHSVA } from 'editor/src/util/color/colorUtils';
import HSVA, { DEFAULT_HSVA } from 'editor/src/util/color/hsvUtils';
import RGBA from 'editor/src/util/color/rgbaUtils';

import WarningBanner from '../../Banners/WarningBanner';

import CMYKInput from './CMYKInput';
import HexInput from './HexInput';
import HueSelector from './HueSelector';
import OpacitySlider from './OpacitySlider';
import RGBInput from './RGBInput';
import ShadeSelector from './ShadeSelector';

import styles from './index.module.scss';

interface Props {
  color: string | undefined;
  colorSpace: ColorSpace;
  hideRGB?: true;
  onColorChange: (color: string) => void;
  onColorChanging?: (color: string) => void;
  className?: string;
  label?: string;
  allowOpacity: boolean;
}

function exportHSV(hsv: HSVA, colorSpace: ColorSpace) {
  const rgb = hsv.toRGB();

  if (colorSpace === ColorSpace.CMYK) {
    return CMYK.fromRGB(rgb).toString();
  }

  return rgb.toString();
}

function ColorControllers({
  color,
  colorSpace,
  hideRGB,
  onColorChange,
  onColorChanging,
  className,
  label,
  allowOpacity,
}: Props) {
  const { t } = useTranslation();
  const [hsva, setCurrentHSVA] = useState(() => (color ? parseColorToHSVA(color) : DEFAULT_HSVA));
  const [modifiedCmyk, setModifiedCmyk] = useState(false);
  const [hexChanged, setHexChanged] = useState(false);

  useEffect(() => {
    if (!color) {
      setCurrentHSVA(DEFAULT_HSVA);
      return;
    }

    // if the value is the same once translated to CMYK, don't update it
    if (colorSpace === ColorSpace.CMYK) {
      const newCmyk = parseColorToCMYK(color);
      const currentCmyk = CMYK.fromRGB(hsva.toRGB());
      if (newCmyk.isEqual(currentCmyk)) {
        return;
      }
    }
    setCurrentHSVA(parseColorToHSVA(color));
  }, [color]);

  const onHueChange = useCallback(
    (hue: number) => {
      const newHSVA = new HSVA({
        h: hue,
        s: hsva.s,
        v: hsva.v,
        a: hsva.a,
      });
      setCurrentHSVA(newHSVA);
      if (onColorChanging) {
        onColorChanging(exportHSV(newHSVA, colorSpace));
      }
    },
    [hsva, onColorChanging, colorSpace],
  );

  const onOpacityChange = useCallback(
    (alpha: number) => {
      const newHSVA = new HSVA({
        h: hsva.h,
        s: hsva.s,
        v: hsva.v,
        a: alpha,
      });
      setCurrentHSVA(newHSVA);
      if (onColorChanging) {
        onColorChanging(exportHSV(newHSVA, colorSpace));
      }
    },
    [hsva, onColorChanging, colorSpace],
  );

  const onSaturationChange = useCallback(
    (hsva: HSVA) => {
      setCurrentHSVA(hsva);
      if (onColorChanging) {
        onColorChanging(exportHSV(hsva, colorSpace));
      }
    },
    [onColorChanging, colorSpace],
  );

  const onSaturationChanged = useCallback(
    (hsva: HSVA) => {
      setHexChanged(false);
      onColorChange(exportHSV(hsva, colorSpace));
    },
    [onColorChange, colorSpace],
  );

  const onHSLChanged = useCallback(() => {
    setHexChanged(false);
    onColorChange(exportHSV(hsva, colorSpace));
  }, [hsva, onColorChange, colorSpace]);

  const onRGBChange = useCallback(
    (rgba: RGBA) => {
      setHexChanged(false);
      setModifiedCmyk(false);
      if (colorSpace === ColorSpace.CMYK) {
        const cmyk = CMYK.fromRGB(rgba);
        onColorChange(cmyk.toString());
      } else {
        onColorChange(rgba.toString());
      }
    },
    [onColorChange, colorSpace],
  );

  const onHexChange = useCallback(
    (hex: string) => {
      setHexChanged(true);
      setModifiedCmyk(false);
      if (colorSpace === ColorSpace.CMYK && modifiedCmyk) {
        const rgba = RGBA.fromHex(hex);
        if (rgba) {
          const cmyk = CMYK.fromRGB(rgba);
          onColorChange(cmyk.toString());
        }
      } else {
        onColorChange(hex);
      }
    },
    [onColorChange, colorSpace],
  );

  const onCmykChange = useCallback(
    (color: string) => {
      setHexChanged(false);
      setModifiedCmyk(true);
      onColorChange(color);
    },
    [onColorChange, colorSpace],
  );

  const rgbaColor = hsva.toRGB();

  return (
    <div className={cn(styles.picker, className)}>
      {label && <span className={styles.label}>{label}</span>}
      <ShadeSelector hideCursor={!color} hsva={hsva} onChange={onSaturationChange} onChanged={onSaturationChanged} />
      <HueSelector
        hideCursor={!color}
        hue={hsva.h}
        onChange={onHueChange}
        onChanged={onHSLChanged}
        className={styles.slider}
      />
      {allowOpacity && colorSpace === ColorSpace.sRGB && (
        <OpacitySlider
          rgba={rgbaColor}
          onOpacityChanging={onOpacityChange}
          onOpacityChanged={onHSLChanged}
          className={styles.slider}
          hideCursor={!color}
        />
      )}
      {colorSpace === ColorSpace.CMYK && (
        <>
          <CMYKInput color={color} onColorChange={onCmykChange} className={styles.input} />
          <span className={styles.labelCentered}>CMYK (%)</span>
        </>
      )}
      <HexInput isUnset={!color} className={styles.input} onColorChange={onHexChange} rgba={rgbaColor} />
      <span className={styles.labelCentered}>HEX</span>
      {colorSpace === ColorSpace.CMYK && modifiedCmyk && hexChanged && (
        <WarningBanner>
          <div>{t('Colour is out of gamut for print and has been converted to CMYK')}</div>
        </WarningBanner>
      )}
      {!hideRGB && colorSpace === ColorSpace.sRGB && (
        <>
          <RGBInput
            rgba={rgbaColor}
            onColorChange={onRGBChange}
            allowOpacity={allowOpacity}
            className={styles.input}
            isUnset={!color}
          />
          <span className={styles.labelCentered}>{allowOpacity ? 'RGBA' : 'RGB'}</span>
        </>
      )}
    </div>
  );
}

export default React.memo(ColorControllers);
