import { Transform as FabricTransform, FabricObject } from 'fabric';

import FabricImage from 'editor/src/fabric/CustomFabricImage';

const getImageUpdateOnDrag = (
  _: FabricTransform,
  mask: FabricObject,
  maskOrigin: FabricObject,
  imageOrigin: FabricObject,
): Partial<FabricImage> => ({
  left: (imageOrigin.left || 0) + ((mask.left || 0) - (maskOrigin.left || 0)),
  top: (imageOrigin.top || 0) + ((mask.top || 0) - (maskOrigin.top || 0)),
});

export default getImageUpdateOnDrag;
