import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  VariationProductControl,
  ProductControlOption,
  ProductControlMulti,
  ProductUnAvailability,
} from 'editor/src/store/variants/types';

import Accordion from 'editor/src/component/Accordion';
import MaxMessageInfo from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/MaxMessageInfo';
import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import { getUnAvailableMessage } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/utils';
import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';
import Select, { SelectOption } from 'editor/src/component/Select';
import WithTooltip from 'editor/src/component/WithTooltip';

import MultiSelectProductControlHeader from './MultiSelectProductControlHeader';

import styles from './MultiSelectProductControl.module.scss';
import controlStyles from '../ProductControl.module.scss';

export interface Props<P extends ProductControlOption> {
  options: P[];
  control: ProductControlMulti;
  selectedOptions: string[];
  availableOptions?: Set<string>;
  toggleOption(control: VariationProductControl, option: P): void;
  selectAll?(control: ProductControlMulti): void;
  deselectAll?(control: ProductControlMulti): void;
  Element: React.FC<ControlOptionProps<P>>;
  title: string;
  multiMode: boolean;
  maxCount?: number;
  maxMessage?: string;
  selectedCount?: number;
  className?: string;
  noTopBar?: boolean;
  disabled?: boolean;
  allowSelectAll?: boolean;
  IconElement?: React.FC;
  unAvailableOptions?: { [key: string]: ProductUnAvailability[] };
}

function MultiSelectProductControl<P extends ProductControlOption>({
  options,
  selectedOptions,
  availableOptions,
  toggleOption,
  selectAll,
  deselectAll,
  title,
  maxCount,
  maxMessage,
  Element,
  control,
  selectedCount = selectedOptions.length,
  className,
  multiMode,
  noTopBar,
  disabled: disabledControl,
  allowSelectAll,
  IconElement,
  unAvailableOptions,
}: Props<P>) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const showDropdown = control.dropdownThreshold && options.length > control.dropdownThreshold;

  const onSelectAll = useCallback(
    (deselect = false) => {
      if (control.collapsible) {
        setCollapsed(false);
      }

      !deselect ? selectAll?.(control) : deselectAll?.(control);
    },
    [control, selectAll, deselectAll],
  );

  const allOptionsSelected = useMemo(() => {
    return selectedOptions.length >= options.filter((option) => availableOptions?.has(option.value)).length;
  }, [selectedOptions, options, availableOptions]);

  const optionsWithMeta = options
    .map((option) => {
      const selected = selectedOptions.includes(option.value);
      const unavailable = availableOptions && !availableOptions.has(option.value);
      const disabled =
        disabledControl ||
        unavailable ||
        (multiMode && selected && selectedCount <= 1) ||
        (maxCount !== undefined && selectedCount >= maxCount && !selected);
      return {
        option,
        selected,
        unavailable,
        disabled,
      };
    })
    .sort(sortDisabledOptionLast);

  function sortDisabledOptionLast(
    { unavailable: unavailable1 }: (typeof optionsWithMeta)[0],
    { unavailable: unavailable2 }: (typeof optionsWithMeta)[0],
  ) {
    if (unavailable1) {
      return 1;
    }
    if (unavailable2) {
      return -1;
    }
    return 0;
  }

  const onSelectOption = (option: SelectOption) => {
    const selectedOption = options.find((originalOption) => originalOption.value === option.value);
    selectedOption && toggleOption(control, selectedOption);
  };

  const optionElements = (
    <div
      className={cn(
        styles.MultiSelectProductControl,
        className,
        'cy-multi-select-product-control',
        `cy-multi-select-product-control-${title.toLowerCase()}`,
      )}
    >
      {showDropdown ? (
        <Select
          options={optionsWithMeta.map(({ option, disabled }) => ({
            label: option.title,
            value: option.value,
            previewImg: option.optionDetails?.previewImage,
            description: option.optionDetails?.subtitle,
            disabled,
          }))}
          onSelect={onSelectOption}
          selected={selectedOptions}
          isMultiSelect={multiMode}
          placeholder={t('Select product {{title}}', { title })}
          className={styles.MultiSelectDropdown}
        />
      ) : (
        optionsWithMeta.map(({ option, selected, disabled, unavailable }) => {
          const element = (
            <Element
              // we allow undefined as a value to support no-frame placeholder see https://github.com/gelatoas/gelato-api-dashboard/pull/4631
              key={option.value || ''}
              option={option}
              control={control}
              toggle={toggleOption}
              selected={selected}
              disabled={disabled}
              selectedCount={selectedCount}
              unavailable={unavailable ? getUnAvailableMessage(option.value, unAvailableOptions) : undefined}
            />
          );

          if (option.disabledTitle) {
            return (
              <WithTooltip key={option.value || ''} overlay={option.disabledTitle} placement="top">
                {element}
              </WithTooltip>
            );
          }

          return element;
        })
      )}
    </div>
  );

  const header = (
    <MultiSelectProductControlHeader
      onSelectAll={onSelectAll}
      allItemsSelected={allOptionsSelected}
      allowSelectAll={multiMode && (allowSelectAll || !!control.allowSelectAll)}
    >
      {title}
      {IconElement && <IconElement />}
    </MultiSelectProductControlHeader>
  );

  return (
    <>
      {!noTopBar && <PropertySeparator bigMargin />}
      {control.collapsible ? (
        <Accordion header={header} collapsed={collapsed} setCollapsed={setCollapsed}>
          {optionElements}
        </Accordion>
      ) : (
        <>
          <div className={controlStyles.controlTitleContainer}>{header}</div>
          {optionElements}
        </>
      )}
      {maxMessage && maxCount !== undefined ? (
        <MaxMessageInfo optionCount={options.length} maxCount={maxCount} message={maxMessage} />
      ) : null}
    </>
  );
}

export default React.memo(MultiSelectProductControl);
