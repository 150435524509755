import cn from 'classnames';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getImageUrl } from 'editor/src/store/design/selector/getImageElementUrl';
import { DraggableItemType, DraggableMultipleItems, DraggableSingleItem } from 'editor/src/store/editor/types';
import removeImageOperation from 'editor/src/store/gallery/operation/removeImageOperation';
import { ImageAction } from 'editor/src/store/gallery/selector/getImageAction';
import getImageCrossOriginByState from 'editor/src/store/gallery/selector/getImageCrossOriginByState';
import getSelectedGalleryImagesIds from 'editor/src/store/gallery/selector/getSelectedGalleryImagesIds';
import { GalleryImage, ImageState, MimeType } from 'editor/src/store/gallery/types';
import { useDispatch, useStore } from 'editor/src/store/hooks';

import useDrag, { DRAG_ELMT_CLASSNAME } from 'editor/src/util/dnd/useDrag';

import ConfirmationMenu from 'editor/src/component/ConfirmationMenu';
import IconCheck from 'editor/src/component/Icon/IconCheck';
import IconPlus from 'editor/src/component/Icon/IconPlus';
import IconRefresh from 'editor/src/component/Icon/IconRefresh';
import useMobileMenu from 'editor/src/component/useMobileMenu';
import WithTooltip from 'editor/src/component/WithTooltip';

import ErrorBadge from './ErrorBadge';
import imageIcon from './image-icon.png';
import LabelBadge from './LabelBadge';
import MoreInfoImageButton from './MoreInfoImageButton';
import getImageName from './MoreInfoImageButton/MoreInfoDropdown/getImageName';
import pdfIcon from './pdf-icon.png';
import ReuploadImageButton from './ReuploadImageButton';
import UploadingBadge from './UploadingBadge';
import UsageBadge from './UsageBadge';

import styles from './index.module.scss';

interface Props {
  usage: number;
  imageAction: ImageAction;
  noRemove: boolean;
  image: GalleryImage;
  allowedUseNotUploadedImages: boolean;
  allowMultipleImageSelect: boolean;
  isMobile: boolean;
  hide?: boolean;
  isSelected?: boolean;
  onImageClick: (imageId: string) => void;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

function ImageListItem({
  image,
  imageAction,
  allowedUseNotUploadedImages,
  allowMultipleImageSelect,
  isMobile,
  usage,
  hide,
  isSelected,
  onImageClick,
  noRemove,
  x,
  y,
  width,
  height,
}: Props) {
  const store = useStore();
  const deleteImageConfirmation = useMobileMenu();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getDraggedElement = useCallback(() => {
    const baseObject = { itemType: DraggableItemType.image };
    const selectedImageIds = getSelectedGalleryImagesIds(store.getState());

    if (allowMultipleImageSelect && selectedImageIds.length > 0) {
      if (!selectedImageIds.includes(image.id)) {
        return {
          ...baseObject,
          itemIds: [...selectedImageIds, image.id],
          isMultipleItems: true,
        } as DraggableMultipleItems;
      }

      return { ...baseObject, itemIds: selectedImageIds, isMultipleItems: true } as DraggableMultipleItems;
    }
    return { ...baseObject, itemId: image.id, isMultipleItems: false } as DraggableSingleItem;
  }, [allowMultipleImageSelect, image.id]);

  const imageReadyForUse =
    image.state === ImageState.UPLOADED ||
    (image.state !== ImageState.FAILED && allowedUseNotUploadedImages && !!image.width && !!image.height);
  const { elementRef, dragging } = useDrag(getDraggedElement, imageReadyForUse);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [status, setStatus] = useState<'idle' | 'loaded' | 'failed'>('idle');

  let imageUrl = getImageUrl(image, true);
  useLayoutEffect(() => setStatus('idle'), [imageUrl]);

  if (!imageUrl) {
    imageUrl = image.type === MimeType.PDF ? pdfIcon : imageIcon;
  }

  if (status === 'failed' && image.state !== ImageState.UPLOADED) {
    imageUrl = imageIcon;
  }

  const handleImageClick = () => {
    if (!imageReadyForUse || status === 'failed') {
      return;
    }
    onImageClick(image.id);
  };

  const onMouseEnter = () => {
    setIsMouseOver(true);
  };

  const onMouseLeave = () => {
    setIsMouseOver(false);
  };

  const onImageLoad = useCallback(() => {
    if (imageUrl !== imageIcon && imageUrl !== pdfIcon) {
      setStatus('loaded');
    }
  }, [imageUrl]);

  const onImageError = useCallback(() => {
    if (imageUrl !== imageIcon && imageUrl !== pdfIcon) {
      setStatus('failed');
    }
  }, [imageUrl]);

  const imageName = getImageName(image);

  const crossOrigin = getImageCrossOriginByState(image.state);

  const onRemove = () => {
    if (!isMobile) {
      removeImage();
    } else {
      deleteImageConfirmation.open();
    }
  };

  const handleCancel = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    deleteImageConfirmation.close();
  };
  const removeImage = () => {
    dispatch(removeImageOperation(image.id));
  };

  const noSingleRemove = noRemove || allowMultipleImageSelect;
  const isHoverApplied = isMouseOver || isMobile;

  return (
    <div>
      <div
        className={cn(styles.ImageListItem, 'cy-image-list-item', DRAG_ELMT_CLASSNAME, {
          [styles.hide]: hide,
          [styles.dragging]: dragging,
          [styles.selected]: isSelected,
          [styles.multiselect]: allowMultipleImageSelect,
        })}
        onClick={handleImageClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={elementRef}
        style={{
          transform: x !== undefined ? `translate3d(${x}px,${y}px,0)` : undefined,
          padding: allowMultipleImageSelect ? '2px' : undefined,
          width,
          height,
        }}
      >
        <WithTooltip className={styles.nameTooltip} overlay={imageName} placement="top" disabled={!imageName}>
          {isMouseOver &&
            imageReadyForUse &&
            !isMobile &&
            image.state !== ImageState.FAILED &&
            status !== 'failed' &&
            ((imageAction === ImageAction.ADD && (
              <div className={styles.overlay}>
                <IconPlus />
              </div>
            )) ||
              (imageAction === ImageAction.REPLACE && (
                <div className={styles.overlay}>
                  <IconRefresh />
                </div>
              )))}
          <img
            // key={imageUrl + (crossOrigin || '')} // needed to avoid some CORS issue with safari
            crossOrigin={crossOrigin}
            src={imageUrl}
            alt={imageName}
            draggable={false}
            onLoad={onImageLoad}
            onError={onImageError}
            loading="lazy"
          />
          {isSelected && (
            <div className={cn(styles.selectedIcon, 'cy-selected-image-icon')}>
              <IconCheck />
            </div>
          )}
          {isHoverApplied && image.state === ImageState.FAILED && <ReuploadImageButton imageId={image.id} />}
          <div className={styles.badges}>
            {usage > 0 && <UsageBadge usage={usage} />}
            {!usage && image.label && <LabelBadge label={image.label} />}
            {image.state === ImageState.FAILED && <ErrorBadge />}
            {image.state !== ImageState.FAILED && image.state !== ImageState.UPLOADED && (
              <UploadingBadge imageId={image.id} />
            )}
          </div>
        </WithTooltip>
      </div>
      <div
        className={cn(styles.moreInfoContainer, 'cy-image-list-item-info-container')}
        style={{
          transform: x !== undefined ? `translate3d(${x + (width || 0)}px,${y}px,0)` : undefined,
          padding: allowMultipleImageSelect ? '2px' : undefined,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <MoreInfoImageButton
          onRemove={onRemove}
          imageId={image.id}
          noRemove={noSingleRemove}
          isButtonVisible={isHoverApplied && !!(!noSingleRemove || imageName)}
        />
        {isMobile &&
          deleteImageConfirmation.render(
            <ConfirmationMenu
              headerText={t('Delete image')}
              headerDescriptionText={t('Are you sure you want to delete the image')}
              confirmButtonText={t('Delete image')}
              cancellButtonText={t('Cancel')}
              onCancel={handleCancel}
              onConfirm={removeImage}
              onCrossClick={handleCancel}
            />,
          )}
      </div>
    </div>
  );
}

export default React.memo(ImageListItem);
