import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';
import DropDown from 'editor/src/component/DropDown';
import { DropdownPosition } from 'editor/src/component/DropDown/getPosition';

import styles from './index.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
  isVisible: boolean;
  triggerBtnRef?: React.RefObject<HTMLButtonElement | HTMLDivElement>;
  pointerOffset: number;
  position?: DropdownPosition;
  onClose: () => void;
  className?: string;
  titleClassName?: string;
  closeOnClickOutside?: boolean;
  actions?: React.ReactNode;
}
function RichTooltip({
  title,
  children,
  className,
  titleClassName,
  onClose,
  isVisible,
  triggerBtnRef,
  pointerOffset,
  position = 'right-fromtop',
  closeOnClickOutside = true,
  actions,
}: Props) {
  const [offsetModify, setOffsetModify] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const { t } = useTranslation();
  const onClickOutside = (e?: MouseEvent | TouchEvent) => {
    if (e && triggerBtnRef?.current && e.composedPath && e.composedPath().includes(triggerBtnRef.current)) {
      return;
    }

    onClose();
  };

  const onCloseHandler = (e?: MouseEvent | TouchEvent) => {
    onClose();
    e?.stopPropagation();
  };

  const pointerStyles = useMemo(() => {
    const styles: { [key: string]: string } = {};
    if (position === 'right-frombottom' || position === 'right-fromtop') {
      styles.top = `${pointerOffset - offsetModify.y}px`;
    } else if (position === 'top-center' || position === 'top-right' || position === 'top-left') {
      styles.left = `${pointerOffset - offsetModify.x}px`;
    }

    return styles;
  }, [pointerOffset, position, offsetModify]);

  return (
    <DropDown
      isVisible={isVisible}
      className={cn(className, styles.richTooltip, styles[`richTooltip__${position}`])}
      onClickOutside={(e) => closeOnClickOutside && onClickOutside(e)}
      openPosition={position}
      onOffsetChanged={setOffsetModify}
    >
      <div className={cn(styles.pointer, styles[`pointer__${position}`])} style={pointerStyles} />
      <div className={cn(styles.title, titleClassName)}>
        <b>{title}</b>
      </div>
      <div className={styles.content}>{children}</div>
      <div className={styles.bottomBar}>
        {!!actions && actions}
        <Button secondary className={styles.actionButton} onClick={onCloseHandler}>
          {t('Close')}
        </Button>
      </div>
    </DropDown>
  );
}

export default React.memo(RichTooltip);
