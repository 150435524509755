import { util as fabricNativeUtils, Point as FabricPoint } from 'fabric';

import { Coords, MediaText } from 'editor/src/store/design/types';

import FabricPathText from 'editor/src/fabric/FabricPathText';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

const fabricPropsToTextElementData = (
  px2mm: (size: number) => number,
  pageCoords: Coords,
  element: FabricPathText,
  canvasRotation: CanvasRotation,
): Partial<MediaText> => {
  const elementAngle = element.angle || 0;
  const {
    left: elementLeft = 0,
    top: elementTop = 0,
    width: elementWidth = 1,
    height: elementHeight = 1,
  } = element.getElementBoundingRect();

  const unrotatedPoint = fabricNativeUtils.rotatePoint(
    new FabricPoint(elementLeft, elementTop),
    canvasRotation.canvasCenter,
    -canvasRotation.angleRad,
  );

  return {
    x: px2mm(unrotatedPoint.x - pageCoords.left),
    y: px2mm(unrotatedPoint.y - pageCoords.top),
    width: px2mm(elementWidth),
    height: px2mm(elementHeight),
    r: elementAngle - canvasRotation.angleDeg,
  };
};

export default fabricPropsToTextElementData;
