import cn from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addElementToConditionGroupOperation from 'editor/src/store/design/operation/addElementToConditionGroupOperation';
import removeElementFromGroupConditionOperation from 'editor/src/store/design/operation/removeElementFromGroupConditionOperation';
import updateMediaElementNameOperation from 'editor/src/store/design/operation/updateMediaElementNameOperation';
import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import isElementPersonalizationLocked from 'editor/src/store/design/selector/isElementPersonalizationLocked';
import { MediaElement, StructureIndex } from 'editor/src/store/design/types';
import logPersonalizationLayerActivatedOperation from 'editor/src/store/editor/operation/logPersonalizationLayerActivatedOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import hasPersonalizationPluginEnabled from 'editor/src/store/plugins/selector/hasPersonalizationPluginEnabled';

import useOnClickOutside from 'editor/src/util/useOnClickOutside';

import IconGripDot from 'editor/src/component/Icon/IconGripDot';
import SwitchControl from 'editor/src/component/SwitchControl';
import TextInput from 'editor/src/component/TextInput';

import styles from './index.module.scss';

interface Props {
  element: MediaElement;
  address: StructureIndex;
  ImageElement?: JSX.Element;
  label?: string;
  className?: string;
  isNameEditable?: boolean;
}

function ElementNameWithPersonalizableSwitch({
  element,
  address,
  label,
  className,
  ImageElement = <IconGripDot />,
  isNameEditable = true,
}: Props) {
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const [showInput, setShowInput] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const structureIndex = {
    ...address,
    uuid: element?.uuid,
  };
  const personalizationLocked = useSelector((state) => isElementPersonalizationLocked(state, structureIndex));
  const personalizationEnabled = useSelector(hasPersonalizationPluginEnabled);
  const { t } = useTranslation();

  useOnClickOutside(
    divRef,
    () => {
      setShowInput(false);
      setError(undefined);

      const trimmedElementName = element?.name?.trim();
      const isElementNameUntrimmed = element?.name !== trimmedElementName;

      if (isElementNameUntrimmed) {
        onLabelChange(trimmedElementName);
      }
    },
    !showInput,
  );

  const onLabelClick = () => {
    if (isNameEditable) {
      setShowInput(true);
    }
  };

  const onSwitch = () => {
    batch(() => {
      dispatch(
        updateMediaElementOperation(
          structureIndex,
          { personalizationLocked: !personalizationLocked || undefined },
          MediaUpdateActionName.TOGGLE_PERSO_LOCK,
        ),
      );

      if (personalizationEnabled) {
        if (personalizationLocked) {
          batch(() => {
            dispatch(addElementToConditionGroupOperation(element));
            dispatch(logPersonalizationLayerActivatedOperation(element.type));
          });
        } else {
          dispatch(removeElementFromGroupConditionOperation(element.uuid));
        }
      }
    });
  };

  const onLabelChange = useCallback(
    (name: string) => {
      if (address) {
        const isSuccessful = dispatch(updateMediaElementNameOperation(address, name));

        if (!isSuccessful) {
          setError(t('This name is already taken'));
        } else {
          setError(undefined);
        }
      }
    },
    [address],
  );

  return (
    <div className={cn(styles.labelContainer, className)} ref={divRef}>
      <div className={styles.icon} onClick={onLabelClick}>
        {ImageElement}
      </div>
      <div className={cn(styles.textContainer)}>
        {showInput ? (
          <div>
            <TextInput
              value={label || element?.name || ''}
              onChange={onLabelChange}
              className={cn(styles.textInput, 'cy-element-name-input')}
            />
            {error && <div className={styles.errorMsg}>{error}</div>}
          </div>
        ) : (
          <label
            onClick={onLabelClick}
            className={cn(styles.label, 'cy-element-name', {
              [styles.hover]: isNameEditable,
            })}
          >
            {label || element?.name}
          </label>
        )}
      </div>
      <SwitchControl
        title=""
        className={cn(styles.switch, 'cy-element-switch')}
        on={!personalizationLocked}
        disabled={false}
        onSwitch={onSwitch}
      />
    </div>
  );
}

export default ElementNameWithPersonalizableSwitch;
