import { CanvasEvents, FabricObject } from 'fabric';
import React, { useRef, useEffect, useCallback } from 'react';

import startCropModeOperation from 'editor/src/store/editor/operation/startCropModeOperation';
import stopCropModeOperation from 'editor/src/store/editor/operation/stopCropModeOperation';
import { setZoomModeAction as setZoomModeOperation } from 'editor/src/store/editor/slice';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

function useCropMode(
  uuid: number,
  imageIsEmpty: boolean,
  selected: boolean,
  maskRef: React.RefObject<FabricObject>,
  ghostRef: React.RefObject<FabricObject>,
) {
  const fabricCanvas = useFabricCanvas();
  const dispatch = useDispatch();
  const isInCropMode = useSelector((state) => state.editor.cropModeElementUuid === uuid);

  const turnCropModeOff = useCallback(() => {
    dispatch(stopCropModeOperation());
    dispatch(setZoomModeOperation(false));
  }, []);

  const toggleCropMode = useCallback(() => {
    if (isInCropMode) {
      turnCropModeOff();
    } else {
      dispatch(startCropModeOperation(uuid));
    }
  }, [isInCropMode, uuid]);

  const onClickOut = useCallback(
    (e: CanvasEvents['mouse:down']) => {
      if (e.target !== ghostRef.current) {
        turnCropModeOff();
      }
    },
    [turnCropModeOff],
  );

  const mounted = useRef(false); // avoid effects on first mount
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return undefined;
    }

    if (isInCropMode && !selected) {
      turnCropModeOff();
    }

    if (isInCropMode && selected) {
      if (ghostRef.current) {
        fabricCanvas.setActiveObject(ghostRef.current);
      }
    } else if (maskRef.current && selected) {
      fabricCanvas.setActiveObject(maskRef.current);
    }

    if (isInCropMode) {
      fabricCanvas.on('mouse:down:after' as any, onClickOut);
      return () => {
        fabricCanvas.off('mouse:down:after' as any, onClickOut);
      };
    }

    return undefined;
  }, [isInCropMode, selected]);

  // when deleting image while in crop mode
  useEffect(() => {
    if (imageIsEmpty && isInCropMode) {
      turnCropModeOff();
    }
  }, [imageIsEmpty, isInCropMode, turnCropModeOff]);

  return { isInCropMode, toggleCropMode };
}

export default useCropMode;
