import getActiveIntroTourStep from 'editor/src/store/editorModules/tour/selector/getActiveIntroTourStep';
import getIntroTourSteps from 'editor/src/store/editorModules/tour/selector/getIntroTourSteps';
import { setActiveIntroStepAction } from 'editor/src/store/editorModules/tour/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import closeIntroTourOperation from './closeIntroTourOperation';

const setNextIntroStepOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const currentActiveStep = getActiveIntroTourStep(state) || 0;
  const introTourSteps = getIntroTourSteps(state);
  if (currentActiveStep >= introTourSteps.length) {
    dispatch(closeIntroTourOperation());
    return;
  }

  dispatch(setActiveIntroStepAction(currentActiveStep + 1));
};

export default setNextIntroStepOperation;
