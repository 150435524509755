import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MockupDisplayMode } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';
import setMockupDisplayModeOperation from 'editor/src/store/mockup/operation/setMockupDisplayModeOperation';
import { ControlUIType, VariationProductControl } from 'editor/src/store/variants/types';

import iconInfo from 'editor/src/static/info-icon.svg';

import ButtonElement from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ButtonElement';
import SingleValueProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleValueProductControl';
import WithTooltip from 'editor/src/component/WithTooltip';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';
import buttonStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleControl/index.module.scss';

import type { TFunction } from 'i18next';

function getOptions(t: TFunction): Array<{ title: string; value: MockupDisplayMode }> {
  return [
    { title: t('editor-hanging-shadow'), value: MockupDisplayMode.Hanging },
    { title: t('editor-leaning-shadow'), value: MockupDisplayMode.Leaning },
  ];
}

interface Props {
  displayMode: MockupDisplayMode | undefined;
}

function MockupDisplayModeControl({ displayMode }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function onSelect(_: VariationProductControl, option: { title: string; value: MockupDisplayMode }) {
    dispatch(setMockupDisplayModeOperation(option.value));
  }

  const control: VariationProductControl = useMemo(
    () => ({
      type: 'single',
      uiType: ControlUIType.Button,
      key: 'display-mode',
      title: t('editor-mockup-display-mode'),
      options: getOptions(t),
    }),
    [],
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  function IconElement() {
    return (
      <div className={controlStyles.controlIcon}>
        <WithTooltip
          overlay={t('editor-mockup-display-mode-tooltip')}
          className="cy-editor-mockup-display-mode-tooltip"
        >
          <img src={iconInfo} alt={t('editor-mockup-display-mode')} draggable={false} />
        </WithTooltip>
      </div>
    );
  }

  return (
    <SingleValueProductControl
      noTopBar
      className={cn(buttonStyles.ButtonControl, 'cy-display-mode-control')}
      title={control.title}
      control={control}
      options={control.options}
      toggleOption={onSelect}
      selectedValue={displayMode ?? MockupDisplayMode.Hanging}
      Element={ButtonElement}
      IconElement={IconElement}
    />
  );
}

export default React.memo(MockupDisplayModeControl);
