import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import {
  getBleedDimensions,
  getProductDimensionsBasedOnAsset,
} from 'editor/src/store/design/operation/checkToResizeProductOperation';
import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import getFirstImageElementAndAsset from 'editor/src/store/design/selector/getFirstImageElementAndAsset';
import { DesignDimensions, DimensionRestrictions } from 'editor/src/store/design/types';
// import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
// import { SettingsProperty, Unit } from 'editor/src/store/editor/types';
import { MimeType } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getSizeOptionValue from 'editor/src/store/variants/helpers/getSizeOptionValue';
import removeControlOptionOperation from 'editor/src/store/variants/operation/removeControlOptionOperation';
import selectExternalOptionOperation from 'editor/src/store/variants/operation/selectExternalOptionOperation';
import updateControlOptionOperation from 'editor/src/store/variants/operation/updateControlOptionOperation';
import { addControlOptionAction } from 'editor/src/store/variants/slice';
import { ProductControlSize, ProductSizeControlKey, ProductSizeUnit } from 'editor/src/store/variants/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import Button from 'editor/src/component/Button';
import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';

import controlStyles from '../../ProductControls/ProductControl.module.scss';

import ProductSizeDropdown, { ProductSizeDropdownMode, Size } from './ProductSize';
import ProductSizeItem from './ProductSize/ProductSizeItem';
import useDefaultSizes from './useDefaultSizes';
import useSizesWithAdditionalInfo from './useSizesWithAdditionalInfo';
import { getSizeValue } from './utils';

import styles from './index.module.scss';

interface Props {
  dimensions: DesignDimensions;
  sizeControl: ProductControlSize;
  multiMode: boolean;
}

function ProductSizeControl({ dimensions, sizeControl, multiMode }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.editor.settings.units);
  const productUid = useSelector((state) => state.design.designData?.product_uid);
  const selectedExternalOptions = useSelector((state) => state.variants.selectedExternalOptions);

  const selectedSizes = useMemo(
    () => new Set((selectedExternalOptions[ProductSizeControlKey] || []).map((size) => size.value)),
    [selectedExternalOptions],
  );

  const { imageElement, imageAsset } = useSelector(getFirstImageElementAndAsset, shallowEqual);

  const bleedDimensions = useSelector((state) => {
    const { designData } = state.design;
    if (!designData) {
      return undefined;
    }
    const spreadIndex = getCurrentSpreadIndex(state);
    return getBleedDimensions(designData.spreads[spreadIndex], dimensions);
  }, shallowEqual);

  const dpiLevels = useSelector(getCurrentDpiLevels, shallowEqual);

  const [showProductSizeDropdown, setShowProductSizeDropdown] = useState(false);
  const [productSizeDropdownMode, setProductSizeDropdownMode] = useState<ProductSizeDropdownMode>('add_size');
  const [editSizeIndex, setEditSizeIndex] = useState<number>(-1);

  // const handleUnitChange = useCallback((value: Unit) => {
  //   dispatch(setSettingsValueOperation(SettingsProperty.units, value));
  // }, []);

  const handleAddSize = useCallback(
    (size) => {
      dispatch(
        addControlOptionAction({
          controlKey: sizeControl.key,
          option: {
            width: size.width,
            height: size.height,
            value: getSizeOptionValue(size.width, size.height),
          },
        }),
      );

      setShowProductSizeDropdown(false);
      onSelect(size, false);
    },
    [sizeControl, setShowProductSizeDropdown],
  );

  function onAddSizeClick() {
    setShowProductSizeDropdown(true);
    setProductSizeDropdownMode('add_size');
  }

  function onSizeEditClick(index: number) {
    setShowProductSizeDropdown(true);
    setProductSizeDropdownMode('edit_size');
    setEditSizeIndex(index);
  }

  const onEditEnd = useCallback(
    (index: number, size: Size) => {
      const sizeExist = sizeControl.options.some(
        (option, i) => index !== i && option.width === size.width && option.height === size.height,
      );
      if (sizeExist) {
        return false;
      }
      const option = { ...size, value: getSizeValue(size) };
      dispatch(updateControlOptionOperation(sizeControl, option, index));
      sendPostMessage('log.productSizeAdded', size);
      setShowProductSizeDropdown(false);
      return true;
    },
    [productUid, sizeControl.options],
  );

  const onDeleteSize = useCallback(
    (index: number) => {
      if (sizeControl.options.length > 1) {
        dispatch(removeControlOptionOperation(sizeControl, index));
      }
    },
    [sizeControl.options.length],
  );

  const onSelect = useCallback(
    (size: Size, selected: boolean) => {
      if (productUid && (!selected || selectedSizes.size > 1)) {
        dispatch(
          selectExternalOptionOperation(sizeControl, {
            ...size,
            value: getSizeValue(size),
          }),
        );
        sendPostMessage('log.productSizeSelected', size);
      }
    },
    [productUid, selectedSizes.size],
  );

  const getMaxImageDimensions = useCallback(
    (dimensionRestrictions: DimensionRestrictions) => {
      if (
        !imageAsset ||
        !bleedDimensions ||
        !imageElement ||
        !dpiLevels ||
        imageAsset.type === MimeType.SVG ||
        imageAsset.type === MimeType.PDF ||
        imageAsset.type === MimeType.Unknown
      ) {
        return undefined;
      }

      return getProductDimensionsBasedOnAsset(
        imageAsset,
        dimensionRestrictions,
        bleedDimensions,
        dpiLevels.low,
        imageElement,
      );
    },
    [imageAsset, imageElement, bleedDimensions],
  );
  // Todo: add 'additionalDefaultSizesInfo' below if needed
  const { defaultSizes } = useDefaultSizes(
    sizeControl,
    unit as ProductSizeUnit,
    dimensions,
    imageAsset,
    getMaxImageDimensions,
  );

  const { sizes: sizeControlOptions, additionalInfo: additionalSizesInfo } = useSizesWithAdditionalInfo(
    sizeControl.options,
    dimensions,
    imageAsset,
    getMaxImageDimensions,
  );

  return (
    <div className={styles.ProductSizeControl}>
      <PropertySeparator bigMargin />
      <div className={styles.label}>
        <span className={controlStyles.controlTitle}>{sizeControl.title}</span>
        <Button small className={cn(styles.addSize, 'cy-add-size-button')} onClick={onAddSizeClick}>
          + {t('Add size')}
        </Button>
      </div>
      {productSizeDropdownMode === 'add_size' && (
        <ProductSizeDropdown
          isVisible={showProductSizeDropdown}
          mode="add_size"
          dropdownPosition="bottom-right"
          sizeControlOptions={sizeControlOptions}
          dimensions={dimensions}
          maxImageDimensions={
            additionalSizesInfo.get({ width: dimensions.width, height: dimensions.max_height })?.maxImageDimensions
          }
          unit={unit}
          // onUnitChange={handleUnitChange}
          onAddSize={handleAddSize}
          onEditEnd={onEditEnd}
          onClickOutside={() => setShowProductSizeDropdown(false)}
        />
      )}

      {sizeControlOptions.length > 0 && (
        <div className={cn(styles.sizes, 'cy-custom-sizes')}>
          {sizeControlOptions.map((size, i) => (
            <ProductSizeItem
              key={`manual${i}`}
              index={i}
              size={size}
              unit={unit}
              multi={multiMode}
              selected={selectedSizes.has(getSizeValue(size))}
              disabled={selectedSizes.has(getSizeValue(size)) && selectedSizes.size === 1}
              immutable={false}
              onEdit={() => onSizeEditClick(i)}
              onSelect={onSelect}
              onDelete={onDeleteSize}
            />
          ))}
          {productSizeDropdownMode === 'edit_size' && (
            <ProductSizeDropdown
              isVisible={showProductSizeDropdown}
              mode="edit_size"
              dropdownPosition="bottom-right"
              sizeControlOptions={sizeControlOptions}
              size={sizeControlOptions[editSizeIndex] || { width: dimensions.width, height: dimensions.height }}
              dimensions={dimensions}
              maxImageDimensions={
                additionalSizesInfo.get(
                  sizeControlOptions[editSizeIndex] || { width: dimensions.width, height: dimensions.height },
                )?.maxImageDimensions
              }
              unit={unit}
              index={editSizeIndex}
              // onUnitChange={handleUnitChange}
              onEditEnd={onEditEnd}
              onClickOutside={() => setShowProductSizeDropdown(false)}
            />
          )}
        </div>
      )}
      {defaultSizes.length > 0 && (
        <div className={cn(styles.sizes, 'cy-default-sizes')}>
          <div className={styles.label}>{t('Default sizes')}</div>
          {defaultSizes.map((size, i) => (
            <ProductSizeItem
              key={`manual${i}`}
              index={i}
              size={size}
              unit={unit}
              multi={multiMode}
              selected={selectedSizes.has(getSizeValue(size))}
              disabled={selectedSizes.has(getSizeValue(size)) && selectedSizes.size === 1}
              immutable
              onSelect={onSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(ProductSizeControl);
