import cn from 'classnames';
import React from 'react';

import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';

import styles from './index.module.scss';

function ButtonElement({ option, selected, disabled, toggle, control }: ControlOptionProps) {
  return (
    <div
      className={cn(
        styles.ButtonElement,
        { [styles.selected]: selected, [styles.disabled]: disabled, 'cy-active': selected },
        `cy-button-${control.key}-${option.value}`,
      )}
      onClick={() => toggle(control, option)}
    >
      {option.title}
    </div>
  );
}

export default React.memo(ButtonElement);
