import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import updateAllGridElementsOperation from 'editor/src/store/design/operation/calendarGrid/updateAllGridElementsOperation';
import { MediaGrid } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import Checkbox from 'editor/src/component/Checkbox';

const SUNDAY = 0;
const MONDAY = 1;

interface Props {
  gridElement: MediaGrid;
}

function WeekNumberControl({ gridElement }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isWeekNumChecked = !gridElement.grid.hideWeekNum;
  const { firstDayOfWeek } = gridElement.grid;

  const disabled = firstDayOfWeek !== SUNDAY && firstDayOfWeek !== MONDAY;

  const onChange = useCallback(() => {
    void dispatch(
      updateAllGridElementsOperation({
        hideWeekNum: isWeekNumChecked ? true : undefined,
      }),
    );
  }, [isWeekNumChecked]);

  return (
    <Checkbox
      on={!!isWeekNumChecked}
      onChange={onChange}
      label={t('show-week-numbers')}
      className={cn('mt-2 cy-checkbox-show-week-num', {
        'cy-checkbox-disabled': disabled,
      })}
      disabled={disabled}
    />
  );
}

export default React.memo(WeekNumberControl);
