import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ScrollDownArrow from './ScrollDownArrow';
import ScrollRightArrow from './ScrollRightArrow';

import styles from './index.module.scss';

const TOLERANCE_OFFSET = 20;

interface Props {
  children: React.ReactNode;
  addUIBorders?: boolean;
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  fixedBlock?: React.ReactNode;
}

function ScrollHelper({ children, className, addUIBorders = true, orientation = 'horizontal', fixedBlock }: Props) {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [showScrollHelper, setShowScrollHelper] = useState(false);

  const checkScrollPosition = useCallback(() => {
    if (sectionRef.current) {
      if (orientation === 'horizontal') {
        const isOverflowing =
          sectionRef.current.scrollLeft <
          sectionRef.current.scrollWidth - sectionRef.current.clientWidth - TOLERANCE_OFFSET;
        setShowScrollHelper(isOverflowing);
      } else {
        const isOverflowing =
          sectionRef.current.scrollTop <
          sectionRef.current.scrollHeight - sectionRef.current.clientHeight - TOLERANCE_OFFSET;
        setShowScrollHelper(isOverflowing);
      }
    }
  }, [orientation]);

  useEffect(() => {
    window.addEventListener('resize', checkScrollPosition);
    return () => window.removeEventListener('resize', checkScrollPosition);
  }, []);

  useEffect(() => {
    const handle = window.setTimeout(checkScrollPosition, 100);
    return () => window.clearTimeout(handle);
  });

  const scrollSection = () => {
    if (orientation === 'horizontal') {
      sectionRef.current?.scrollTo({
        behavior: 'smooth',
        left: sectionRef.current.scrollWidth,
      });
    } else {
      sectionRef.current?.scrollTo({
        behavior: 'smooth',
        top: sectionRef.current.scrollHeight,
      });
    }
  };

  return (
    <div className={cn(styles.ScrollHelper, styles[orientation], className, { [styles.borders]: addUIBorders })}>
      <div className={styles.content} ref={sectionRef} onScroll={checkScrollPosition}>
        {children}
      </div>
      {showScrollHelper && orientation === 'horizontal' && <ScrollRightArrow onClick={scrollSection} />}
      {showScrollHelper && orientation === 'vertical' && <ScrollDownArrow onClick={scrollSection} />}
      {fixedBlock && <div className={styles.FixedBlock}>{fixedBlock}</div>}
    </div>
  );
}

export default ScrollHelper;
