/* eslint-disable max-len */
import React from 'react';

import Icon from './index';

interface Props {
  className?: string;
}

function IconPlay({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M9.525 18.025C9.19167 18.2416 8.854 18.254 8.512 18.062C8.17067 17.8706 8 17.575 8 17.175V6.82496C8 6.42496 8.17067 6.12896 8.512 5.93696C8.854 5.74562 9.19167 5.75829 9.525 5.97496L17.675 11.15C17.975 11.35 18.125 11.6333 18.125 12C18.125 12.3666 17.975 12.65 17.675 12.85L9.525 18.025ZM10 15.35L15.25 12L10 8.64996V15.35Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconPlay);
