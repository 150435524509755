import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getFocusedMediaElementUuidSelector from 'editor/src/store/editor/selector/getFocusedMediaElementUuidSelector';
import hideSidebarOperation from 'editor/src/store/editorModules/sidebar/operation/hideSidebarOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import getSidebarTabsCount from 'editor/src/store/editorModules/sidebar/selector/getSidebarTabsCount';
import isSidebarVisible from 'editor/src/store/editorModules/sidebar/selector/isSidebarVisible';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import IconChevronLeft from 'editor/src/component/Icon/IconChevronLeft';
import WithTooltip from 'editor/src/component/WithTooltip';

import ScrollHelper from '../BottomBarMobile/ControlsSection/ScrollHelper';

import TabContents from './TabContents';
import tabContents from './TabContents/tabContents';
import Tabs from './Tabs';

import styles from './index.module.scss';

function Sidebar() {
  const showSidebar = useSelector(isSidebarVisible);
  const activeTab = useSelector(getSidebarActiveTab);
  const focusedModeEnabled = useSelector(getFocusedMediaElementUuidSelector);
  const tabsCount = useSelector(getSidebarTabsCount);

  const dispatch = useDispatch();

  const handleHideSidebarClick = () => {
    dispatch(hideSidebarOperation());
  };
  const { t } = useTranslation();

  return (
    <div
      className={cn(styles.Sidebar, 'cy-sidebar', {
        [styles.visible]: showSidebar && activeTab && tabsCount > 1,
      })}
    >
      {!focusedModeEnabled && tabsCount > 1 && (
        <ScrollHelper orientation="vertical" addUIBorders={false} fixedBlock={<Tabs sticky />}>
          <Tabs />
        </ScrollHelper>
      )}
      {showSidebar && activeTab && (
        <>
          <TabContents className={styles.content} tabContents={tabContents} activeTab={activeTab} />
          {!isEmbedded && (
            <div className={styles.closeButton} onClick={handleHideSidebarClick}>
              <WithTooltip className={styles.closeButtonArea} placement="bottom" overlay={t('editor-close-side-panel')}>
                <IconChevronLeft />
              </WithTooltip>
            </div>
          )}
        </>
      )}
      <div />
    </div>
  );
}

export default React.memo(Sidebar);
