import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PrintTechnology } from 'editor/src/store/design/types';
import useMultiValues from 'editor/src/store/variants/selector/useMultiValues';
import { ProductControlMulti, ProductControlOption } from 'editor/src/store/variants/types';

import printTechnicImg from 'editor/src/static/print-technic.png';
import printTechnologyImg from 'editor/src/static/print-technology.png';

import MultiSelectProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/multi/MultiSelectProductControl';
import useFilteredOptionsWithVariations from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/multi/useFilteredOptionsWithVariations';
import { MultiProductControlProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';

import ButtonElement from '../../elements/ButtonElement';
import InfoBtnControl from '../../elements/InfoBtnControl';
import PrintTechnicInfoBanner from '../../elements/PrintTechnicInfoBanner';

import styles from './index.module.scss';

function MultiControlTechnology({
  toggleOption,
  element,
  multiMode,
  control,
  selectAll,
  deselectAll,
  variations,
  multiOptions,
  singleOptions,
  noTopBar,
  availableOptions,
  unAvailableOptions,
  disabled,
  disabledControlDescription,
}: MultiProductControlProps) {
  const { t } = useTranslation();
  const { selectedOptions, totalCount } = useMultiValues(control, multiOptions, singleOptions, !multiMode);
  const options = useFilteredOptionsWithVariations(control, variations, singleOptions, disabledControlDescription);
  const printTechnicOptions = options.filter(({ title }) => title !== PrintTechnology.Embroidery);
  const selectedPrintTechnic = useMemo(() => {
    return selectedOptions.find((option) =>
      [PrintTechnology.DTF.toLowerCase(), PrintTechnology.DTG.toLowerCase()].includes(option),
    );
  }, [selectedOptions]);

  // show Technology section if Embroidery and DTF/DTG
  const embroideryOption = options.find(({ title }) => title === PrintTechnology.Embroidery);
  const printingOption: ProductControlOption | undefined = printTechnicOptions.length
    ? {
        title: 'Printing',
        value: selectedPrintTechnic || printTechnicOptions[0].value,
      }
    : undefined;

  const toggleOptionOverwrite = (control: ProductControlMulti, option: ProductControlOption): void => {
    if (option.title === printingOption?.title && !selectedPrintTechnic) {
      return toggleOption(control, printTechnicOptions[0]);
    }
    return toggleOption(control, option);
  };

  const printTechnologyInfo = () => (
    <InfoBtnControl
      title="print-technology-tooltip-title"
      description="print-technology-tooltip-description"
      components={{ image: <img src={printTechnologyImg} alt={t('Print technology')} /> }}
    />
  );
  const technologyProductControl =
    options.length > 1 && embroideryOption && printingOption ? (
      <MultiSelectProductControl
        className={cn(styles.technologyControl, !multiMode ? styles.singleTechnologyControl : undefined)}
        title={control.title}
        options={[printingOption, embroideryOption]}
        control={control}
        toggleOption={toggleOptionOverwrite}
        selectAll={selectAll}
        deselectAll={deselectAll}
        selectedOptions={selectedOptions}
        availableOptions={availableOptions}
        selectedCount={totalCount}
        multiMode={multiMode}
        Element={ButtonElement}
        noTopBar={noTopBar}
        IconElement={printTechnologyInfo}
        unAvailableOptions={unAvailableOptions?.[control.key]}
        disabled={disabled}
        allowSelectAll
      />
    ) : undefined;

  // show Print Technic Radio Btn if DTF and DTG and Technology == Printing
  const printTechnicInfo = () => (
    <InfoBtnControl
      title="print-technic-tooltip-title"
      description="print-technic-tooltip-description"
      components={{
        image: <img src={printTechnicImg} alt={t('Print technic')} />,
        ul: <ul />,
        li: <li />,
      }}
    />
  );
  const printTechnicProductControl =
    printTechnicOptions.length > 1 && !selectedOptions.includes(PrintTechnology.Embroidery.toLowerCase()) ? (
      <>
        <MultiSelectProductControl
          className={cn(styles.printTechnicControl, !multiMode ? styles.singlePrintTechnicControl : undefined)}
          title={t('Print technic')}
          options={printTechnicOptions}
          control={control}
          toggleOption={toggleOption}
          selectAll={selectAll}
          deselectAll={deselectAll}
          selectedOptions={selectedOptions}
          availableOptions={availableOptions}
          selectedCount={totalCount}
          multiMode={multiMode}
          Element={element}
          noTopBar={embroideryOption ? true : noTopBar}
          IconElement={printTechnicInfo}
          unAvailableOptions={unAvailableOptions?.[control.key]}
          disabled={disabled}
          allowSelectAll
        />
        <PrintTechnicInfoBanner selectedTechnics={selectedOptions} />
      </>
    ) : undefined;

  return (
    <>
      {technologyProductControl}
      {printTechnicProductControl}
    </>
  );
}

export default React.memo(MultiControlTechnology);
