import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

export enum GalleryTabItem {
  All = 'all',
  Digitized = 'digitized',
}

interface TabItem {
  key: GalleryTabItem;
  label: React.ReactNode;
  active?: true;
}

interface Props {
  items: TabItem[];
  onSwitch: (key: GalleryTabItem) => void;
  activeKey: GalleryTabItem;
}

function GalleryImageTab({ items, activeKey, onSwitch }: Props) {
  return (
    <div className={cn(styles.tabsContainer)}>
      {items.map((item) => (
        <div
          className={cn(styles.tabItem, { [styles.active]: item.key === activeKey })}
          key={item.key}
          onClick={() => onSwitch(item.key)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
}

export default React.memo(GalleryImageTab);
