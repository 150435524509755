import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getDesignData from 'editor/src/store/design/selector/getDesignData';
import triggerIntroTourOperation from 'editor/src/store/editorModules/tour/operation/triggerIntroTourOperation';
import { isIntroTourAllowed } from 'editor/src/store/editorModules/tour/utils/introTourConfig';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import IconPlay from 'editor/src/component/Icon/IconPlay';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

function ProductTourButton() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const designData = useSelector(getDesignData);

  const canShowButton = isIntroTourAllowed(designData);

  const triggerTour = () => {
    dispatch(triggerIntroTourOperation());
  };

  if (isMobile || !canShowButton) {
    return null;
  }

  return (
    <Button secondary onClick={triggerTour} className={cn(styles.button, 'cy-trigger-product-tour-button')}>
      <IconPlay />
      <span>{t('Product tour')}</span>
    </Button>
  );
}

export default React.memo(ProductTourButton);
