import cn from 'classnames';
import React from 'react';

import TabElements from './TabElements';

import styles from './index.module.scss';

interface Props {
  sticky?: true;
}
function Tabs({ sticky }: Props) {
  if (sticky) {
    return <TabElements sticky={sticky} />;
  }

  return (
    <div className={cn(styles.tabs, 'cy-tabs')}>
      <TabElements />
      <div className={styles.fill} />
    </div>
  );
}

export default React.memo(Tabs);
