import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProductControlOptionColor } from 'editor/src/store/variants/types';

import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import IconCheck2 from 'editor/src/component/Icon/IconCheck2';
import WithTooltip from 'editor/src/component/WithTooltip';

import ColorDot from './ColorDot';

import styles from './index.module.scss';

function ColorElement({
  option,
  selected,
  disabled,
  toggle,
  control,
  unavailable,
}: ControlOptionProps<ProductControlOptionColor>) {
  let overlayTitle = '';
  const { t } = useTranslation();
  const previewImg = option.optionDetails?.previewImage;
  if (previewImg) {
    overlayTitle = option.title;
  } else if (disabled && option.disabledTitle) {
    overlayTitle = option.disabledTitle;
  } else if (unavailable) {
    const translatedUnavailableMessage = unavailable.map((unavailableMessage) => t(unavailableMessage));
    overlayTitle = translatedUnavailableMessage.join(',');
  } else {
    overlayTitle = !disabled
      ? `${option.title}, ${option.colorHex.split(';').join('/')}`
      : t('Color/size combination not available');
  }

  const canToggle = !disabled || (!!unavailable && selected);

  return (
    <WithTooltip overlay={overlayTitle} placement="top" className={styles.wrapper}>
      {!previewImg ? (
        <ColorDot
          toggle={() => !disabled && toggle(control, option)}
          className={cn(`cy-color-box-${option.value}`, { [styles.disabled]: !canToggle, 'cy-disabled': disabled })}
          disabled={!!(unavailable || disabled) && !selected}
          colorHex={option.colorHex}
          selected={selected}
          texture={option.texture}
        />
      ) : (
        <div
          className={cn(styles.previewImage, `cy-selector-box-${option.value}`, {
            [styles.disabled]: !canToggle,
            'cy-disabled': disabled,
          })}
          onClick={() => !disabled && toggle(control, option)}
        >
          <img src={previewImg} alt={option.title} />
          {selected && <IconCheck2 className={styles.check} />}
        </div>
      )}
    </WithTooltip>
  );
}

export default React.memo(ColorElement);
